export const ISAUTHENTICATED = "ISAUTHENTICATED";
export const CUSTOMER_DETAILS = "CUSTOMER_DETAILS";
export const LOGIN_LOADING_START = "LOGIN_LOADING_START";
export const LOGIN_LOADING_DONE = "LOGIN_LOADING_DONE";
export const LOADING_START = "LOADING_START";
export const LOADING_END = "LOADING_END";
export const DESTINATION = "DESTINATION";
export const CHECK_IN_CHECK_OUT = "CHECK_IN_CHECK_OUT";
export const ROOM_GUEST = "ROOM_GUEST";
export const PRICE_RANGE = "PRICE_RANGE";
export const PROPERTY_TYPE = "PROPERTY_TYPE";
