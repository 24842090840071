import React, { useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import { Navigate, Route, Routes, useNavigate } from "react-router-dom";
import {
  authAction,
  updateCustomerDetails,
} from "../data/redux/action/authAction";
import {
  updateCheckInCheckOut,
  updateDestination,
  updatePropertyType,
} from "../data/redux/action/searchAction";
import Dashboard from "../presentation/pages/dashboard";
import Profile from "../presentation/pages/Profile";
import PropertyDetails from "../presentation/pages/Property/PropertyDetails";
import PropertyList from "../presentation/pages/Property/PropertyList";
import {
  SS_CHECK_IN_CHECK_OUT,
  SS_DESTINATION,
  SS_EMAIL,
  SS_MOBILE_NUMBER,
  SS_PROPERTY_TYPE,
  SS_ROLE,
  SS_TOKEN,
  SS_USERID,
  SS_USER_NAME,
} from "../presentation/resources/secureStorageManager";
import secureStorage from "../utility/secureStorage";
import { clearLocalStorageTimer } from "../utility/timerToClearStorage";
import AboutUs from "../presentation/pages/Utils/AboutsUs";
import PrivacyPolicy from "../presentation/pages/Utils/PrivacyPolicy";
import ContactUs from "../presentation/pages/ContactUs";
import TermsAndConditions from "../presentation/pages/Utils/TermsAndConditions";
import PaymentTest from "../presentation/pages/Utils/PaymentTest";
import BookNow from "../presentation/pages/BookNow";

const Router = ({ isAuthenticated }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const checkAuthenticatedOnReload = async () => {
    const token = await secureStorage.getItem(SS_TOKEN);
    if (token) {
      const email = await secureStorage.getItem(SS_EMAIL);
      const userId = await secureStorage.getItem(SS_USERID);
      const name = await secureStorage.getItem(SS_USER_NAME);
      const role = await secureStorage.getItem(SS_ROLE);
      const phone = await secureStorage.getItem(SS_MOBILE_NUMBER);

      const custDetails = {
        token,
        userId,
        email,
        name,
        role,
        phone,
      };

      dispatch(authAction(true));
      dispatch(updateCustomerDetails(custDetails));
    }
  };

  const getSearchParameters = async () => {
    let destination = await secureStorage.getItem(SS_DESTINATION);
    let checkInCheckOut = await secureStorage.getItem(SS_CHECK_IN_CHECK_OUT);
    let propertyType = await secureStorage.getItem(SS_PROPERTY_TYPE);
    // let roomGuest = await secureStorage.getItem(SS_ROOM_GUEST);

    dispatch(updateDestination(destination));
    dispatch(updateCheckInCheckOut(checkInCheckOut));
    dispatch(updatePropertyType(propertyType));
    // dispatch(updateRoomGuest(roomGuest));
  };

  useEffect(() => {
    checkAuthenticatedOnReload();
    getSearchParameters();
  }, []);

  useEffect(() => {
    dispatch(clearLocalStorageTimer(navigate));
  }, []);

  return (
    <Routes>
      <>
        <Route exact path="/home" element={<Dashboard />} />
        <Route exact path="/home/:type" element={<Dashboard />} />
        <Route exact path="/profile/:route" element={<Profile />} />
        <Route
          exact
          path="/property-list/:location/:type/:subtype/:price"
          element={<PropertyList />}
        />
        <Route
          path="/property-details/:type/:property_id"
          element={<PropertyDetails />}
        />
        <Route path="/about-us" element={<AboutUs />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route exact path="/contact-us" element={<ContactUs />} />
        <Route path="/terms-and-conditions" element={<TermsAndConditions />} />
        {isAuthenticated && (
          <>
            <Route path="/test-payment" element={<PaymentTest />} />
            <Route path="/book-now" element={<BookNow />} />
          </>
        )}
        <Route path="*" element={<Navigate replace to="/home" />} />
      </>
    </Routes>
  );
};

const mapStateToProps = (state) => {
  return {
    isAuthenticated: state.AuthReducer.isAuthenticated,
  };
};
export default connect(mapStateToProps)(Router);
