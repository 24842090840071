import { SS_TOKEN } from "../../presentation/resources/secureStorageManager";
import secureStorage from "../../utility/secureStorage";
import axios from "axios";

export const ApiGetServiceWrapper = ({ url = "", headers = {} }) => {
  // let token = secureStorage.getItem(SS_TOKEN);
  return new Promise(async (resolve, reject) => {
    axios({
      method: "GET",
      url,
      headers: {
        // Authorization: "Bearer " + token,
        "Content-Type": "application/json",
        "X-Frame-Options": "DENY",
        ...headers,
      },
    })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => reject(error));
  });
};

export const ApiPostServiceWrapper = ({
  url = "",
  headers = {},
  body = {},
}) => {
  // let token = secureStorage.getItem(SS_TOKEN);
  return new Promise((resolve, reject) => {
    axios({
      method: "POST",
      url,
      headers: {
        // Authorization: "Bearer " + token,
        "Content-Type": "application/json",
        "X-Frame-Options": "DENY",
        ...headers,
      },
      data: body,
    })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => reject(error));
  });
};

export const ApiPutServiceWrapper = ({ url = "", headers = {}, body = {} }) => {
  // let token = secureStorage.getItem(SS_TOKEN);
  return new Promise((resolve, reject) => {
    axios({
      method: "PUT",
      url,
      headers: {
        // Authorization: "Bearer " + token,
        "Content-Type": "application/json",
        "X-Frame-Options": "DENY",
        ...headers,
      },
      data: body,
    })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => reject(error));
  });
};
