import React from "react";
import ResizeListener from "../../utility/ResizeListener";
import Footer from "../components/Footer";
import { WHITE_COLOR } from "../resources/colorsManager";
import Navbar from "./Navbar";
import { WIDTH_LIMIT as limit } from "../../data/constants/Constants";

const MainLayout = ({ children }) => {
  const { width, height } = ResizeListener();
  const pathname = window.location.pathname;

  return (
    <div
      style={{
        backgroundColor: WHITE_COLOR,
        width: "100%",
        // height: height,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <Navbar />

      {/* maxWidth: "1440px"  */}
      <div style={{ width: "100%" }}>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            padding:
              pathname.includes("/home") || pathname === "/about-us"
                ? "0px"
                : width > limit
                ? "0px"
                : "10px 0px",
            boxSizing: "border-box",
            justifyContent: "center",
            width: "100%",
          }}
        >
          {children}
        </div>
        <Footer />
      </div>
    </div>
  );
};

export default MainLayout;
