import React from "react";
import ResizeListener from "../../../utility/ResizeListener";
import { WIDTH_LIMIT as limit } from "../../../data/constants/Constants";
const AboutCard = ({ item, idx }) => {
  const { width } = ResizeListener();
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        borderRadius: "16px",
        border: "1px solid #e2e2e2",
      }}
      key={idx}
    >
      <img
        style={{
          width: "100%",
          height: "250px",
          objectFit: "cover",
          borderTopLeftRadius: "16px",
          borderTopRightRadius: "16px",
        }}
        src={item.image}
        alt={item.name}
      />
      <div
        style={{
          padding: "16px 32px",
        }}
      >
        <p
          style={{
            color: "#101010",
            fontSize: "20px",
            fontFamily: "Poppins",
            fontWeight: "600",
            margin: "0",
          }}
        >
          {item.name}
        </p>
        <p
          style={{
            color: "#101010",
            fontSize: "16px",
            fontFamily: "Poppins",
            fontWeight: "400",
            margin: "0",
          }}
        >
          {item?.designation}
        </p>
      </div>
    </div>
  );
};

export default AboutCard;
