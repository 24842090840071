import React from "react";
import {
  Facebook,
  Instagram,
  LinkedIn,
  Twitter,
  Youtube,
} from "../../resources/assetsManager";
import { WIDTH_LIMIT } from "../../../data/constants/Constants";
import ResizeListener from "../../../utility/ResizeListener";

const ConnectUs = () => {
  const { width } = ResizeListener();
  const pathname = window.location.pathname;

  return (
    <>
      <div
        id="contact-us"
        style={{
          width:
            width > WIDTH_LIMIT
              ? pathname === "/about-us"
                ? "100%"
                : "90%"
              : "100%",
          margin: width > WIDTH_LIMIT ? "0px 0px 50px 0px" : "0px 0px 30px 0px",
          padding: width > WIDTH_LIMIT ? "50px" : 20,
          background: "#101010",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          borderRadius: width > WIDTH_LIMIT ? 10 : 0,
        }}
      >
        <p
          style={{
            color: "#fff",
            fontSize: width > WIDTH_LIMIT ? 27 : 23,
            fontWeight: "500",
            textAlign: "center",
            margin: 0,
          }}
        >
          Socialize with us
        </p>

        <div
          style={{
            width: width > WIDTH_LIMIT ? "60%" : "100%",
            height: 100,
            margin: width > WIDTH_LIMIT ? "20px 0px" : 0,
            display: "flex",
            justifyContent: "space-around",
            alignItems: "center",
          }}
        >
          <img
            onClick={() => {
              window.open("https://www.instagram.com/wandering_lama_india/");
            }}
            style={{ width: width > WIDTH_LIMIT ? 65 : 40, cursor: "pointer" }}
            src={Instagram}
          />
          <img
            onClick={() => {
              window.open("https://www.facebook.com/WanderingLamaa/");
            }}
            style={{ width: width > WIDTH_LIMIT ? 65 : 40, cursor: "pointer" }}
            src={Facebook}
          />
          <img
            onClick={() => {
              window.open(
                "https://www.youtube.com/channel/UCcApgijS374wGEYYQpk94IQ"
              );
            }}
            style={{ width: width > WIDTH_LIMIT ? 65 : 40, cursor: "pointer" }}
            src={Youtube}
          />
          <img
            onClick={() => {
              window.open(
                "https://twitter.com/wandering_lama_?s=11&t=R1fyw71cyqXJ5N_Z7qCIkA"
              );
            }}
            style={{ width: width > WIDTH_LIMIT ? 65 : 40, cursor: "pointer" }}
            src={Twitter}
          />
          <img
            onClick={() => {
              window.open("https://www.linkedin.com/company/wandering-lama/");
            }}
            style={{ width: width > WIDTH_LIMIT ? 65 : 40, cursor: "pointer" }}
            src={LinkedIn}
          />
        </div>

        <p
          style={{
            color: "#fff",
            margin: 0,
            textAlign: "center",
            fontSize: width > WIDTH_LIMIT ? 17 : 14,
          }}
        >
          We would love to hear from you <br />
          info@wanderinglama.com
          <br />
          +91 93535 12394
        </p>
      </div>
    </>
  );
};

export default ConnectUs;
