import React, { useState } from "react";
import ResizeListener from "../../../../utility/ResizeListener";
import { CloseIcon } from "../../../resources/assetsManager";

const ImageModal = ({ onClose, imgs }) => {
  const { height, width } = ResizeListener();
  const [activeIndex, setActiveIndex] = useState(0);

  return (
    <div
      style={{
        width: width * 0.8,
        height: height * 0.8,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
        borderRadius: "5px",
        outline: "none",
        border: 0,
        backgroundColor: "white",
      }}
    >
      <img
        style={{
          position: "absolute",
          width: width * 0.8,
          height: height * 0.8,
          objectFit: "cover",
        }}
        src={imgs[activeIndex]}
      />

      <div
        style={{
          position: "relative",
          width: width * 0.8,
          height: height * 0.8,
        }}
      >
        <div
          style={{
            width: "100%",
            position: "absoulte",
            top: 0,
            right: 0,
            height: 50,
            borderRadius: 10,
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
          }}
        >
          <p
            onClick={onClose}
            style={{
              cursor: "pointer",
              margin: 0,
              textAlign: "center",
              padding: "0px 20px",
              borderRadius: 10,
              fontSize: 40,
              color: "#fff",
              fontWeight: "bold",
              textShadow: "0 0 3px #000, 0 0 5px #000",
            }}
          >{`x`}</p>
        </div>

        <div
          style={{
            position: "absoulte",
            top: 0,
            right: 0,
            width: "100%",
            borderRadius: 10,
            display: "flex",
            alignItems: "center",
            marginTop: height * 0.21,
            justifyContent: "space-between",
          }}
        >
          <p
            onClick={() => {
              if (activeIndex > 0) setActiveIndex((prev) => prev - 1);
              else setActiveIndex(imgs.length - 1);
            }}
            style={{
              cursor: "pointer",
              margin: 0,
              textAlign: "center",
              padding: "30px 10px",
              borderRadius: 10,
              fontSize: 80,
              color: "#fff",
              fontWeight: "bold",
              textShadow: "0 0 3px #000, 0 0 5px #000",
            }}
          >{`<`}</p>

          <p
            onClick={() => {
              if (activeIndex < imgs.length - 1)
                setActiveIndex((prev) => prev + 1);
              else setActiveIndex(0);
            }}
            style={{
              cursor: "pointer",
              margin: 0,
              textAlign: "center",
              padding: "30px 10px",
              borderRadius: 10,
              fontSize: 80,
              color: "#fff",
              fontWeight: "bold",
              textShadow: "0 0 3px #000, 0 0 5px #000",
            }}
          >{`>`}</p>
        </div>

        <div
          style={{
            position: "absoulte",
            top: 0,
            right: 0,
            width: "100%",
            borderRadius: 10,
            display: "flex",
            alignItems: "center",
            marginTop: height * 0.21,
            justifyContent: "space-between",
          }}
        >
          <p
            style={{
              textShadow: "0 0 3px #000, 0 0 5px #000",
              fontWeight: "bold",
              color: "#fff",
              fontSize: 20,
              width: "100%",
              textAlign: "center",
            }}
          >
            {activeIndex + 1} / {imgs.length}
          </p>
        </div>
      </div>
    </div>
  );
};

export default ImageModal;
