export const SS_TOKEN = "SS_TOKEN";
export const SS_USERID = "SS_USERID";
export const SS_EMAIL = "SS_EMAIL";
export const SS_USER_NAME = "SS_FIRST_NAME";
export const SS_ROLE = "SS_ROLE";
export const SS_MOBILE_NUMBER = "SS_MOBILE_NUMBER";
export const SS_DESTINATION = "SS_DESTINATION";
export const SS_CHECK_IN_CHECK_OUT = "SS_CHECK_IN_CHECK_OUT";
export const SS_ROOM_GUEST = "SS_ROOM_GUEST";
export const SS_PROPERTY_TYPE = "SS_PROPERTY_TYPE";
export const SS_PRICE_RANGE = "SS_PRICE_RANGE";
export const SS_ACTIVE_TAB = "SS_ACTIVE_TAB";
