import React, { useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { stopFullScreenLoadingAction } from "../../data/redux/action/fullScreenLoadingAction";
import CustomFullScreenLoader from "../components/customFullScreenLoader";
import MainLayout from "./MainLayout";

const Layout = ({ children, isAuthenticated, isLoading }) => {
  let location = useLocation();
  const dispatch = useDispatch();

  useEffect(() => {
    const interval = setTimeout(() => {
      dispatch(stopFullScreenLoadingAction());
    }, 3000);
    return () => {
      clearInterval(interval);
    };
  });

  return (
    <>
      {isLoading && (
        <CustomFullScreenLoader
          styles={{
            position: "fixed",
            top: 0,
            zIndex: 1000,
            background: "#fff",
          }}
        />
      )}
      <MainLayout>{children}</MainLayout>
    </>
  );

  // if (["/about-us"].includes(pathname)) {
  //   return <Empty>{children}</Empty>;
  // }
  // if ( isAuthenticated && [ "/home" ].includes( pathname ) ) {
  //   return <Centered>{ children }</Centered>;
  // } else {
  //   if ( isAuthenticated ) {
  //     return <MainLayout>{ children }</MainLayout>;
  //   } else {
  //     return <Empty>{ children }</Empty>;
  //   }
  //   // return <MainLayout>{children}</MainLayout>;
  // }
};

const mapStateToProps = (state) => {
  return {
    isLoading: state.fullScreenLoadingReducer.isLoading,
    isAuthenticated: state.AuthReducer.isAuthenticated,
  };
};

export default connect(mapStateToProps)(Layout);
