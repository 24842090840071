import { useSnackbar } from "notistack";
import React, { useCallback, useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import {
  WIDTH_LIMIT,
  WIDTH_LIMIT as limit,
} from "../../../data/constants/Constants";
import { ApiRequestGet } from "../../../data/network/services/ApiRequestGet";
import {
  updateCheckInCheckOut,
  updateDestination,
  updatePropertyType,
} from "../../../data/redux/action/searchAction";
import {
  eventsSubCategory,
  experienceSubCategory,
  toursSubCategory,
} from "../../../utility/Constants";
import ResizeListener from "../../../utility/ResizeListener";
import secureStorage from "../../../utility/secureStorage";
import MainSearchBar from "../../components/MainSearchBar";
import {
  BackLanding,
  BackLandingM,
  ExperianceImage,
  FrontLanding,
  FrontLandingM,
} from "../../resources/assetsManager";
import {
  CAMPING,
  DAYOUTING,
  EVENTS,
  EXPERIENCES,
  STAYS,
  TOURS,
} from "../../resources/constantsManager";
import {
  SS_CHECK_IN_CHECK_OUT,
  SS_DESTINATION,
  SS_PROPERTY_TYPE,
} from "../../resources/secureStorageManager";
import ConnectUs from "./ConnectUs";
import TrendingComponent from "./TrendingComponent";
import WhatTravellersSay from "./WhatTravellersSay";
import WhyChooseUs from "./WhyChooseUs";
import "./index.css";

const Dashboard = ({
  destinationRed,
  checkInCheckOutRed,
  roomGuestRed,
  propertyTypeRed,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const [destination, setDestination] = useState("");
  const [activeTab, setActiveTab] = useState(STAYS);
  const [showLoactionSuggestion, setShowLoactionSuggestion] = useState("");
  const [checkInCheckOut, setCheckInCheckOut] = useState("");
  const [checkInCheckOutText, setCheckInCheckOutText] = useState("");
  const [roomGuest, setRoomGuest] = useState("");
  const [locationArr, setLocationArr] = useState([]);
  const [propertyType, setPropertyType] = useState("none");
  const [trendingProducts, setTrendingProducts] = useState({});

  const { width, height } = ResizeListener();
  const propTypeOptions = [
    { key: 0, value: "none", label: "Property Type" },
    { key: 1, value: "RESORT", label: "Resort" },
    { key: 2, value: "HOMESTAY", label: "Homestay" },
    { key: 3, value: "ACTIVITY", label: "Activity" },
  ];

  const searchAllLocations = () => {
    ApiRequestGet.getAllLocationSuggestions()
      .then((res) => {
        setLocationArr(res?.data?.data);
        setShowLoactionSuggestion(true);
      })
      .catch((err) => {
        console.log("getLocationSuggestions error", err);
      });
  };

  useEffect(() => {
    const parallax = document.getElementById("parallax");

    // // Parallax Effect for DIV 1
    window.addEventListener("scroll", function () {
      let offset = window.pageYOffset;
      parallax.style.backgroundPositionY = offset * 0.8 + "px";
      // DIV 1 background will move slower than other elements on scroll.
    });
  }, []);

  const onSearch = async () => {
    const isDestinationSelected = locationArr?.findIndex(
      (e) => e?.name?.toLowerCase() === destination.toLowerCase()
    );

    if (!destination) {
      enqueueSnackbar("Please Enter Your Destination ", {
        variant: "error",
        anchorOrigin: { vertical: "top", horizontal: "right" },
      });
    } else {
      // if (isDestinationSelected !== -1)
      dispatch(updateDestination(destination));
      dispatch(updateCheckInCheckOut(checkInCheckOut));
      dispatch(updatePropertyType(propertyType));
      // dispatch(updateRoomGuest(roomGuest));

      await secureStorage.setItem(SS_DESTINATION, destination);
      await secureStorage.setItem(SS_CHECK_IN_CHECK_OUT, checkInCheckOut);
      await secureStorage.setItem(SS_PROPERTY_TYPE, propertyType);
      // await secureStorage.setItem(SS_ROOM_GUEST, roomGuest);

      const subType =
        activeTab === EXPERIENCES
          ? experienceSubCategory[0].value
          : activeTab === EVENTS
          ? eventsSubCategory[0].value
          : activeTab === TOURS
          ? toursSubCategory[0].value
          : "none";

      navigate(
        `/property-list/${destination}/${activeTab.toLowerCase()}/${subType.toLowerCase()}/50000`
      );
      activeTab.toUpperCase();
    }
    // else {
    //   enqueueSnackbar("Please Enter Select the Destination from List ", {
    //     variant: "error",
    //     anchorOrigin: { vertical: "top", horizontal: "right" },
    //   });
    // }
  };

  const getTrendingProducts = () => {
    ApiRequestGet.getTrendingProducts()
      .then((res) => {
        if (res?.data?.success) setTrendingProducts(res?.data?.data);
        else
          enqueueSnackbar(res?.data?.message, {
            variant: "error",
            anchorOrigin: { vertical: "top", horizontal: "right" },
          });
      })
      .catch((err) => {
        console.log("err", err);
        enqueueSnackbar("Something went wrong!!!", {
          variant: "error",
          anchorOrigin: { vertical: "top", horizontal: "right" },
        });
      });
  };

  const getLocationSuggestions = (search = "", value) => {
    // setLoading(true);
    ApiRequestGet.getLocationSuggestions(search)
      .then((res) => {
        setLocationArr(res?.data?.data);
        setShowLoactionSuggestion(value);
      })
      .catch((err) => {
        console.log("getLocationSuggestions error", err);
      });
    // .finally(() => setLoading(false));
  };

  const debounceFn = useCallback((val) => {
    console.log("debounceFn", val);
    getLocationSuggestions(val, true);
    // _debounce(getLocationSuggestions, 1000);
  }, []);

  useEffect(() => {
    if (destinationRed) {
      setDestination(destinationRed);
      getLocationSuggestions(destinationRed, false);
    }
    if (checkInCheckOutRed) {
      setCheckInCheckOut(checkInCheckOutRed);

      let dateSplit = checkInCheckOutRed?.split("-");
      let fromDate = dateSplit[0].split("/");
      let toDate = dateSplit[1].split("/");
      setCheckInCheckOutText(
        `${fromDate[0]}/${fromDate[1]} - ${toDate[0]}/${toDate[1]}`
      );
    }
    if (roomGuestRed) setRoomGuest(roomGuestRed);
    if (propertyTypeRed) setPropertyType(propertyTypeRed);
  }, [destinationRed, checkInCheckOutRed, roomGuestRed, propertyTypeRed]);

  useEffect(() => {
    getTrendingProducts();
  }, []);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        scrollBehavior: "smooth",
      }}
    >
      <div
        style={{
          width: width,
          height: width > limit ? height : height,
          position: "relative",
        }}
      >
        <div
          id="parallax"
          style={{
            position: "relative",
            width: width > WIDTH_LIMIT ? width : "auto",
            height: width > WIDTH_LIMIT ? "100%" : "80%",
            zIndex: 0,
            top: 0,
            backgroundImage: `url(
              ${width > WIDTH_LIMIT ? BackLanding : BackLandingM}
            )`,
            backgroundSize: "100% 100%",
          }}
        />

        <img
          className="background"
          style={{
            position: "absolute",
            width: width > WIDTH_LIMIT ? width : "auto",
            height: "100%",
            fill: "cover",
            top: 0,
            zIndex: 100,
          }}
          src={width > WIDTH_LIMIT ? FrontLanding : FrontLandingM}
        />

        <div
          style={{
            position: "absolute",
            width: "100%",
            height: "100%",
            left: 0,
            right: 0,
            top: 0,
            bottom: 0,
            overflow: "hidden",
          }}
        >
          <div
            style={{
              width: "100%",
              height: "100%",
              display: "flex",
              flexDirection: "column",
              // justifyContent: "center",
              alignItems: "center",
              marginTop:
                width > limit ? height * (4 / 100) : height * (22 / 100),
            }}
          >
            <p
              style={{
                color: "#fff",
                fontSize: width > limit ? 40 : 20,
                margin: "0px",
                textAlign: "center",
              }}
            >
              {/* <span style={{ fontWeight: "400" }}>find the best place to</span>{" "} */}
              {/* <br /> <Slider /> */}
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  flexDirection: "column",
                  position: "relative",
                }}
              >
                <p
                  // className="GFG"
                  style={{
                    color: "#fff",
                    margin: "0px",
                    fontWeight: "bold",
                    fontStyle: "normal",
                    fontSize: width > WIDTH_LIMIT ? "120px" : 60,
                  }}
                >
                  DISCOVER
                </p>
              </div>
            </p>

            <div
              style={{
                width: "100%",
                marginTop: 20,
              }}
            >
              <div
                style={{
                  position: "relative",
                  width: "100%",
                  zIndex: 200,
                }}
              >
                <MainSearchBar
                  placeholder="search your destination"
                  validator="name"
                  onSearch={onSearch}
                  searchAllLocations={searchAllLocations}
                  value={destination}
                  onChange={setDestination}
                  activeTab={activeTab}
                  setActiveTab={setActiveTab}
                  debounceFn={debounceFn}
                  showLoactionSuggestion={showLoactionSuggestion}
                  locationArr={locationArr}
                  setShowLoactionSuggestion={setShowLoactionSuggestion}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        style={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          justifyContent: "center",
          alignItems: "center",
          background: "#fff",
          zIndex: 100,
        }}
      >
        <div style={{}}>
          <TrendingComponent
            Title="Trending Stays"
            type={STAYS}
            data={trendingProducts?.stay ? trendingProducts?.stay : []}
          />
          <TrendingComponent
            Title="Trending Day Outings"
            type={DAYOUTING}
            data={
              trendingProducts?.dayOuting ? trendingProducts?.dayOuting : []
            }
          />
          <TrendingComponent
            Title="Trending Campings"
            type={CAMPING}
            data={trendingProducts?.camping ? trendingProducts?.camping : []}
          />
          {/* <TrendingComponent Title="Trending Events" /> */}
          {/* <TrendingComponent Title="Trending Tours" /> */}
        </div>

        <div>
          <WhyChooseUs />
        </div>

        <div
          style={{
            position: "relative",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            height: width > WIDTH_LIMIT ? 400 : 300,
            margin: "80px 0px",
          }}
        >
          <p
            style={{
              color: "#fff",
              zIndex: 10,
              fontSize: width > WIDTH_LIMIT ? 18 : 16,
              textAlign: "center",
              fontWeight: "300",
              position: "absolute",
            }}
          >
            Experience the real beauty of <br /> adventures with{" "}
            <span style={{ fontWeight: "600" }}> Wandering Lama</span>
          </p>
          <img
            style={{
              width: width,
              height: "100%",
            }}
            src={ExperianceImage}
          />
        </div>

        <div style={{ width: "100%" }}>
          <WhatTravellersSay />
        </div>

        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <ConnectUs />
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    destinationRed: state.SearchReducer.destination,
    checkInCheckOutRed: state.SearchReducer.checkInCheckOut,
    roomGuestRed: state.SearchReducer.roomGuest,
    propertyTypeRed: state.SearchReducer.propertyType,
  };
};
export default connect(mapStateToProps)(Dashboard);
