import { React, useState } from "react";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import {
  Typography,
  TextField,
  Button,
  InputAdornment,
  IconButton,
  CircularProgress,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import "./index.css";
import SignUp from "./SignUp";
import Otp from "./Otp";
import "./index.css";
import { ApiRequestPost } from "../../data/network/services/ApiRequestPost";
import secureStorage from "../../utility/secureStorage";
import {
  SS_EMAIL,
  SS_MOBILE_NUMBER,
  SS_ROLE,
  SS_TOKEN,
  SS_USERID,
  SS_USER_NAME,
} from "../resources/secureStorageManager";
import { useSnackbar } from "notistack";
import {
  authAction,
  updateCustomerDetails,
} from "../../data/redux/action/authAction";
import { useDispatch } from "react-redux";
import ForgotPasswordEmail from "./ForgotPasswordEmail";
import { ResetPassword } from "./ResetPassword";
import ResizeListener from "../../utility/ResizeListener";
import { validateEmail } from "../../utility/inputValidations";
import { WIDTH_LIMIT as limit } from "../../data/constants/Constants";

export default function Login({
  loginOpen,
  setLoginOpen,
  isMessage,
  onSuccess,
}) {
  //@props details
  // loginOpen,setLoginOpen to open and close the modal
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();

  const [step, setStep] = useState(1);
  const [signUpOpen, setSignUpOpen] = useState(false);
  const [forgetOpen, setForgetOpen] = useState(false);
  const [otpOpen, setOtpOpen] = useState(false);
  const [resetOpen, setResetOpen] = useState(false);
  const [isResetPassword, setResetPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const { width } = ResizeListener();
  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);
  const [signUpEmail, setSignUpEmail] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [accessToken, setAccessToken] = useState("");

  const [error, setError] = useState({
    email: {
      status: "",
      message: "",
    },
    password: {
      status: "",
      message: "",
    },
  });

  const handleClose = () => {
    setLoginOpen(false);
    if (isMessage) onSuccess();
  };

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: width > limit ? "30%" : "80%",
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
    borderRadius: "13px",
  };

  const handleValidation = (event) => {
    let newErr = { ...error };
    if (event.target.id === "email") {
      if (email && !validateEmail(email)) {
        newErr.email.status = "error";
        newErr.email.message = "Enter a valid Email";
      } else {
        newErr.email.status = "";
        newErr.email.message = "";
      }
    } else if (event.target.id === "password") {
      if (password) {
        newErr.password.status = "";
        newErr.password.message = "";
      }
    }

    setError(newErr);
  };

  const handleChange = (event) => {
    if (event.target.id === "email") {
      setEmail(event.target.value.trim());
    } else if (event.target.id === "password") {
      setPassword(event.target.value.trim());
    }
  };

  const handleSubmit = () => {
    let newErr = { ...error };
    if (password && email) {
      if (!error.email.status && !error.password.status) {
        /// password Validation
        setLoading(true);
        ApiRequestPost.login(email, password)
          .then(async (res) => {
            if (res?.data?.success) {
              if (res?.data?.token !== undefined) {
                console.log("ajhsgdaksd");
                await secureStorage.setItem(SS_TOKEN, res?.data?.token);
                await secureStorage.setItem(SS_USERID, res?.data?.userId);
                await secureStorage.setItem(SS_EMAIL, res?.data?.email);
                await secureStorage.setItem(SS_USER_NAME, res?.data?.name);
                await secureStorage.setItem(SS_ROLE, res?.data?.role);
                await secureStorage.setItem(SS_MOBILE_NUMBER, res?.data?.phone);

                const custDetails = {
                  token: res?.data?.token,
                  userId: res?.data?.userId,
                  email: res?.data?.email,
                  name: res?.data?.name,
                  role: res?.data?.role,
                  phone: res?.data?.phone,
                };

                dispatch(authAction(true));
                dispatch(updateCustomerDetails(custDetails));
                handleClose();

                enqueueSnackbar("Login Successful ", {
                  variant: "success",
                  anchorOrigin: { vertical: "top", horizontal: "center" },
                });
              } else {
                setSignUpEmail(email);
                setLoginOpen(false);
                setOtpOpen(true);
                setStep(3);

                enqueueSnackbar(res?.data?.message, {
                  variant: "success",
                  anchorOrigin: { vertical: "top", horizontal: "right" },
                });
              }
            } else {
              enqueueSnackbar("Login Unsuccessfull", {
                variant: "error",
                anchorOrigin: { vertical: "top", horizontal: "right" },
              });
            }
          })
          .catch((err) => {
            enqueueSnackbar(err.response.data.message, {
              variant: "error",
              anchorOrigin: { vertical: "top", horizontal: "right" },
            });

            console.log("errerr", err);
          })
          .finally(() => {
            setLoading(false);
          });
      }
    } else {
      if (!password) {
        newErr.password = {
          status: "error",
          message: "Please enter the password",
        };
      }
      if (!email) {
        newErr.email = { status: "error", message: "Please enter the email" };
      }

      setError(newErr);
    }
  };

  return (
    <div>
      <Modal
        open={loginOpen}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={loginOpen}>
          <Box sx={style}>
            <IconButton
              style={{
                position: "absolute",
                top: "0",
                right: "0",
              }}
              onClick={() => setLoginOpen(false)}
            >
              <CloseIcon fontSize="large" />
            </IconButton>
            <div
              className="model-title"
              style={{ marginBottom: isMessage ? 0 : 40 }}
            >
              <Typography
                sx={{
                  fontFamily: "Poppins",
                  fontSize: width > limit ? "40px" : "30px",
                  fontWeight: 600,
                  color: "#000000",
                }}
              >
                LOGIN
              </Typography>
            </div>
            {isMessage && (
              <p
                style={{ fontSize: 14, textAlign: "center", marginBottom: 40 }}
              >
                Please login before booking!
              </p>
            )}

            <div className="login-fields">
              <TextField
                id="email"
                label="Email"
                placeholder="Enter Your Email"
                size="medium"
                required
                sx={{ width: width > limit ? "80%" : "100%" }}
                autoFocus
                error={error.email.status === "error"}
                onBlur={(e) => handleValidation(e)}
                onChange={(e) => handleChange(e)}
                value={email}
                helperText={
                  error.email.status === "error" ? error.email.message : ""
                }
              />
              <TextField
                id="password"
                label="Password"
                placeholder="Enter Your Password"
                type={showPassword ? "text" : "password"}
                required
                sx={{ width: width > limit ? "80%" : "100%" }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                      >
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                error={error.password.status === "error"}
                onBlur={(e) => handleValidation(e)}
                onChange={(e) => handleChange(e)}
                value={password}
                helperText={
                  error.password.status === "error"
                    ? error.password.message
                    : ""
                }
              />
              <Button
                variant="contained"
                size="small"
                onClick={() => handleSubmit()}
                sx={{
                  width: 150,
                  height: 40,
                  backgroundColor: "#000000",
                  color: "#FFF",
                  textTransform: "none",
                  fontSize: "20px",
                  fontWeight: "500",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  ":hover": {
                    bgcolor: "#000000",
                    fontWeight: 800,
                  },
                }}
              >
                {loading ? (
                  <CircularProgress style={{ color: "#fff" }} size={20} />
                ) : (
                  "Login"
                )}
              </Button>
              <div>
                <Typography
                  sx={{
                    fontFamily: "Poppins",
                    fontSize: "16px",
                    fontWeight: 300,
                    color: "#6C6A6A",
                    textAlign: "center",
                    paddingBottom: "10px",
                    //  width: width > limit ? "80%" : "100%"
                  }}
                >
                  <span
                    onClick={() => {
                      setLoginOpen(false);
                      setForgetOpen(true);
                      setStep(4);
                    }}
                    style={{ textDecoration: "underline", cursor: "pointer" }}
                  >
                    Forgot Password?
                  </span>
                </Typography>
                <Typography
                  sx={{
                    fontFamily: "Poppins",
                    fontSize: "16px",
                    fontWeight: 300,
                    color: "#6C6A6A",
                    textAlign: "center",
                  }}
                >
                  Don't have an account?{" "}
                  <span
                    className="span-class"
                    onClick={() => {
                      setLoginOpen(false);
                      setSignUpOpen(true);
                      setStep(2);
                    }}
                    style={{ textDecoration: "underline", cursor: "pointer" }}
                  >
                    Register Now
                  </span>
                </Typography>
              </div>
            </div>
          </Box>
        </Fade>
      </Modal>
      {step === 2 && (
        <SignUp
          setStep={setStep}
          setLoginOpen={setLoginOpen}
          signUpOpen={signUpOpen}
          setOtpOpen={setOtpOpen}
          setSignUpOpen={setSignUpOpen}
          setSignUpEmail={setSignUpEmail}
        />
      )}

      {step === 3 && (
        <Otp
          setLoginOpen={setLoginOpen}
          email={signUpEmail}
          setStep={setStep}
          otpOpen={otpOpen}
          setOtpOpen={setOtpOpen}
          setResetOpen={setResetOpen}
          isEmailOrNumber="Email"
          senderDetails={signUpEmail}
          isResetPassword={isResetPassword}
          setAccessToken={setAccessToken}
        />
      )}

      {step === 4 && (
        <ForgotPasswordEmail
          setStep={setStep}
          forgetOpen={forgetOpen}
          setOtpOpen={setOtpOpen}
          setForgetOpen={setForgetOpen}
          setSignUpEmail={setSignUpEmail}
          setResetPassword={setResetPassword}
        />
      )}

      {step === 5 && (
        <ResetPassword
          accessToken={accessToken}
          setAccessToken={setAccessToken}
          email={signUpEmail}
          setStep={setStep}
          setLoginOpen={setLoginOpen}
          open={resetOpen}
          setResetOpen={setResetOpen}
          setResetPassword={setResetPassword}
        />
      )}
    </div>
  );
}
