export const SUCCESS = "SUCCESS";
export const FAILURE = "FAILURE";
export const ADD_PROPERTY = "ADD_PROPERTY";
export const VIEW_PROPERTY = "VIEW_PROPERTY";
export const CUSTOMER_ENQUIRY = "CUSTOMER_ENQUIRY";
export const MAX_PRICE_RANGE = 50000;

export const STAYS = "STAYS";
export const EXPERIENCES = "EXPERIENCES";
export const DAYOUTING = "DAYOUTING";
export const CAMPING = "CAMPING";
export const TOURS = "TOURS";
export const EVENTS = "EVENTS";
