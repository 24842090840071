import {
  DESTINATION,
  CHECK_IN_CHECK_OUT,
  ROOM_GUEST,
  PRICE_RANGE,
  PROPERTY_TYPE,
} from "../constants/reduxConstants";

const initialState = {
  destination: "",
  checkInCheckOut: "",
  roomGuest: "",
  priceRange: "",
  propertyType: "",
};

const SearchReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case DESTINATION:
      return {
        ...state,
        destination: payload,
      };
    case CHECK_IN_CHECK_OUT:
      return {
        ...state,
        checkInCheckOut: payload,
      };
    case ROOM_GUEST:
      return {
        ...state,
        roomGuest: payload,
      };
    case PROPERTY_TYPE:
      return {
        ...state,
        propertyType: payload,
      };
    case PRICE_RANGE:
      return {
        ...state,
        priceRange: payload,
      };
    default:
      return state;
  }
};

export default SearchReducer;
