// @flow
import React from "react";
import { SIDEBAR_COLOR } from "../../resources/colorsManager";
import CustomLoader from "../customLoader";

export const CustomButton = ({
  title,
  onClick,
  loading,
  disabled,
  styles,
  textStyles,
  backgroundColor,
  loginButton,
  dataTestId = "button",
  icon,
  ionDirection = "left",
  mobileViewBtn,
  onKeyDown,
  buttonLoader = 20,
  iconStyles,
}) => {
  return (
    <button
      data-testid={dataTestId}
      style={{
        backgroundColor:
          disabled || loading
            ? "lightgrey"
            : backgroundColor
            ? backgroundColor
            : "#101010",
        borderRadius: 8,
        alignSelf: "center",
        padding: 6,
        cursor: !disabled ? "pointer" : "not-allowed",
        minWidth: loginButton ? 304 : "80",
        border: 0,
        outline: "none",
        minHeight: 40,
        width: mobileViewBtn ? 120 : 150,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        ...styles,
      }}
      disabled={loading || disabled ? true : false}
      onClick={onClick}
      onKeyDown={onKeyDown}
    >
      <div
        style={{
          marginLeft: 5,
          alignSelf: "center",
          marginRight: 5,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
        }}
      >
        {ionDirection === "left" && (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {icon && (
              <img
                style={{ marginRight: 5, ...iconStyles }}
                src={icon}
                alt="icon"
              />
            )}{" "}
          </div>
        )}
        {loading ? (
          <CustomLoader size={buttonLoader} />
        ) : (
          <p
            style={{
              fontSize: mobileViewBtn ? 12 : 14,
              cursor: "pointer",
              color: disabled ? "grey" : "white",
              padding: 0,
              margin: 0,
              fontWeight: "normal",
              alignSelf: "center",
              ...textStyles,
            }}
            className="roboto"
          >
            {title}
          </p>
        )}
        {ionDirection === "right" && (
          <div
            style={{
              marginLeft: 5,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {" "}
            {icon && icon}{" "}
          </div>
        )}
      </div>
    </button>
  );
};
