export const PRIMARY_COLOR = "#0D1321";
export const SECONDARY_COLOR = "#3BE07E";
export const HEADER_COLOR = "#1D2D44";

export const SIDEBAR_COLOR = "#82C34C";

export const BG_COLOR = "#F3F3F3";

export const WHITE_COLOR = "#FFFFFF";
export const BLACK_COLOR = "#111111";

export const LOGIN_BG_COLOR = "#F9F9F9";
