import React, { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router";
import ResizeListener from "../../utility/ResizeListener";
import TopNavBarDropdown from "../components/TopNavBarDropdown";
import Login from "../modals/Login";
import {
  ProfileIcon,
  WanderinglamaColor,
  WanderinglamaLogo,
} from "../resources/assetsManager";
import {
  WIDTH_LIMIT,
  WIDTH_LIMIT as limit,
} from "../../data/constants/Constants";

import "./layout.css";
import secureStorage from "../../utility/secureStorage";
import { SS_TOKEN } from "../resources/secureStorageManager";

const Navbar = ({ isAuthenticated, customerDetails }) => {
  const dispatch = useDispatch();
  const { height, width } = ResizeListener();
  const navigate = useNavigate();
  let location = useLocation();
  let { pathname } = { ...location };
  const [showDropdown, setShowDropdown] = useState(false);
  const [changeColor, setChangeColor] = useState(false);
  const [loginOpen, setLoginOpen] = React.useState(false);

  useEffect(() => {
    setTimeout(() => {
      const token = secureStorage.getItem(SS_TOKEN);
      if (pathname.includes("/profile") && !token) {
        console.log("agdkasd");
        setLoginOpen(true);
      }
    }, 2800);
  }, []);

  useEffect(() => {
    window.addEventListener(
      "scroll",
      () => {
        if (window.pageYOffset > 10) {
          setShowDropdown(false);
        }
        if (window.pageYOffset > (width > WIDTH_LIMIT ? 700 : 550)) {
          setChangeColor(true);
        } else {
          setChangeColor(false);
        }
      },
      false
    );
  }, []);

  if (pathname.includes("/home")) {
    return (
      <div
        style={{
          width: "100%",
          height: height * 0.1,
          maxHeight: "100px",
          minHeight: "80px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          position: "fixed",
          backgroundColor: "transparent",
          top: 0,
          boxSizing: "border-box",
          background: changeColor ? "#fff" : "transparent",
          borderBottom: changeColor ? "1px solid #ddd" : 0,
          zIndex: 210,
        }}
      >
        <div
          style={{
            width: "100%",
            display: "flex",
            padding: width > limit ? "0px 30px" : "0px 15px",
            alignItems: "center",
            justifyContent: "space-between",
            height: "100%",
            transition: "width 0.5s ease-in-out",
            position: "relative",
          }}
        >
          <div
            style={{ display: "flex", alignItems: "center", cursor: "pointer" }}
            onClick={() => navigate("/home")}
          >
            <img
              src={changeColor ? WanderinglamaColor : WanderinglamaLogo}
              style={{
                width: width > limit ? 150 : 100,
                height: 40,
                objectFit: "contain",
              }}
              alt="WanderinglamaLogo"
            />
          </div>

          <div
            style={{
              display: "flex",
              alignItems: "center",
              height: "100%",
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginLeft: 25,
                cursor: "pointer",
                background: changeColor ? "#fff" : "#050505",
                padding: "7px 20px",
                borderRadius: 5,
              }}
              onClick={() => {
                if (isAuthenticated) {
                  if (showDropdown) setShowDropdown(false);
                  else setShowDropdown(true);
                } else {
                  setLoginOpen(true);
                }
              }}
            >
              {/* <img
                alt="DropDown"
                src={ProfileIcon}
                style={{
                  width: 22,
                  marginRight: width > limit ? 20 : 10,
                }}
              /> */}

              <p
                style={{
                  color: changeColor ? "#000" : "#fff",
                  fontWeight: "bold",
                  fontSize: width > limit ? 16 : 14,
                }}
                className="roboto"
              >
                {isAuthenticated ? customerDetails?.name : "Login / Signup"}
              </p>
            </div>

            {showDropdown && (
              <div
                style={{
                  backgroundColor: "white",
                  width: 180,
                  position: "absolute",
                  right: pathname.includes("/home") ? "0px" : 26,
                  top: 75,
                  borderRadius: 10,
                  right: 15,
                  boxShadow:
                    "rgb(9 30 66 / 25%) 0px 4px 8px -2px, rgb(9 30 66 / 31%) 0px 0px 1px",
                  transition: "0.5s all",
                  zIndex: "1111111111111",
                }}
                onMouseLeave={() => {
                  setShowDropdown(false);
                }}
                // ref={wrapperRef}
              >
                <TopNavBarDropdown
                  clickHandle={() => {
                    setShowDropdown(false);
                  }}
                />
              </div>
            )}
          </div>
        </div>

        <Login loginOpen={loginOpen} setLoginOpen={setLoginOpen} />
      </div>
    );
  } else {
    return (
      <div
        style={{
          width: "100%",
          height: height * 0.1,
          maxHeight: "100px",
          minHeight: "80px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          position: "sticky",
          backgroundColor: "transparent",
          top: 0,
          zIndex: 210,
          //minWidth: "650px",
          boxSizing: "border-box",
          background: "#fff",
          borderBottom: "1px solid #ddd",
        }}
      >
        <div
          style={{
            width: "100%",
            display: "flex",
            padding: width > limit ? "0px 30px" : "0px 15px",
            alignItems: "center",
            justifyContent: "space-between",
            height: "100%",
            transition: "width 0.5s ease-in-out",
            position: "relative",
          }}
        >
          <div
            style={{ display: "flex", alignItems: "center", cursor: "pointer" }}
            onClick={() => navigate("/home")}
          >
            <img
              src={WanderinglamaColor}
              style={{
                width: width > limit ? 150 : 100,
                height: 40,
                objectFit: "contain",
              }}
              alt="WanderinglamaLogo"
            />
          </div>

          <div
            style={{ display: "flex", alignItems: "center", height: "100%" }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginLeft: 25,
                cursor: "pointer",
              }}
              onClick={() => {
                if (isAuthenticated) {
                  if (showDropdown) setShowDropdown(false);
                  else setShowDropdown(true);
                } else {
                  setLoginOpen(true);
                }
              }}
            >
              {/* <img
                alt="DropDown"
                src={ProfileIcon}
                style={{
                  width: 22,
                  marginRight: width > limit ? 20 : 10,
                }}
              /> */}

              <p
                style={{
                  color: "#000",
                  fontWeight: "bold",
                }}
                className="roboto"
              >
                {isAuthenticated ? customerDetails?.name : "Login / Signup"}
              </p>
            </div>

            {showDropdown && (
              <div
                style={{
                  backgroundColor: "white",
                  width: 150,
                  minHeight: 120,
                  position: "absolute",
                  right: pathname.includes("/home") ? "0px" : 26,
                  top: 70,
                  borderRadius: 4,
                  boxShadow:
                    "rgb(9 30 66 / 25%) 0px 4px 8px -2px, rgb(9 30 66 / 31%) 0px 0px 1px",
                  transition: "0.5s all",
                  zIndex: "1111111111111",
                }}
                onMouseLeave={() => {
                  setShowDropdown(false);
                }}
                // ref={wrapperRef}
              >
                <TopNavBarDropdown
                  clickHandle={() => {
                    setShowDropdown(false);
                  }}
                />
              </div>
            )}
          </div>
        </div>

        <Login loginOpen={loginOpen} setLoginOpen={setLoginOpen} />
      </div>
    );
  }
};

const mapStateToProps = (state) => {
  return {
    isAuthenticated: state.AuthReducer.isAuthenticated,
    customerDetails: state.AuthReducer.customerDetails,
  };
};

export default connect(mapStateToProps)(Navbar);
