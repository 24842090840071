import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useSnackbar } from 'notistack';
import {
  Typography,
  TextField,
  Box,
  Button,
  InputLabel,
  FormControl,
  Grid
} from '@mui/material';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';

import TrendingComponent from '../dashboard/TrendingComponent';
import ContactUsSuccess from '../../modals/ContactUsSuccess';
import CustomLoader from '../../components/customLoader';
import './index.css';

import { ApiRequestGet } from '../../../data/network/services/ApiRequestGet';
import { ApiRequestPost } from '../../../data/network/services/ApiRequestPost';
import countryCodes from '../../../data/constants/CountryCode.json';
import ResizeListener from '../../../utility/ResizeListener';
import { convertOnlyDate } from '../../../utility/dateFormatter';
import {
  validateEmail,
  validateName,
  validatePhoneNumber,
} from '../../../utility/inputValidations';
import { WIDTH_LIMIT as limit } from '../../../data/constants/Constants';
import { STAYS } from '../../resources/constantsManager';

const ContactUs = ({ customerDetails }) => {
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);

  const [locationArr, setLocationArr] = useState([]);
  const [trendingProducts, setTrendingProducts] = useState({});
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [countryIsdCode, setCountryIsdCode] = useState('+91');
  const [phone, setPhone] = useState('');
  const [destination, setDestination] = useState('');
  const [noOfPeople, setNoOfPeople] = useState(2);
  const currentDate = new Date();
  const [travelDate, setTravelDate] = useState(currentDate);

  const [openSuccessModal, setOpenSuccessModal] = useState(false);

  const { width, height } = ResizeListener();

  useEffect(() => {
    window.scrollTo(0, 0);
    searchAllLocations();
    getTrendingProducts();
  }, []);

  useEffect(() => {
    if (customerDetails) {
      setName(customerDetails.name);
      setPhone(customerDetails.phone);
      setEmail(customerDetails.email);
    }
  }, [customerDetails]);

  useEffect(() => {
    setTimeout(() => {
      if (openSuccessModal) {
        setOpenSuccessModal(false);
      }
    }, 5000);

    return () => clearTimeout();
  }, [openSuccessModal]);

  const searchAllLocations = () => {
    ApiRequestGet.getAllLocationSuggestions()
      .then((res) => {
        setLocationArr(res?.data?.data);
      })
      .catch((err) => {
        console.log('getLocationSuggestions error', err);
      });
  };

  const getTrendingProducts = () => {
    ApiRequestGet.getTrendingProductsByType('stay')
      .then((res) => {
        if (res?.data?.success) setTrendingProducts(res?.data?.data);
        else
          enqueueSnackbar(res?.data?.message, {
            variant: 'error',
            anchorOrigin: { vertical: 'top', horizontal: 'right' },
          });
      })
      .catch((err) => {
        enqueueSnackbar('Something went wrong!!!', {
          variant: 'error',
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
        });
      });
  };

  const [error, setError] = useState({
    name: {
      status: '',
      message: '',
    },
    email: {
      status: '',
      message: '',
    },
    countryIsdCode: {
      status: '',
      message: '',
    },
    phone: {
      status: '',
      message: '',
    },
    destination: {
      status: '',
      message: '',
    },
    noOfPeople: {
      status: '',
      message: '',
    },
    travelDate: {
      status: '',
      message: '',
    },
  });

  const handleChange = (event) => {
    if (event.target.id === 'email') {
      setEmail(event.target.value.trim());
    } else if (event.target.id === 'name') {
      setName(event.target.value);
    } else if (event.target.id === 'phone') {
      if (
        event.target.value === '' ||
        validatePhoneNumber(event.target.value)
      ) {
        setPhone(event.target.value);
      }
    } else if (event.target.id === 'noOfPeople') {
        setNoOfPeople(event.target.value);
    } else if (event.target.name === 'countryIsdCode') {
      setCountryIsdCode(event.target.value);
    } else if (event.target.name === 'destination') {
      setDestination(event.target.value);
      setError({
        ...error,
        destination: {
          status: '',
          message: '',
        },
      });
    }
  };

  const resetError = (fieldName) => {
    let newErr = { ...error };
    newErr[fieldName].status = '';
    newErr[fieldName].message = '';
  };

  const handleFieldValidation = (newErr, field, value, validator, errorMessage) => {
    if (value && !validator(value)) {
      newErr[field].status = 'error';
      newErr[field].message = errorMessage;
    } else {
      resetError(field);
    }
  };

  const handleValidation = (event) => {
    let newErr = { ...error };
  
    switch (event.target.id) {
      case 'email':
        handleFieldValidation(newErr, 'email', email, validateEmail, 'Enter a valid Email');
        break;
      case 'phone':
        handleFieldValidation(newErr, 'phone', phone, validatePhoneNumber, 'Enter a valid phone number');
        break;
      case 'name':
        if (name) {
          handleFieldValidation(newErr, 'name', name, validateName, 'Enter a valid name');
          setName(name.trim());
        }
        break;
      case 'noOfPeople':
        if (noOfPeople && noOfPeople < 2) {
          newErr.noOfPeople.status = 'error';
          newErr.noOfPeople.message = 'A minimum of 2 has to be selected';
        } else {
          resetError('noOfPeople');
        }
        break;
      default:
        break;
    }
  
    setError(newErr);
  };

  const handleSubmit = () => {
    let submitCheck = true;
    const currentDate = new Date();

    let newErr = { ...error };
    if (!name) {
      newErr.name = { status: 'error', message: 'Please enter the name' };
      submitCheck = false;
    }
    if (!noOfPeople) {
      newErr.noOfPeople = {
        status: 'error',
        message: 'Please enter the number of people',
      };
      submitCheck = false;
    }
    if (!email) {
      newErr.email = { status: 'error', message: 'Please enter the email' };
      submitCheck = false;
    }
    if (!phone) {
      newErr.phone = {
        status: 'error',
        message: 'Please enter the mobile number',
      };
      submitCheck = false;
    }
    if (!countryIsdCode) {
      newErr.countryIsdCode = {
        status: 'error',
        message: 'Please select the country code',
      };
      submitCheck = false;
    }
    if (!destination) {
      newErr.destination = {
        status: 'error',
        message: 'Please select a destination',
      };
      submitCheck = false;
    }
    if (!travelDate) {
      newErr.travelDate = {
        status: 'error',
        message: 'Please select the date of travelling',
      };
      submitCheck = false;
    }

    setError(newErr);

    if (submitCheck) {
      if (
        !error.name.status &&
        !error.email.status &&
        !error.countryIsdCode.status &&
        !error.phone.status &&
        !error.destination.status &&
        !error.noOfPeople.status &&
        !error.travelDate.status
      ) {
        let newLead;
        newLead = {
          name: name,
          email: email,
          noOfPeople: noOfPeople,
          phone: phone,
          code: countryIsdCode,
          travellingDate: convertOnlyDate(travelDate),
          destination: destination,
        };

        ApiRequestPost.addLead(newLead)
          .then((res) => {
            setLoading(true);
            if (res.data.success) {
              setLoading(true);

              res?.data?.success
                ? setOpenSuccessModal(true)
                : enqueueSnackbar('Something went wrong!', {
                    variant: res?.data?.success ? 'success' : 'error',
                    anchorOrigin: { vertical: 'top', horizontal: 'right' },
                  });

              setName('');
              setEmail('');
              setCountryIsdCode('+91');
              setPhone('');
              setDestination('');
              setNoOfPeople(2);
              setTravelDate(currentDate);
            }
          })
          .catch((err) => {
            enqueueSnackbar(err?.response?.data?.message ?? err?.message, {
              variant: 'error',
              anchorOrigin: { vertical: 'top', horizontal: 'right' },
            });
          })
          .finally(() => {
            setLoading(false);
          });
      }
    } else {
      enqueueSnackbar('Please fill the required fields', {
        variant: 'error',
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
      });
    }
  };

  const handleDateValidation = (field, value) => {
    let newErr = { ...error };
    const parsedDate = dayjs(value);
    if (!parsedDate.isValid()) {
      newErr[field].status = 'error';
      newErr[field].message = 'Invalid Date';
      setError(newErr);
    }
    if (parsedDate.isBefore(dayjs(), 'day')) {
      newErr[field].status = 'error';
      newErr[field].message = 'Cannot be past date';
      setError(newErr);
    }
  };

  return (
    <React.Fragment>
      {loading && (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: width,
            height: height,
          }}
        >
          <CustomLoader />
        </div>
      )}
      <Box className='contactus-container'>
        <Box className='contactus-title'>
          <Typography
            sx={{
              fontFamily: 'Poppins',
              fontSize: '2rem',
              fontWeight: 600,
              color: '#000000',
              textAlign: 'center',
            }}
          >
            Make your travel dreams a reality with <br />
            <span style={{ fontWeight: '600', color: '#428D04' }}>
              {' '}
              Wandering Lama
            </span>
          </Typography>
        </Box>
        <Box className='contactus-form'>
          <FormControl variant='standard' className='form-field'>
            <InputLabel shrink htmlFor='name' className='field-label' required>
              Name
            </InputLabel>
            <TextField
              id='name'
              placeholder='Enter Your Name'
              size='medium'
              required
              type='text'
              sx={{ marginTop: '24px' }}
              autoFocus
              error={error.name.status === 'error'}
              helperText={
                error.name.status === 'error' ? error.name.message : ''
              }
              onBlur={(e) => handleValidation(e)}
              onChange={(e) => handleChange(e)}
              value={name}
            />
          </FormControl>
          <FormControl variant='standard' className='form-field'>
            <InputLabel shrink htmlFor='email' className='field-label' required>
              Email
            </InputLabel>
            <TextField
              id='email'
              placeholder='Enter Your Email'
              size='medium'
              required
              type='text'
              sx={{ marginTop: '24px' }}
              autoFocus
              error={error.email.status === 'error'}
              helperText={
                error.email.status === 'error' ? error.email.message : ''
              }
              onBlur={(e) => handleValidation(e)}
              onChange={(e) => handleChange(e)}
              value={email}
            />
          </FormControl>
          <Grid
            container
            sx={{
              display: 'flex',
              width: '100%',
              paddingTop: width > limit ? '0px' : '30px',
              marginTop: '8px',
            }}
          >
            <Grid item xs={4} sm={4} md={2} lg={2} xl={2}>
              <FormControl variant='standard' fullWidth className='form-field'>
                <InputLabel shrink className='field-label' required>
                  Country Code
                </InputLabel>
                <TextField
                  id='countryIsdCode'
                  name='countryIsdCode'
                  value={countryIsdCode}
                  onChange={(e) => handleChange(e)}
                  sx={{ marginTop: '24px' }}
                  required
                  select
                  SelectProps={{
                    native: true,
                  }}
                  error={error.countryIsdCode.status === 'error'}
                  helperText={
                    error.countryIsdCode.status === 'error'
                      ? error.countryIsdCode.message
                      : ''
                  }
                >
                  {countryCodes.countries.map((data, index) => (
                    <option key={index} value={data?.code}>
                      {data?.code}
                    </option>
                  ))}
                </TextField>
              </FormControl>
            </Grid>
            <Grid item xs={8} sm={8} md={10} lg={10} xl={10}>
              <FormControl variant='standard' className='form-field' required>
                <InputLabel
                  shrink
                  htmlFor='phone'
                  className='field-label'
                  required
                >
                  Phone
                </InputLabel>
                <TextField
                  id='phone'
                  placeholder='Enter Your Mobile Number'
                  value={phone}
                  size='medium'
                  required
                  type='text'
                  sx={{ marginTop: '24px' }}
                  inputProps={{ maxLength: 10 }}
                  onBlur={(e) => handleValidation(e)}
                  onChange={(e) => handleChange(e)}
                  error={error.phone.status === 'error'}
                  helperText={
                    error.phone.status === 'error' ? error.phone.message : ''
                  }
                />
              </FormControl>
            </Grid>
          </Grid>
          <Box sx={{ width: '100%' }}>
            <FormControl variant='standard' className='form-field' required>
              <InputLabel shrink htmlFor='destination' className='field-label'>
                Destination
              </InputLabel>
              <TextField
                id='destination'
                name='destination'
                value={destination}
                onBlur={(e) => handleValidation(e)}
                onChange={(e) => handleChange(e)}
                sx={{ marginTop: '20px' }}
                required
                select
                SelectProps={{
                  native: true,
                }}
                error={error.destination.status === 'error'}
                helperText={
                  error.destination.status === 'error'
                    ? error.destination.message
                    : ''
                }
              >
                <>
                  <option value='' selected disabled>
                    Please Select
                  </option>
                  {locationArr?.map((location) => (
                    <option key={location.id} value={location?.value}>
                      {location?.name}
                    </option>
                  ))}
                </>
              </TextField>
            </FormControl>
          </Box>
          <FormControl variant='standard' className='form-field' required>
            <InputLabel shrink htmlFor='noOfPeople' className='field-label'>
              No of people
            </InputLabel>
            <TextField
              id='noOfPeople'
              placeholder='No of people'
              value={noOfPeople}
              defaultValue={2}
              size='medium'
              type='number'
              required
              InputProps={{
                inputProps: { min: 2 },
              }}
              sx={{ width: '100%', marginTop: '24px' }}
              error={error.noOfPeople.status === 'error'}
              onBlur={(e) => handleValidation(e)}
              onChange={(e) => handleChange(e)}
              helperText={
                error.noOfPeople.status === 'error'
                  ? error.noOfPeople.message
                  : ''
              }
            />
          </FormControl>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DesktopDatePicker
              id='travelDate'
              disablePast
              inputFormat='DD/MM/YYYY'
              value={travelDate}
              onChange={(e) => {
                let newErr = { ...error };
                newErr.travelDate.status = '';
                newErr.travelDate.message = '';
                setError(newErr);
                setTravelDate(e.$d);
                handleDateValidation('travelDate', e);
              }}
              sx={{ width: '100%' }}
              PaperProps={{
                sx: {
                  '& .MuiPickersDay-root': {
                    '&.Mui-selected': {
                      backgroundColor: '#82C34C',
                    },
                  },
                  '&MuiPickersDay-dayWithMargin': {
                    backgroundColor: '#82C34C',
                  },
                },
              }}
              renderInput={(params) => (
                <FormControl variant='standard' className='form-field' required>
                  <InputLabel
                    shrink
                    htmlFor='travelDate'
                    className='field-label'
                  >
                    Date of Travelling
                  </InputLabel>
                  <TextField
                    {...params}
                    required
                    sx={{
                      width: '100%',
                      marginTop: '24px',
                    }}
                    error={error.travelDate.status === 'error'}
                    helperText={
                      error.travelDate.status === 'error'
                        ? error.travelDate.message
                        : ''
                    }
                  />
                </FormControl>
              )}
            />
          </LocalizationProvider>
          <div className='submit-btn'>
            <Button
              onClick={() => {
                handleSubmit();
              }}
              variant='contained'
              size='small'
              sx={{
                backgroundColor: '#000000',
                color: '#FFF',
                fontWeight: '500',
                textTransform: 'none',
                fontSize: '1.1rem',
                fontFamily: 'Poppins',
                ':hover': {
                  bgcolor: '#000000',
                  fontWeight: 800,
                },
                padding: '0.6rem 0',
                width: '10rem',
              }}
            >
              Submit
            </Button>
          </div>
        </Box>
      </Box>
      <Box className='exclusive-stays'>
        <TrendingComponent
          Title='Our Exclusive Stays'
          type={STAYS}
          data={trendingProducts ?? []}
          disableSlider={true}
        />
      </Box>
      {openSuccessModal && (
        <ContactUsSuccess
          open={openSuccessModal}
          handleClose={() => setOpenSuccessModal(false)}
        />
      )}
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    customerDetails: state.AuthReducer.customerDetails,
  };
};

export default connect(mapStateToProps)(ContactUs);
