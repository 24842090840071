import React from "react";
import Modal from "@mui/material/Modal";
import Backdrop from "@mui/material/Backdrop";
import ResizeListener from "../../utility/ResizeListener";
import { WIDTH_LIMIT } from "../../data/constants/Constants";
import { PaymentSuccessIcon } from "../resources/assetsManager";
import { WHITE_COLOR } from "../resources/colorsManager";
import { CustomButton } from "../components/customButton/CustomButton";
import { useNavigate } from "react-router";

const PaymentSuccess = ({ handleClose, bookingNo }) => {
  const navigate = useNavigate();
  const { width } = ResizeListener();

  return (
    <Modal
      open={true}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <div
        style={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: width > WIDTH_LIMIT ? "30%" : "80%",
          background: "#fff",
          boxShadow: 24,
          borderRadius: "8px",
        }}
      >
        <div
          style={{
            padding: "30px 45px",
            background: "#428D04",
            display: "flex",
            alignItems: "center",
            borderTopLeftRadius: 8,
            borderTopRightRadius: 8,
          }}
        >
          <img src={PaymentSuccessIcon} />

          <p style={{ fontWeight: "bold", color: "#fff", marginLeft: 20 }}>
            Payment Successful!
          </p>
        </div>

        <div
          style={{
            padding: "30px 45px",
            background: "#fff",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            borderBottomLeftRadius: 8,
            borderBottomRightRadius: 8,
          }}
        >
          <p style={{ fontWeight: "bold", fontSize: 15, textAlign: "center" }}>
            You successfully created your booking
          </p>
          <p style={{ fontSize: 15 }}>Booking ID: {bookingNo}</p>
        </div>

        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            padding: "0px 30px 30px 30px",
          }}
        >
          <CustomButton
            // loading={loading}
            title="Print Invoice"
            styles={{
              background: WHITE_COLOR,
              width: "49%",
              borderRadius: "4px",
              border: "1px solid black",
            }}
            textStyles={{
              // color: WHITE_COLOR,
              color: "black",
              fontSize: 14,
              fontWeight: "500",
            }}
            onClick={() => {
              // DOWNLOAD INVOICE
              navigate("/profile/bookings", { state: true });
            }}
            // disabled={ disabled }
          />

          <CustomButton
            // loading={loading}
            title="Awesome"
            styles={{
              width: "49%",
              borderRadius: "4px",
              border: 0,
            }}
            textStyles={{
              color: WHITE_COLOR,
              fontSize: 14,
              fontWeight: "500",
            }}
            onClick={() => {
              navigate("/profile/bookings", { state: true });
            }}
            // disabled={ disabled }
          />
        </div>
      </div>
    </Modal>
  );
};

export default PaymentSuccess;
