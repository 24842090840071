const getMetaDataDetails = (pathname) => {
  const metaTagDetails = [];
  let metaTitle = "";
  let metaDescription = "";
  let metaKeywords = "";
  const path = pathname.split("/")[1];
  switch (path) {
    case "home":
      metaTitle =
        "Wandering lama | Discover thrilling adventures, activities, and tours";
      metaDescription =
        "Make your travel dreams a reality with Wandering lama. We offer unique adventures, activities, and tours for all types of travelers.";
      metaKeywords =
        "Adventure tours, Experiential travel, Karnataka tourism, Homestays, Nature-friendly stays, Camping, Outing, Travel platform, Sustainable tourism,Authentic travel experiences";
      break;
    case "about-us":
      metaTitle =
        "About Wandering lama - Discover Our Vision, Values, and Passion for Travel";
      metaDescription =
        "Learn more about Wandering lama and our mission to provide hassle-free, experiential stays for all types of travelers. Discover our exclusive properties, nature-friendly stays, and unique offerings.";
      metaKeywords =
        "Experiential stays, Nature-friendly, Unique offerings, Hassle-free travel, Authentic travel, Travel platform, Exclusive properties, Boathouses, Cruise tours, Karnataka travel, Discerning travelers, Passion for travel, Vision for the future of travel, Travel company, Homestays";
      break;
    case "privacy-policy":
      metaTitle = "Privacy Policy | Wadering lama";
      metaDescription =
        "Explore Wandering lama's privacy policy. Learn how we protect your data and ensure your online privacy while you wander through our offerings.";
      metaKeywords =
        "Wandering lama, Privacy Policy, Data Protection, Online Privacy, Information Security, User Data, Personal Information, Privacy Assurance, Data Usage, Privacy Measures";
      break;
    default:
      metaTitle =
        "Wandering lama | Discover thrilling adventures, activities, and tours";
      metaDescription =
        "Make your travel dreams a reality with Wandering lama. We offer unique adventures, activities, and tours for all types of travelers.";
      metaKeywords =
        "Adventure tours, Experiential travel, Karnataka tourism, Homestays, Nature-friendly stays, Camping, Outing, Travel platform, Sustainable tourism,Authentic travel experiences";
  }

  metaTagDetails.push(metaTitle);
  metaTagDetails.push(metaDescription);
  metaTagDetails.push(metaKeywords);
  return metaTagDetails;
};

export default getMetaDataDetails;
