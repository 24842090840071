import { LOADING_END, LOADING_START } from "../constants/reduxConstants";

const initialState = {
  isLoading: true,
};

export const fullScreenLoadingReducer = (
  state = initialState,
  { type, payload }
) => {
  switch (type) {
    case LOADING_START:
      return {
        isLoading: true,
      };

    case LOADING_END:
      return {
        isLoading: false,
      };

    default:
      return state;
  }
};
