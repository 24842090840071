import { useEffect } from 'react';
import { useLocation } from 'react-router';
import getMetaDataDetails from '../utility/getMetaDataDetails';
import { metaData } from '../utility/Constants';

const RouteChangeTracker = () => {

    const getMetaDetails = (metaTitle, metaDescription, metaKeywords) => {
        const metaDataConstants = metaData;

        document
            .getElementsByTagName(metaDataConstants.metaTag)
            .namedItem(metaDataConstants.description)
            .setAttribute(metaDataConstants.content, metaDescription);
        document
            .getElementsByTagName(metaDataConstants.metaTag)
            .namedItem(metaDataConstants.keywords)
            .setAttribute(metaDataConstants.content, metaKeywords);
        document.title = metaTitle;

        const ogMetaTitle = document.querySelector(`meta[property="${metaDataConstants.ogTitle}"]`);
        const ogMetaDescription = document.querySelector(`meta[property="${metaDataConstants.ogDescription}"]`);
        const ogMetaUrl = document.querySelector(`meta[property="${metaDataConstants.ogUrl}"]`);
        if(ogMetaTitle && ogMetaDescription && ogMetaUrl) {
            ogMetaTitle.setAttribute(metaDataConstants.content, metaTitle);
            ogMetaDescription.setAttribute(metaDataConstants.content, metaDescription);
            ogMetaUrl.setAttribute(metaDataConstants.content, window.location.href);
        }
    };

    const location = useLocation();

    useEffect(() => {
        const [metaTitle, metaDescription, metaKeywords] = getMetaDataDetails(location.pathname);
        if (metaTitle && metaDescription && metaKeywords) {
            getMetaDetails(metaTitle, metaDescription, metaKeywords);
        }
    }, [location]);
};

export default RouteChangeTracker;