function validateEmail(email) {
  let re = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
  return re.test(email);
}

function validatePhoneNumber(num) {
  let regex = /^\d+$/g;
  return num.match(regex);
}

function validateName(name) {
  let regex = /^[a-z ,.'-]+$/i;
  return name.match(regex);
}

function validatePassword(pass) {
  let regex = /(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[^\w\s])^.{8,}$/;
  return pass.match(regex);
}

export { validateEmail, validatePhoneNumber, validateName, validatePassword };
