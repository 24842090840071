import React from "react";
import {
  AboutUs1,
  AboutUs2,
  AboutUs3,
  AboutUs4,
  AboutUs5,
  AboutUs6,
  AboutUs7,
  AboutUs8,
  Aniketh,
  Adarsh,
  Shalva,
  AboutUsMobile,
  AboutUsGroup,
  AboutUsVector,
} from "../../resources/assetsManager";
import ConnectUs from "../dashboard/ConnectUs";
import AboutCard from "./AboutCard";
import ResizeListener from "../../../utility/ResizeListener";
import { WIDTH_LIMIT as limit } from "../../../data/constants/Constants";
import { useEffect } from "react";

const AboutUs = () => {
  const { width } = ResizeListener();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const team = [
    {
      name: "Adarsh",
      designation: "Co-Founder",
      image: Adarsh,
    },
    {
      name: "Aniketh",
      designation: "Co-Founder",
      image: Aniketh,
    },
    {
      name: "Shalva",
      designation: "Co-Founder",
      image: Shalva,
    },
  ];

  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          padding: width > limit ? "40px 70px" : 20,
          rowGap: width > limit ? "64px" : "32px",
          width: "100%",
          background: "#FAFCFF",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <p
            style={{
              fontWeight: "600",
              fontSize: width > limit ? 42 : 22,
              margin: 0,
            }}
          >
            Welcome To Wandering Lama
          </p>
          <p
            style={{
              fontWeight: "600",
              fontSize: width > limit ? 36 : 18,
              margin: 0,
            }}
          >
            Leading The Way Towards
          </p>
          <p
            style={{
              fontWeight: "600",
              fontSize: width > limit ? 36 : 18,
              margin: 0,
              marginBottom: 10,
            }}
          >
            A<span style={{ color: "#428D04" }}> New Era Of Travel</span>
          </p>

          <p
            style={{
              color: "#525252",
              width: width > limit ? "70%" : "100%",
              textAlign: "center",
              fontSize: width > limit ? 20 : 16,
              fontWeight: 400,
              marginBottom: 10,
            }}
          >
            Wandering Lama is your one-stop travel platform for hassle-free
            experiential stays. We offer exclusive properties in multiple
            locations throughout India and abroad, which we market and manage to
            provide you with the best possible experience.
          </p>
          <p
            style={{
              color: "#525252",
              width: width > limit ? "70%" : "100%",
              textAlign: "center",
              fontSize: width > limit ? 20 : 16,
              fontWeight: 400,
              marginBottom: 10,
            }}
          >
            Our offerings go beyond typical hotel stays, providing unique
            experiences like homestays and campings that you won't find anywhere
            else. We prioritize sustainability and nature-friendliness,
            providing you with a chance to disconnect from your busy schedule
            and immerse yourself in natural surroundings.
          </p>
          <p
            style={{
              color: "#525252",
              width: width > limit ? "70%" : "100%",
              textAlign: "center",
              fontSize: width > limit ? 20 : 16,
              fontWeight: 400,
            }}
          >
            Whether you're a solo traveler, a couple, or a group, we have
            something for everyone. Our stays are designed to cater to all types
            of travelers, providing a perfect getaway for those seeking
            adventure, relaxation, or cultural immersion.
          </p>
        </div>

        <div
          style={{
            width: "100%",
            display: width > limit ? "flex" : "block",
            justifyContent: "space-between",
          }}
        >
          <div style={{ width: width > limit ? "32%" : "100%" }}>
            <img
              style={{
                width: "100%",
                height: width > limit ? 600 : 300,
                objectFit: "cover",
                borderRadius: 10,
              }}
              src={width > limit ? AboutUs1 : AboutUsMobile}
            />
          </div>

          {width > limit && (
            <div style={{ width: "67%" }}>
              <img
                style={{
                  width: "100%",
                  height: 292,
                  objectFit: "cover",
                  borderRadius: 10,
                }}
                src={AboutUs2}
              />
              <img
                style={{
                  marginTop: 10,
                  height: 292,
                  width: "100%",
                  objectFit: "cover",
                  borderRadius: 10,
                }}
                src={AboutUs3}
              />
            </div>
          )}
        </div>
        {/*<div
          style={{
            backgroundColor: "#FFFFFF",
            boxShadow: "1px 1px 48px 0px rgba(0, 0, 0, 0.05)",
            borderRadius: "16px",
          }}
        >
          <div
            style={{
              display: "flex",
              padding: width > limit ? "64px 128px" : "20px",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              gap: "12px",
            }}
          >
            <div
              style={{
                color: "#428D04",
                fontSize: width > limit ? "28px" : "22px",
                fontFamily: "Clash Display",
              }}
            >
              <span
                style={{
                  fontWeight: 500,
                }}
              >{`Our `}</span>
              <span
                style={{
                  fontWeight: 700,
                }}
              >
                Journey
              </span>
            </div>
            <div
              style={{
                fontSize: width > limit ? "20px" : "16px",
                fontFamily: "Poppins",
                color: "#525252",
                textAlign: "center",
              }}
            >
              <p style={{ margin: "0" }}>
                Your one-stop travel platform for hassle-free experiential
                stays. We offer exclusive properties in multiple locations
                throughout India and abroad, which we market and manage to
                provide you with the best possible experience.
              </p>
            </div>
            {width > limit && (
              <div
                style={{
                  position: "relative",
                  width: "100%",
                  height: "234px",
                  textAlign: "center",
                  fontSize: "14px",
                  color: "#525252",
                  fontFamily: "Poppins",
                }}
              >
                <img
                  style={{
                    position: "absolute",
                    top: "72px",
                    left: "29px",
                    width: "90%",
                    height: "90%",
                  }}
                  alt=""
                  src={AboutUsVector}
                />
                <img
                  style={{
                    position: "absolute",
                    top: "70%",
                    left: "4%",
                    width: "33px",
                    height: "33px",
                  }}
                  alt=""
                  src={AboutUsGroup}
                />
                <img
                  style={{
                    position: "absolute",
                    top: "93%",
                    left: "40%",
                    width: "33px",
                    height: "33px",
                  }}
                  alt=""
                  src={AboutUsGroup}
                />
                <img
                  style={{
                    position: "absolute",
                    top: "45%",
                    left: "60%",
                    width: "33px",
                    height: "33px",
                  }}
                  alt=""
                  src={AboutUsGroup}
                />
                <img
                  style={{
                    position: "absolute",
                    top: "50%",
                    left: "90%",
                    width: "33px",
                    height: "33px",
                  }}
                  alt=""
                  src={AboutUsGroup}
                />
                <div style={{ position: "absolute", top: "50%", left: "0%" }}>
                  <p style={{ margin: "0" }}>founded</p>
                  <p style={{ margin: "0" }}>in 2020</p>
                </div>
                <div style={{ position: "absolute", top: "60%", left: "36%" }}>
                  <p style={{ margin: "0" }}>2021</p>
                  <p style={{ margin: "0" }}>{`over 100+ `}</p>
                  <p style={{ margin: "0" }}>resorts registered</p>
                </div>
                <div style={{ position: "absolute", top: "25%", left: "60%" }}>
                  <p style={{ margin: "0" }}>got 500K</p>
                  <p style={{ margin: "0" }}>funding in 2022</p>
                </div>
                <div style={{ position: "absolute", top: "17%", right: "0%" }}>
                  <p style={{ margin: "0" }}>2023</p>
                  <p style={{ margin: "0" }}>{`over 500+ `}</p>
                  <p style={{ margin: "0" }}>resorts registered</p>
                </div>
              </div>
            )}
            {width <= limit && (
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "flex-start",
                  justifyContent: "flex-start",
                  gap: "12px",
                  textAlign: "center",
                  fontSize: "14px",
                  color: "#525252",
                  fontFamily: "Poppins",
                  marginTop: "20px",
                }}
              >
                <div
                  style={{
                    height: "320px",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    gap: "4px",
                  }}
                >
                  <img
                    style={{
                      position: "relative",
                      width: "33px",
                      height: "33px",
                    }}
                    alt=""
                    src={AboutUsGroup}
                  />
                  <div
                    style={{
                      flex: "1",
                      position: "relative",
                      borderRight: "2px dashed #8e8e8e",
                      boxSizing: "border-box",
                      width: "2px",
                    }}
                  />
                  <img
                    style={{
                      position: "relative",
                      width: "33px",
                      height: "33px",
                    }}
                    alt=""
                    src={AboutUsGroup}
                  />
                  <div
                    style={{
                      flex: "1",
                      position: "relative",
                      borderRight: "2px dashed #8e8e8e",
                      boxSizing: "border-box",
                      width: "2px",
                    }}
                  />
                  <img
                    style={{
                      position: "relative",
                      width: "33px",
                      height: "33px",
                    }}
                    alt=""
                    src={AboutUsGroup}
                  />
                  <div
                    style={{
                      flex: "1",
                      position: "relative",
                      borderRight: "2px dashed #8e8e8e",
                      boxSizing: "border-box",
                      width: "2px",
                    }}
                  />
                  <img
                    style={{
                      position: "relative",
                      width: "33px",
                      height: "33px",
                    }}
                    alt=""
                    src={AboutUsGroup}
                  />
                </div>
                <div
                  style={{
                    alignSelf: "stretch",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                    justifyContent: "center",
                    gap: "74px",
                  }}
                >
                  <div style={{ position: "relative" }}>founded in 2020</div>
                  <div style={{ position: "relative" }}>
                    2021 over 100+ resorts registered
                  </div>
                  <div style={{ position: "relative" }}>
                    got 500K funding in 2022
                  </div>
                  <div style={{ position: "relative" }}>
                    2023 over 500+ resorts registered
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>*/}
        <div
          style={{
            backgroundColor: "#FFFFFF",
            boxShadow: "1px 1px 48px 0px rgba(0, 0, 0, 0.05)",
            borderRadius: "16px",
          }}
        >
          <div
            style={{
              display: "flex",
              padding: width > limit ? "64px 128px" : "20px",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              gap: "12px",
            }}
          >
            <div>
              <img
                style={{
                  width: "100%",
                  objectFit: "cover",
                }}
                alt=""
                src={AboutUs4}
              />
            </div>
            <div
              style={{
                color: "#428D04",
                fontSize: width > limit ? "28px" : "22px",
                fontFamily: "Clash Display",
              }}
            >
              <span
                style={{
                  fontWeight: 500,
                }}
              >{`Our `}</span>
              <span
                style={{
                  fontWeight: 700,
                }}
              >
                Mission
              </span>
            </div>
            <div
              style={{
                fontSize: width > limit ? "20px" : "16px",
                fontFamily: "Poppins",
                color: "#525252",
                textAlign: "center",
              }}
            >
              <p style={{ margin: "0" }}>
                Pioneering the future of travel experiences
              </p>
              <p style={{ margin: "0" }}>
                locations throughout India and abroad, which we market and
                manage to provide you with the best possible experience.
              </p>
            </div>
          </div>
        </div>
        <div
          style={{
            backgroundColor: "#FFFFFF",
            boxShadow: "1px 1px 48px 0px rgba(0, 0, 0, 0.05)",
            borderRadius: "16px",
            display: "flex",
            flexDirection: "column",
            rowGap: "16px",
            padding: width > limit ? "64px 128px" : "20px",
          }}
        >
          <div
            style={{
              color: "#428D04",
              fontSize: width > limit ? "28px" : "22px",
              fontFamily: "Clash Display",
              textAlign: "center",
              marginBottom: width > limit ? "16px" : "0",
            }}
          >
            <span
              style={{
                fontWeight: 500,
              }}
            >{`Our `}</span>
            <span
              style={{
                fontWeight: 700,
              }}
            >
              Values
            </span>
          </div>
          <div
            style={{
              display: width > limit ? "flex" : "block",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "center",
              columnGap: "16px",
              width: "100%",
            }}
          >
            <div
              style={{
                width: width > limit ? "50%" : "",
                padding: "48px 24px",
                backgroundColor: "rgba(223, 58, 58, 0.10)",
                borderRadius: "16px",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                marginBottom: width > limit ? "0" : "16px",
              }}
            >
              <div>
                <img alt="" src={AboutUs5} />
              </div>
              <p style={{ fontSize: "20px", fontWeight: "400", margin: "0" }}>
                Authenticity
              </p>
            </div>
            <div
              style={{
                width: width > limit ? "50%" : "",
                padding: "48px 24px",
                background: "rgba(101, 99, 255, 0.1)",
                borderRadius: "16px",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <div>
                <img alt="" src={AboutUs6} />
              </div>
              <p style={{ fontSize: "20px", fontWeight: "400", margin: "0" }}>
                Innovation
              </p>
            </div>
          </div>
          <div
            style={{
              display: width > limit ? "flex" : "block",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "center",
              columnGap: "16px",
              width: "100%",
            }}
          >
            <div
              style={{
                width: width > limit ? "50%" : "",
                padding: "48px 24px",
                background: "rgba(66, 141, 4, 0.1)",
                borderRadius: "16px",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                marginBottom: width > limit ? "0" : "16px",
              }}
            >
              <div>
                <img alt="" src={AboutUs7} />
              </div>
              <p style={{ fontSize: "20px", fontWeight: "400", margin: "0" }}>
                Transparency
              </p>
            </div>
            <div
              style={{
                width: width > limit ? "50%" : "",
                padding: "48px 24px",
                background: "rgba(255, 186, 8, 0.1)",
                borderRadius: "16px",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <div>
                <img alt="" src={AboutUs8} />
              </div>
              <p style={{ fontSize: "20px", fontWeight: "400", margin: "0" }}>
                Customer Experience
              </p>
            </div>
          </div>
        </div>
        <div
          style={{
            backgroundColor: "#FFFFFF",
            boxShadow: "1px 1px 48px 0px rgba(0, 0, 0, 0.05)",
            borderRadius: "16px",
            display: "flex",
            flexDirection: "column",
            rowGap: "16px",
            padding: width > limit ? "64px 128px" : "20px",
          }}
        >
          <div
            style={{
              color: "#428D04",
              fontSize: width > limit ? "28px" : "22px",
              fontFamily: "Clash Display",
              textAlign: "center",
              marginBottom: width > limit ? "16px" : "0",
            }}
          >
            <span
              style={{
                fontWeight: 500,
              }}
            >{`Our `}</span>
            <span
              style={{
                fontWeight: 700,
              }}
            >
              Team
            </span>
          </div>
          <div
            style={{
              display: width > limit ? "flex" : "block",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              rowGap: "20px",
              width: "100%",
            }}
          >
            <div
              style={{
                display: width > limit ? "flex" : "block",
                flexDirection: "row",
                alignItems: "center",
                columnGap: "20px",
                width: "100%",
                marginBottom: width > limit ? "0" : "16px",
                borderRadius: "16px",
                border: "1px solid #e2e2e2",
                padding: width > limit ? 15 : 10,
              }}
            >
              <div style={{ width: width > limit ? "30%" : "100%" }}>
                <AboutCard item={team[0]} idx={0} />
              </div>
              <div
                style={{
                  fontSize: width > limit ? 20 : 16,
                  width: width > limit ? "65%" : "100%",
                  fontFamily: "Poppins",
                  color: "#525252",
                  marginTop: width > limit ? "0" : "10px",
                }}
              >
                <p style={{ marginBottom: 10 }}>
                  A seasoned IT professional with a remarkable track record of
                  over six years in the industry. Adarsh's journey through
                  esteemed organizations has equipped him with a diverse skill
                  set and a profound understanding of IT leadership.
                </p>
                <p style={{ marginBottom: 10 }}>
                  Adarsh's leadership prowess extends across critical domains,
                  including software testing, customer relationship management,
                  and onboarding. His hands-on experience and strategic mindset
                  enable him to navigate complex IT landscapes with finesse,
                  driving teams toward success.
                </p>
                <p>
                  Beyond his professional achievements, Adarsh is also a
                  dedicated karate player, embodying the principles of
                  discipline, determination, and focus—qualities that resonate
                  in his leadership style.
                </p>
              </div>
            </div>
            <div
              style={{
                display: width > limit ? "flex" : "block",
                flexDirection: "row",
                alignItems: "flex-start",
                columnGap: "20px",
                width: "100%",
                marginBottom: width > limit ? "0" : "16px",
                borderRadius: "16px",
                border: "1px solid #e2e2e2",
                padding: width > limit ? 15 : 10,
              }}
            >
              <div style={{ width: width > limit ? "30%" : "100%" }}>
                <AboutCard item={team[1]} idx={1} />
              </div>
              <div
                style={{
                  fontSize: width > limit ? 20 : 16,
                  width: width > limit ? "65%" : "100%",
                  fontFamily: "Poppins",
                  color: "#525252",
                  marginTop: width > limit ? "0" : "10px",
                }}
              >
                <p style={{ marginBottom: 10 }}>
                  Aniketh holds a degree in engineering with a specialization in
                  IT, laying a strong foundation for his multifaceted career.
                  His journey is marked by remarkable achievements and a
                  relentless passion for sports.
                </p>
                <p style={{ marginBottom: 10 }}>
                  Aniketh's sporting prowess is evident as he has represented
                  his state thrice at both the cricket and basketball State
                  level competitions. This commitment to sports has not only
                  shaped his character but has also been a source of inspiration
                  throughout his professional journey.
                </p>
                <p style={{ marginBottom: 10 }}>
                  With over six years of experience in the adventure and travel
                  field, Aniketh has cultivated a deep understanding of this
                  dynamic industry. His previous role at India’s leading sports
                  education institute saw him successfully manage comprehensive
                  school programs across India, catering to both B2B and B2C
                  segments. Aniketh's expertise extends to senior citizen
                  programs, highlighting his versatility and ability to connect
                  with diverse audiences.
                </p>
                <p>
                  Aniketh's dedication to cricket is further exemplified by his
                  achievement in clearing the Level 3 Umpiring exam conducted by
                  the Karnataka State Cricket Association in 2017. Since then,
                  he has been officiating matches, showcasing his commitment to
                  the sport beyond the field of play.
                </p>
              </div>
            </div>
            <div
              style={{
                display: width > limit ? "flex" : "block",
                flexDirection: "row",
                alignItems: "flex-start",
                columnGap: "20px",
                width: "100%",
                marginBottom: width > limit ? "0" : "16px",
                borderRadius: "16px",
                border: "1px solid #e2e2e2",
                padding: width > limit ? 15 : 10,
              }}
            >
              <div style={{ width: width > limit ? "30%" : "100%" }}>
                <AboutCard item={team[2]} idx={2} />
              </div>
              <div
                style={{
                  fontSize: width > limit ? 20 : 16,
                  width: width > limit ? "65%" : "100%",
                  fontFamily: "Poppins",
                  color: "#525252",
                  marginTop: width > limit ? "0" : "10px",
                }}
              >
                <p style={{ marginBottom: 10 }}>
                  Shalva brings a unique blend of experiences and talents to the
                  table. With a notable history in sales at one of the foremost
                  custom leather companies, Stanley, Shalva possesses a deep
                  understanding of the art of client engagement and
                  satisfaction. This background in sales has moulded Shalva into
                  a dynamic and customer-focused professional.
                </p>
                <p style={{ marginBottom: 10 }}>
                  In addition to his sales expertise, Shalva is a visionary
                  entrepreneur, serving as the co-founder of Build-matic, a
                  trailblazing construction company. His leadership and
                  innovative spirit have been instrumental in driving
                  Build-matic's success, making him a true pioneer in the
                  industry.
                </p>
                <p>
                  But Shalva's talents extend far beyond the business world. He
                  is also a passionate music artist, where his creativity and
                  artistry shine. While a brief overview of his musical journey
                  can be shared, the depth of his musical achievements is a
                  story that unfolds through his compositions and performances.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
          }}
        >
          <ConnectUs />
        </div>
      </div>
    </>
  );
};

export default AboutUs;
