import {
  CHECK_IN_CHECK_OUT,
  DESTINATION,
  PROPERTY_TYPE,
  ROOM_GUEST,
} from "../constants/reduxConstants";

export const updateDestination = (payload) => {
  return {
    type: DESTINATION,
    payload: payload,
  };
};
export const updateCheckInCheckOut = (payload) => {
  return {
    type: CHECK_IN_CHECK_OUT,
    payload: payload,
  };
};
export const updateRoomGuest = (payload) => {
  return {
    type: ROOM_GUEST,
    payload: payload,
  };
};
export const updatePropertyType = (payload) => {
  return {
    type: PROPERTY_TYPE,
    payload: payload,
  };
};
