import { CUSTOMER_DETAILS, ISAUTHENTICATED } from "../constants/reduxConstants";

export const authAction = (payload) => {
  return {
    type: ISAUTHENTICATED,
    payload: payload,
  };
};

export const updateCustomerDetails = (payload) => {
  return {
    type: CUSTOMER_DETAILS,
    payload: payload,
  };
};
