import secureStorage from "../../../utility/secureStorage";
import { ApiPostServiceWrapper, ApiPutServiceWrapper } from "../wrapperService";
import {
  AUTH_URL,
  BOOKING_URL,
  ENQUIRY_URL,
  LEAD_URL,
  PAYMENT_URL,
  PROPERTY_URL,
} from "./ApiEndPoint";

export const ApiRequestPost = {
  login: (email, password) => {
    return new Promise((resolve, reject) => {
      ApiPostServiceWrapper({
        url: AUTH_URL + "/login",
        headers: {},
        body: {
          email,
          password,
          role: "user",
        },
      })
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  register: (userData) => {
    return new Promise((resolve, reject) => {
      ApiPostServiceWrapper({
        url: AUTH_URL + "/register",
        headers: {},
        body: {
          ...userData,
          role: "user",
        },
      })
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  verifyOtp: (email, otp) => {
    return new Promise((resolve, reject) => {
      ApiPostServiceWrapper({
        url: AUTH_URL + "/verifyOtp",
        headers: {},
        body: {
          email,
          otp,
        },
      })
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  forgotPassword: (email) => {
    return new Promise((resolve, reject) => {
      ApiPostServiceWrapper({
        url: AUTH_URL + "/forgotPassword",
        headers: {},
        body: {
          email,
        },
      })
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  resetpassword: (accessToken, email, password) => {
    return new Promise((resolve, reject) => {
      ApiPutServiceWrapper({
        url: AUTH_URL + "/resetpassword",
        headers: {
          Authorization: "Bearer " + accessToken,
        },
        body: {
          email,
          password,
        },
      })
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  addProperty: (body) => {
    return new Promise((resolve, reject) => {
      ApiPostServiceWrapper({
        url: PROPERTY_URL + "/add",
        headers: {},
        body,
      })
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  addEnquiry: (data) => {
    return new Promise((resolve, reject) => {
      ApiPostServiceWrapper({
        url: ENQUIRY_URL + `/add`,
        headers: {},
        body: {
          ...data,
        },
      })
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  addLead: (data) => {
    return new Promise((resolve, reject) => {
      ApiPostServiceWrapper({
        url: LEAD_URL + `/add`,
        headers: {},
        body: {
          ...data,
        },
      })
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  addBooking: async (addBookingObj) => {
    const accessToken = await secureStorage.getItem("SS_TOKEN");

    return new Promise((resolve, reject) => {
      ApiPostServiceWrapper({
        url: BOOKING_URL + "/",
        headers: {
          Authorization: "Bearer " + accessToken,
        },
        body: addBookingObj,
      })
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  rpCreateOrder: async (bookingObj) => {
    const accessToken = await secureStorage.getItem("SS_TOKEN");

    return new Promise((resolve, reject) => {
      ApiPostServiceWrapper({
        url: PAYMENT_URL + `/create-order`,
        headers: {
          Authorization: "Bearer " + accessToken,
        },
        body: {
          bookingID: bookingObj.bookingID,
          amount: bookingObj.totalAmount.toString(),
        },
      })
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  rpVerifyPayment: async (data) => {
    const accessToken = await secureStorage.getItem("SS_TOKEN");

    return new Promise((resolve, reject) => {
      ApiPostServiceWrapper({
        url: PAYMENT_URL + `/verify-payment`,
        headers: {
          Authorization: "Bearer " + accessToken,
        },
        body: {
          ...data,
        },
      })
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  updatePaymentStatus: async (orderId, status = "cancelled") => {
    const accessToken = await secureStorage.getItem("SS_TOKEN");

    return new Promise((resolve, reject) => {
      ApiPutServiceWrapper({
        url: PAYMENT_URL + `/status/` + orderId,
        headers: {
          Authorization: "Bearer " + accessToken,
        },
        body: {
          status,
        },
      })
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
};
