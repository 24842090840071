import React, { useEffect, useRef } from "react";
import { useState } from "react";
import { connect } from "react-redux";
import { ApiRequestGet } from "../../../data/network/services/ApiRequestGet";
import ResizeListener from "../../../utility/ResizeListener";
import {
  WIDTH_LIMIT,
  WIDTH_LIMIT as limit,
} from "../../../data/constants/Constants";
import { Button, Typography } from "@mui/material";
import { STAYS } from "../../resources/constantsManager";
import secureStorage from "../../../utility/secureStorage";
import { SS_ACTIVE_TAB } from "../../resources/secureStorageManager";
import { useLocation } from "react-router";
import { CustomButton } from "../../components/customButton/CustomButton";
import { DownloadIcon, TriangleRight } from "../../resources/assetsManager";
import { printDocument } from "./InvoicePdfReader";
import moment from "moment";

const Profile = ({ customerDetails, isAuthenticated }) => {
  const [enquires, setEnquires] = useState([]);
  const [bookings, setBookings] = useState([]);

  const [downloadPdf, setDownloadPdf] = useState(false);
  const [customerData, setCustomerData] = useState({});
  const [activeType, setActiveType] = useState("");
  const [selected, setSelected] = useState("profile");
  const { width } = ResizeListener();

  let location = useLocation();

  useEffect(() => {
    getActiveType();

    let pathname = window?.location?.pathname;
    pathname = pathname?.split("/");

    if (pathname[pathname.length - 1] === "details") {
      setSelected("profile");
    } else if (pathname[pathname.length - 1] === "bookings") {
      setSelected("bookings");
    } else if (pathname[pathname.length - 1] === "enquire") {
      setSelected("enquire");
    }
  }, []);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });

    if (location?.state) {
      setSelected("bookings");
    }
  }, [location]);

  const getActiveType = async () => {
    const type = await secureStorage.setItem(SS_ACTIVE_TAB, STAYS);
    setActiveType(type);
  };

  const getAllEnquiriesByUser = () => {
    ApiRequestGet.getAllEnquiriesByUser()
      .then((res) => {
        if (res.data.success) {
          setEnquires(res?.data?.data);
        }
      })
      .catch((err) => {
        console.log("error", err);
      });
  };

  const getAllBookingsByUser = () => {
    ApiRequestGet.getAllBookingsByUser()
      .then((res) => {
        if (res.data.success) {
          setBookings(res?.data?.data);
        }
      })
      .catch((err) => {
        console.log("error", err);
      });
  };

  useEffect(() => {
    if (customerDetails?.userId) {
      getAllEnquiriesByUser();
      getAllBookingsByUser();
    }
  }, [customerDetails]);

  const BookingItem = ({ item, index }) => {
    const [expand, setExpand] = useState(false);
    const [contentHeight, setContentHeight] = useState(0);
    const contentRef = useRef(null);

    useEffect(() => {
      if (contentRef.current) {
        setContentHeight(contentRef.current.scrollHeight);
      }
    }, [expand]);

    const handleExpand = () => {
      if (expand) {
        setExpand(false);

        // Scroll smoothly to the compressed content after a slight delay
        setTimeout(() => {
          if (contentRef.current) {
            contentRef.current.scrollIntoView({
              behavior: "smooth",
              block: "start",
            });
          }
        }, 10);
      } else {
        setExpand(true);
      }
    };

    return (
      <div
        ref={contentRef}
        onClick={() => {
          if (!expand) handleExpand();
        }}
        style={{
          borderRadius: "8px",
          backgroundColor: "#fafcff",
          border: "1px solid #e2e2e2",
          overflow: "hidden",
          display: "flex",
          flexDirection: "column",
          padding: expand ? "32px" : "20px",
          alignItems: "flex-start",
          justifyContent: "flex-start",
          gap: "16px",
          margin: width > limit ? "16px 64px" : "16px 32px",
          cursor: !expand ? "pointer" : "",
          height: expand ? contentHeight + "px" : 60,
          transition: "height 0.3s ease", // Adjust the duration and easing as needed
        }}
        key={index}
      >
        {!expand ? (
          <div
            style={{
              alignSelf: "stretch",
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "flex-start",
              gap: "16px",
            }}
          >
            <div
              style={{
                fontWeight: "500",
                width: width > WIDTH_LIMIT ? "20%" : "90%",
              }}
            >
              Booking ID:{" "}
              <b
                style={{
                  color: "#101010",
                  width: "60%",
                  marginLeft: 20,
                }}
              >
                {item?.prefix + item?.bookingNo}
              </b>
            </div>
            {width > WIDTH_LIMIT && (
              <>
                <div
                  style={{
                    fontWeight: "500",
                    width: "45%",
                  }}
                >
                  Property name:
                  <b
                    style={{
                      color: "#101010",
                      width: "60%",
                      marginLeft: 20,
                    }}
                  >
                    {item?.productName?.substring(0, 45)}
                    {item?.productName?.length > 45 ? "..." : ""}
                  </b>
                </div>

                <div
                  style={{
                    fontWeight: "500",
                    width: "20%",
                  }}
                >
                  Payment status:{" "}
                  <b
                    style={{
                      marginLeft: 20,
                      color:
                        item?.payment?.paymentStatus === "failed" ||
                        item?.payment?.paymentStatus === "cancelled"
                          ? "#C52E2E"
                          : item?.payment?.paymentStatus === "created"
                          ? "#E89B08"
                          : item?.payment?.paymentStatus === "success"
                          ? "#82C34C"
                          : "#444",
                    }}
                  >
                    {item?.payment?.paymentStatus}
                  </b>
                </div>
              </>
            )}
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                width: "10%",
              }}
            >
              <img style={{ width: 20 }} src={TriangleRight} />
            </div>
          </div>
        ) : (
          <>
            {item?.payment?.paymentStatus === "success" && (
              <div
                style={{
                  display: "flex",
                  width: "100%",
                  justifyContent: "flex-end",
                }}
              >
                <CustomButton
                  backgroundColor="#101010"
                  onClick={() => {
                    printDocument(false, true, bookings[index]);
                  }}
                  ionDirection="left"
                  icon={DownloadIcon}
                  iconStyles={{
                    width: 22,
                    marginRight: 10,
                  }}
                  styles={{
                    border: 0,
                    borderRadius: 5,
                    marginTop: width > WIDTH_LIMIT ? 0 : 15,
                    width: width > WIDTH_LIMIT ? 150 : "100%",
                  }}
                  textStyles={{
                    fontWeight: "500",
                    fontSize: width > WIDTH_LIMIT ? 16 : 14,
                  }}
                  title="Invoice"
                />
              </div>
            )}
            <div
              style={{
                alignSelf: "stretch",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "flex-start",
                gap: "16px",
              }}
            >
              <div
                style={{
                  fontWeight: "500",
                  width: "35%",
                }}
              >
                Booking ID:
              </div>
              <b
                style={{
                  color: "#101010",
                  width: "60%",
                }}
              >
                {item?.prefix + item?.bookingNo}
              </b>
            </div>
            <div
              style={{
                alignSelf: "stretch",
                display: "flex",
                flexDirection: "row",
                alignItems: "flex-start",
                justifyContent: "flex-start",
                gap: "16px",
              }}
            >
              <div
                style={{
                  fontWeight: "500",
                  width: "35%",
                }}
              >
                Property name:
              </div>
              <b
                onClick={() => {
                  const url =
                    window.location.origin +
                    `/property-details/${item?.productType.toLowerCase()}/${
                      item?.dayOuting?.slug ||
                      item?.stay?.slug ||
                      item?.camping?.slug
                    }`;
                  setTimeout(() => {
                    window.open(url, "_blank", "noreferrer");
                  }, 100);
                }}
                style={{
                  color: "#101010",
                  width: "60%",
                  textDecoration: "underline",
                  cursor: "pointer",
                }}
              >
                {item?.productName}
              </b>
            </div>
            <div
              style={{
                alignSelf: "stretch",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "flex-start",
                gap: "16px",
              }}
            >
              <div
                style={{
                  fontWeight: "500",
                  width: "35%",
                }}
              >
                Payment status:
              </div>
              <b
                style={{
                  color:
                    item?.payment?.paymentStatus === "failed" ||
                    item?.payment?.paymentStatus === "cancelled"
                      ? "#C52E2E"
                      : item?.payment?.paymentStatus === "created"
                      ? "#E89B08"
                      : item?.payment?.paymentStatus === "success"
                      ? "#82C34C"
                      : "#444",
                }}
              >
                {item?.payment?.paymentStatus}
              </b>
            </div>
            <div
              style={{
                alignSelf: "stretch",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "flex-start",
                gap: "16px",
              }}
            >
              <div
                style={{
                  fontWeight: "500",
                  width: "35%",
                }}
              >
                Booking for:
              </div>
              <b
                style={{
                  color: "#101010",
                  width: "60%",
                }}
              >
                {item?.productType}
              </b>
            </div>
            <div
              style={{
                alignSelf: "stretch",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "flex-start",
                gap: "16px",
              }}
            >
              <div
                style={{
                  fontWeight: "500",
                  width: "35%",
                }}
              >
                {item?.productType === "dayOuting"
                  ? "Booked On"
                  : "Booking from"}
                :
              </div>
              <b
                style={{
                  color: "#101010",
                  width: "60%",
                }}
              >
                {item?.checkIn}
              </b>
            </div>
            {item?.checkOut != "" && item?.productType === "camping" && (
              <div
                style={{
                  alignSelf: "stretch",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "flex-start",
                  gap: "16px",
                }}
              >
                <div
                  style={{
                    fontWeight: "500",
                    width: "35%",
                  }}
                >
                  Booking until:
                </div>
                <b
                  style={{
                    color: "#101010",
                    width: "60%",
                  }}
                >
                  {item?.checkOut}
                </b>
              </div>
            )}
            {item?.totalAmount && (
              <div
                style={{
                  alignSelf: "stretch",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "flex-start",
                  gap: "16px",
                }}
              >
                <div
                  style={{
                    fontWeight: "500",
                    width: "35%",
                  }}
                >
                  Total Amount Paid:
                </div>
                <b
                  style={{
                    color: "#101010",
                    width: "60%",
                    margin: 0,
                    fontSize: 20,
                  }}
                >
                  ₹{item?.totalAmount.toFixed(2)}
                </b>
              </div>
            )}

            {item?.payment?.paymentStatus === "success" && (
              <div
                style={{
                  alignSelf: "stretch",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "flex-start",
                  gap: "16px",
                }}
              >
                <div
                  style={{
                    fontWeight: "500",
                    width: "35%",
                  }}
                >
                  SPOC Person:
                </div>
                {item?.dayOuting?.phone ||
                item?.stay?.phone ||
                item?.camping?.phone ? (
                  <b
                    style={{
                      color: "#101010",
                      width: "60%",
                      margin: 0,
                      fontSize: 20,
                    }}
                  >
                    {item?.dayOuting?.code ||
                      item?.stay?.code ||
                      item?.camping?.code}{" "}
                    -{" "}
                    {item?.dayOuting?.phone ||
                      item?.stay?.phone ||
                      item?.camping?.phone}
                  </b>
                ) : (
                  <b
                    style={{
                      color: "#101010",
                      width: "60%",
                      margin: 0,
                      fontSize: 20,
                    }}
                  >
                    Not Available
                  </b>
                )}
              </div>
            )}

            {item?.productType && (
              <div
                style={{
                  alignSelf: "stretch",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "flex-start",
                  gap: "16px",
                }}
              >
                <div
                  style={{
                    fontWeight: "500",
                    width: "35%",
                  }}
                >
                  {activeType === STAYS ? "Room Details:" : "Package Details:"}
                </div>
              </div>
            )}

            {item?.packageDetails?.length > 0 &&
              item?.packageDetails?.map((pkg) => (
                <>
                  {parseInt(pkg?.noOfAdults) > 0 && (
                    <div
                      style={{
                        background: "#fff",
                        borderRadius: 5,
                        width: "100%",
                        border: "1px solid #e5f2da",
                      }}
                    >
                      <div
                        style={{
                          background:
                            item?.payment?.paymentStatus === "failed" ||
                            item?.payment?.paymentStatus === "cancelled"
                              ? "#fad7d7"
                              : item?.payment?.paymentStatus === "created"
                              ? "#fcefd7"
                              : item?.payment?.paymentStatus === "success"
                              ? "#e5fad4"
                              : "#eee",
                          borderTopLeftRadius: 5,
                          borderTopRightRadius: 5,
                          padding: "15px 30px",
                        }}
                      >
                        <div
                          style={{
                            alignSelf: "stretch",
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            justifyContent: "flex-start",
                            gap: "16px",
                          }}
                        >
                          <div
                            style={{
                              fontWeight: "500",
                              width: "35%",
                              margin: 0,
                            }}
                          >
                            Package Name:
                          </div>
                          <b
                            style={{
                              color: "#101010",
                              width: "60%",
                              margin: 0,
                            }}
                          >
                            {pkg?.detailedPackageInfo?.name}
                          </b>
                        </div>
                      </div>
                      <div
                        style={{
                          borderTopLeftRadius: 5,
                          borderTopRightRadius: 5,
                          padding: "15px 30px",
                        }}
                      >
                        <div
                          style={{
                            alignSelf: "stretch",
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            justifyContent: "flex-start",
                            gap: "16px",
                          }}
                        >
                          <div
                            style={{
                              fontWeight: "500",
                              width: "35%",
                              margin: 0,
                            }}
                          >
                            Timings:
                          </div>
                          <b
                            style={{
                              color: "#101010",
                              width: "60%",
                              margin: 0,
                            }}
                          >
                            {moment(pkg?.detailedPackageInfo?.checkIn).format(
                              "HH:mm A"
                            )}
                            <span style={{ fontWeight: "400" }}> to </span>
                            {moment(pkg?.detailedPackageInfo?.checkOut).format(
                              "HH:mm A"
                            )}
                          </b>
                        </div>
                        <div
                          style={{
                            alignSelf: "stretch",
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            justifyContent: "flex-start",
                            gap: "16px",
                          }}
                        >
                          <div
                            style={{
                              fontWeight: "500",
                              width: "35%",
                              margin: 0,
                            }}
                          >
                            No. of Adults:
                          </div>
                          <b
                            style={{
                              color: "#101010",
                              width: "60%",
                              margin: 0,
                            }}
                          >
                            {pkg?.noOfAdults}
                          </b>
                        </div>
                        <div
                          style={{
                            alignSelf: "stretch",
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            justifyContent: "flex-start",
                            gap: "16px",
                          }}
                        >
                          <div
                            style={{
                              fontWeight: "500",
                              width: "35%",
                              margin: 0,
                            }}
                          >
                            No. of Children:
                          </div>
                          <b
                            style={{
                              color: "#101010",
                              width: "60%",
                              margin: 0,
                            }}
                          >
                            {pkg?.noOfChildren}
                          </b>
                        </div>
                        <div
                          style={{
                            alignSelf: "stretch",
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            justifyContent: "flex-start",
                            gap: "16px",
                          }}
                        >
                          <div
                            style={{
                              fontWeight: "500",
                              width: "35%",
                              margin: 0,
                            }}
                          >
                            Package Total Amount:
                          </div>
                          <b
                            style={{
                              color: "#101010",
                              width: "60%",
                              margin: 0,
                              fontSize: 20,
                            }}
                          >
                            ₹{(pkg?.packageTotalAmount).toFixed(2)}
                          </b>
                        </div>
                      </div>
                    </div>
                  )}
                </>
              ))}
            <p
              onClick={() => {
                handleExpand();
              }}
              style={{
                width: "100%",
                margin: "20px 0px 0px 0px",
                color: "#60442e",
                textDecoration: "underline",
                textAlign: "center",
                fontWeight: "500",
                cursor: "pointer",
              }}
            >
              Show Less
            </p>
          </>
        )}
      </div>
    );
  };

  return (
    <div
      style={{
        backgroundColor: "#fff",
        width: "100%",
        overflow: "hidden",
        fontFamily: "Satoshi",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          padding: width > limit ? "0px 64px" : "0px 32px",
          alignItems: "flex-start",
          justifyContent: "flex-start",
          gap: "16px",
          fontFamily: "Satoshi",
          marginTop: "28px",
        }}
      >
        <Button
          variant="outlined"
          style={{
            borderRadius: "4px",
            backgroundColor: selected === "profile" ? "#101010" : "#fff",
            border: "1px solid #101010",
            padding: "16px",
            color: selected === "profile" ? "#fff" : "#101010",
            textTransform: "none",
            fontSize: "16px",
            fontWeight: selected === "profile" ? "bold" : "normal",
            lineHeight: "normal",
          }}
          onClick={() => setSelected("profile")}
        >
          My Profile
        </Button>
        <Button
          variant="outlined"
          style={{
            borderRadius: "4px",
            backgroundColor: selected === "enquire" ? "#101010" : "#fff",
            border: "1px solid #101010",
            padding: "16px",
            color: selected === "enquire" ? "#fff" : "#101010",
            textTransform: "none",
            fontSize: "16px",
            fontWeight: selected === "enquire" ? "bold" : "normal",
            lineHeight: "normal",
          }}
          onClick={() => setSelected("enquire")}
        >
          My Enquiries
        </Button>
        <Button
          variant="outlined"
          style={{
            borderRadius: "4px",
            backgroundColor: selected === "bookings" ? "#101010" : "#fff",
            border: "1px solid #101010",
            padding: "16px",
            color: selected === "bookings" ? "#fff" : "#101010",
            textTransform: "none",
            fontSize: "16px",
            fontWeight: selected === "bookings" ? "bold" : "normal",
            lineHeight: "normal",
          }}
          onClick={() => setSelected("bookings")}
        >
          My Bookings
        </Button>
      </div>
      {selected == "profile" && (
        <div
          style={{
            padding: width > limit ? "0px 64px" : "0px 32px",
            marginTop: "24px",
            marginBottom: "100px",
          }}
        >
          <div
            style={{
              alignSelf: "stretch",
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              justifyContent: "flex-start",
              gap: "16px",
              fontSize: "18px",
              color: "#000",
            }}
          >
            <b style={{ position: "relative" }}>User Details</b>
            <div
              style={{
                alignSelf: "stretch",
                borderRadius: "8px",
                backgroundColor: "#fafcff",
                border: "1px solid #e2e2e2",
                overflow: "hidden",
                display: "flex",
                flexDirection: "column",
                padding: width > limit ? "48px" : "24px",
                alignItems: "flex-start",
                justifyContent: "flex-start",
                gap: "48px",
                color: "#525252",
              }}
            >
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <div
                  style={{
                    fontWeight: "500",
                    width: "35%",
                  }}
                >
                  Name
                </div>
                <b
                  style={{
                    color: "#101010",
                    width: "60%",
                    wordWrap: "break-word",
                  }}
                >
                  {customerDetails?.name}
                </b>
              </div>
              <div
                style={{
                  borderTop: "1px solid #e2e2e2",
                  boxSizing: "border-box",
                  height: "1px",
                }}
              />
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <div
                  style={{
                    width: "50%",
                    fontWeight: "500",
                    width: "35%",
                  }}
                >
                  Mobile
                </div>
                <b
                  style={{
                    width: "50%",
                    color: "#101010",
                    width: "60%",
                    wordWrap: "break-word",
                  }}
                >
                  {customerDetails?.phone}
                </b>
              </div>
              <div
                style={{
                  borderTop: "1px solid #e2e2e2",
                  boxSizing: "border-box",
                  height: "1px",
                }}
              />
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <div
                  style={{
                    width: "50%",
                    fontWeight: "500",
                    width: "35%",
                  }}
                >
                  Email Id
                </div>
                <b
                  style={{
                    width: "50%",
                    color: "#101010",
                    width: "60%",
                    wordWrap: "break-word",
                  }}
                >
                  {customerDetails?.email}
                </b>
              </div>
            </div>
          </div>
        </div>
      )}
      {selected == "enquire" && (
        <>
          <div
            style={{
              padding: width > limit ? "0px 64px" : "0px 32px",
              marginTop: "24px",
            }}
          >
            <Typography
              sx={{
                fontSize: "18px",
                color: "#000",
                fontWeight: "bold",
                //fontFamily: "Satoshi",
                fontStyle: "normal",
                lineHeight: "normal",
              }}
            >
              Enquiry History
            </Typography>
          </div>
          {enquires.length == 0 && (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                fontSize: "18px",
                fontStyle: "normal",
                lineHeight: "normal",
                marginTop: "40px",
                height: "500px",
              }}
            >
              No past enquiries
            </div>
          )}
          {enquires?.map((item, index) => {
            return (
              <div
                style={{
                  borderRadius: "8px",
                  backgroundColor: "#fafcff",
                  border: "1px solid #e2e2e2",
                  overflow: "hidden",
                  display: "flex",
                  flexDirection: "column",
                  padding: "32px",
                  alignItems: "flex-start",
                  justifyContent: "flex-start",
                  gap: "16px",
                  margin: width > limit ? "16px 64px" : "16px 32px",
                }}
                key={index}
              >
                <div
                  style={{
                    alignSelf: "stretch",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "flex-start",
                    gap: "16px",
                  }}
                >
                  <div
                    style={{
                      fontWeight: "500",
                      width: "35%",
                    }}
                  >
                    Enquiry ID:
                  </div>
                  <b
                    style={{
                      color: "#101010",
                      width: "60%",
                    }}
                  >
                    {item?.prefix + item?.enqNo}
                  </b>
                </div>
                <div
                  style={{
                    alignSelf: "stretch",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "flex-start",
                    gap: "16px",
                  }}
                >
                  <div
                    style={{
                      fontWeight: "500",
                      width: "35%",
                    }}
                  >
                    Property name:
                  </div>
                  <b
                    style={{
                      color: "#101010",
                      width: "60%",
                      // textDecoration: "underline",
                    }}
                  >
                    {item?.productName || item?.propertyName}
                  </b>
                </div>
                <div
                  style={{
                    alignSelf: "stretch",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "flex-start",
                    gap: "16px",
                  }}
                >
                  <div
                    style={{
                      fontWeight: "500",
                      width: "35%",
                    }}
                  >
                    Current status:
                  </div>
                  <b
                    style={{
                      color:
                        item?.status === "REQUESTED"
                          ? "#DB9000"
                          : item?.status === "IN_PROGRESS"
                          ? "#E89B08"
                          : item?.status === "CLOSED"
                          ? "#82C34C"
                          : "#444",
                    }}
                  >
                    {item?.status}
                  </b>
                </div>
                <div
                  style={{
                    alignSelf: "stretch",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "flex-start",
                    gap: "16px",
                  }}
                >
                  <div
                    style={{
                      fontWeight: "500",
                      width: "35%",
                    }}
                  >
                    Booking for:
                  </div>
                  <b
                    style={{
                      color: "#101010",
                      width: "60%",
                    }}
                  >
                    {item?.productType || item?.packageType}
                  </b>
                </div>
                <div
                  style={{
                    alignSelf: "stretch",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "flex-start",
                    gap: "16px",
                  }}
                >
                  <div
                    style={{
                      fontWeight: "500",
                      width: "35%",
                    }}
                  >
                    No of people:
                  </div>
                  <b
                    style={{
                      color: "#101010",
                      width: "60%",
                    }}
                  >
                    {item?.noOfPeople}
                  </b>
                </div>
                <div
                  style={{
                    alignSelf: "stretch",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "flex-start",
                    gap: "16px",
                  }}
                >
                  <div
                    style={{
                      fontWeight: "500",
                      width: "35%",
                    }}
                  >
                    Booking requested{" "}
                    {item?.productType === "Dayouting" ? "for" : "from"}:
                  </div>
                  <b
                    style={{
                      color: "#101010",
                      width: "60%",
                    }}
                  >
                    {item?.checkIn}
                  </b>
                </div>
                {item?.checkOut != "" && (
                  <div
                    style={{
                      alignSelf: "stretch",
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "flex-start",
                      gap: "16px",
                    }}
                  >
                    <div
                      style={{
                        fontWeight: "500",
                        width: "35%",
                      }}
                    >
                      Booking requested until:
                    </div>
                    <b
                      style={{
                        color: "#101010",
                        width: "60%",
                      }}
                    >
                      {item?.checkOut}
                    </b>
                  </div>
                )}
                {item?.productType && (
                  <div
                    style={{
                      alignSelf: "stretch",
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "flex-start",
                      gap: "16px",
                    }}
                  >
                    <div
                      style={{
                        fontWeight: "500",
                        width: "35%",
                      }}
                    >
                      {activeType === STAYS ? "Room type:" : "Package type:"}
                    </div>
                    <b
                      style={{
                        color: "#101010",
                        width: "60%",
                      }}
                    >
                      {item?.roomType || item?.packageType}
                    </b>
                  </div>
                )}
              </div>
            );
          })}
        </>
      )}

      {selected == "bookings" && (
        <>
          <div
            style={{
              padding: width > limit ? "0px 64px" : "0px 32px",
              marginTop: "24px",
            }}
          >
            <Typography
              sx={{
                fontSize: "18px",
                color: "#000",
                fontWeight: "bold",
                //fontFamily: "Satoshi",
                fontStyle: "normal",
                lineHeight: "normal",
              }}
            >
              Booking History
            </Typography>
          </div>
          {bookings.length == 0 && (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                fontSize: "18px",
                fontStyle: "normal",
                lineHeight: "normal",
                marginTop: "40px",
                height: "500px",
              }}
            >
              No past bookings
            </div>
          )}
          {console.log("ajhsgdkjahsd", bookings)}
          {bookings?.map((item, index) => (
            <BookingItem item={item} index={index} />
          ))}
        </>
      )}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    customerDetails: state.AuthReducer.customerDetails,
    isAuthenticated: state.AuthReducer.isAuthenticated,
  };
};

export default connect(mapStateToProps)(Profile);
