import { useEffect, useState } from "react";
import CustomText from "../../../components/CustomText";
import { WHITE_COLOR } from "../../../resources/colorsManager";
// import { checkMark, RatingStar } from '../../../resources/assetsManager';
import { useSnackbar } from "notistack";
import { useNavigate } from "react-router";
import SwiperCore, { Autoplay } from "swiper";
import "swiper/css";
import "swiper/css/autoplay";
import { Swiper, SwiperSlide } from "swiper/react";
import { CustomButton } from "../../../components/customButton/CustomButton";
import CustomModal from "../../../components/customModal/CustomModal";
import { WishlistHeart } from "../../../resources/assetsManager";
import ImageModal from "./ImageModal";
import "./property.css";
import ResizeListener from "../../../../utility/ResizeListener";
import { WIDTH_LIMIT } from "../../../../data/constants/Constants";
import { STAYS } from "../../../resources/constantsManager";

const PropertyItem = ({
  index,
  item,
  setEnquiryOpen,
  activeTab,
  activeSubCategory,
}) => {
  const { height, width } = ResizeListener();

  SwiperCore.use([Autoplay]);

  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  const [checked, setChecked] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showImageModal, setShowImageModal] = useState(false);
  const [imgs, setImgs] = useState(false);

  const onClickEdit = () => {
    setEnquiryOpen();
  };

  useEffect(() => {
    if (item) setChecked(item?.status ? true : false);
  }, [item]);

  return (
    <div
      key={index}
      style={{ borderBottom: "1px solid #DFDFDF", padding: "20px 0px" }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: width < WIDTH_LIMIT ? "column" : "row",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            height: 250,
            alignItems: "center",
            width: width < WIDTH_LIMIT ? "100%" : "30%",
            backgroundColor:
              item?.photo[0] &&
              item?.photo[0] !== "no-photo.jpg" &&
              item?.photo[0] !== undefined
                ? "#000"
                : "#ccc",
            borderRadius: 20,
            marginRight: 20,
          }}
        >
          {item?.photo[0] &&
          item?.photo[0] !== "no-photo.jpg" &&
          item?.photo[0] !== undefined ? (
            <div
              onClick={() => {
                if (width > WIDTH_LIMIT) setShowImageModal(true);
                setImgs(item?.photo);
              }}
              style={{ cursor: "pointer", width: "100%", height: "100%" }}
            >
              <Swiper
                style={{
                  width: "100%",
                  height: "100%",
                  borderRadius: 15,
                }}
                modules={[Autoplay]}
                spaceBetween={30}
                slidesPerView={1}
                autoplay
              >
                {item?.photo?.map((item, index) => (
                  <SwiperSlide key={index}>
                    <img
                      style={{
                        width: "100%",
                        height: "100%",
                        objectFit: "cover",
                        borderRadius: 15,
                      }}
                      src={item}
                    />
                  </SwiperSlide>
                ))}
              </Swiper>
            </div>
          ) : (
            <p style={{ color: "#000" }}>No Images</p>
          )}
        </div>

        <div
          style={{
            display: "flex",
            flexDirection: "column",
            width: width < WIDTH_LIMIT ? "100%" : "70%",
            justifyContent: "space-between",
            marginTop: width < WIDTH_LIMIT ? 10 : 0,
          }}
        >
          <div>
            <div style={{ display: "flex" }}>
              <div style={{ width: "90%", paddingRight: "20px" }}>
                <CustomText
                  style={{
                    fontWeight: "bold",
                    fontSize: 25,
                    color: "#252525",
                  }}
                >
                  {item?.name}
                </CustomText>
                <CustomText
                  style={{ fontSize: 18, color: "#525252", marginTop: 5 }}
                >
                  {item?.city || item?.location?.city}, {item?.location?.state}
                </CustomText>
                <CustomText
                  style={{ fontSize: 16, color: "#000", marginTop: 5 }}
                >
                  {item?.typeOfStay == "homestay"
                    ? "Homestay"
                    : !item?.typeOfStay
                    ? "Homestay"
                    : "Resort"}
                </CustomText>
              </div>
              {/* <div
                style={{
                  width: "10%",
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "flex-start",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    backgroundColor: "#eee",
                    width: 40,
                    height: 40,
                    borderRadius: 5,
                  }}
                >
                  <WishlistHeart
                    // fill="#AFAFAF"
                    fill="#82C34C"
                    style={{ width: 20, height: 20 }}
                  />
                </div>
              </div> */}
            </div>
            <div
              style={{ display: "flex", marginTop: 10, alignItems: "center" }}
            ></div>

            <div>
              <div style={{ display: "flex" }}>
                <CustomText
                  style={{
                    fontSize: 15,
                    color: "#8E8E8E",
                    marginTop: 10,
                    textDecoration: "line-through",
                    fontWeight: "600",
                  }}
                >
                  ₹{item?.actualStartingPrice} per{" "}
                  {item?.priceFor === "ROOM" ? "room" : "person"}
                </CustomText>
              </div>

              <CustomText
                style={{
                  fontSize: 25,
                  fontWeight: "bold",
                  color: "#101010",
                  marginTop: 0,
                }}
              >
                ₹ {item?.currentStartingPrice} per{" "}
                {item?.priceFor === "ROOM" ? "room" : "person"}
              </CustomText>
            </div>
          </div>

          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              marginTop: width > WIDTH_LIMIT ? 0 : 20,
            }}
          >
            <div
              onClick={() => {
                let type = activeTab === STAYS ? activeTab : activeSubCategory;

                const url =
                  window.location.origin +
                  `/property-details/${type.toLowerCase()}/${item.slug}`;
                window.open(url, "_blank", "noreferrer");
              }}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "150px",
                border: "1px solid #525252",
                borderRadius: 5,
                cursor: "pointer",
              }}
            >
              <CustomText style={{ color: "#525252" }}>View Details</CustomText>
            </div>

            <CustomButton
              loading={loading}
              title="Send Enquiry"
              styles={{
                width: "200px",
                borderRadius: "4px",
                border: 0,
                marginLeft: 20,
              }}
              textStyles={{
                color: WHITE_COLOR,
                fontSize: 17,
                fontWeight: "600",
              }}
              onClick={() => {
                onClickEdit();
              }}
              // disabled={ disabled }
            />
          </div>
        </div>
      </div>

      {/* showImageModal */}
      {showImageModal ? (
        <>
          <CustomModal
            open={showImageModal}
            onClose={() => setShowImageModal(false)}
          >
            <ImageModal onClose={() => setShowImageModal(false)} imgs={imgs} />
          </CustomModal>
        </>
      ) : null}
    </div>
  );
};

export default PropertyItem;
