import React, { useEffect } from "react";
import ResizeListener from "../../../utility/ResizeListener";
import { WIDTH_LIMIT } from "../../../data/constants/Constants";

const TermsAndConditions = () => {
  const { width, height } = ResizeListener();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <div
        style={{
          width: "100%",
          padding: width > WIDTH_LIMIT ? "30px 100px" : 10,
          color: "#101010",
        }}
      >
        <p
          style={{
            fontWeight: "bold",
            fontSize: width > WIDTH_LIMIT ? "25px" : "20px",
            textAlign: "center",
          }}
        >
          Terms and Conditions
        </p>

        <p
          style={{
            fontWeight: "bold",
            marginTop: 20,
            fontSize: width > WIDTH_LIMIT ? 18 : 16,
          }}
        >
          1. Introduction
        </p>
        <p style={{ fontSize: width > WIDTH_LIMIT ? 15 : 14, marginTop: 10 }}>
          Welcome to Wandering Lama ("we," "our," or "us"). These Terms and
          Conditions govern your use of our website, mobile application, and
          services (collectively referred to as the "Platform"). By using our
          Platform, you agree to abide by these terms and conditions.
        </p>

        <p
          style={{
            fontWeight: "bold",
            marginTop: 20,
            fontSize: width > WIDTH_LIMIT ? 18 : 16,
          }}
        >
          2. Booking and Reservations
        </p>
        <p style={{ fontSize: width > WIDTH_LIMIT ? 15 : 14, marginTop: 10 }}>
          2.1. We facilitate bookings for homestays, weekend stays, and
          adventure activities across India.
        </p>
        <p style={{ fontSize: width > WIDTH_LIMIT ? 15 : 14, marginTop: 10 }}>
          2.2. By making a booking through our Platform, you agree to the terms
          and conditions set by the respective hosts and activity providers.
          Please review these terms before making a reservation.
        </p>

        <p
          style={{
            fontWeight: "bold",
            marginTop: 20,
            fontSize: width > WIDTH_LIMIT ? 18 : 16,
          }}
        >
          3. Payment
        </p>
        <p style={{ fontSize: width > WIDTH_LIMIT ? 15 : 14, marginTop: 10 }}>
          3.1. Payment for bookings is made directly through our Platform. We
          use secure payment processing methods to protect your financial
          information.
        </p>
        <p style={{ fontSize: width > WIDTH_LIMIT ? 15 : 14, marginTop: 10 }}>
          3.2. Prices and fees are listed in Indian Rupees (INR) and are subject
          to change without notice.
        </p>

        <p
          style={{
            fontWeight: "bold",
            marginTop: 20,
            fontSize: width > WIDTH_LIMIT ? 18 : 16,
          }}
        >
          4. Cancellation and Refunds
        </p>
        <p style={{ fontSize: width > WIDTH_LIMIT ? 15 : 14, marginTop: 10 }}>
          4.1. Cancellation policies vary by host and activity provider. Please
          review the cancellation policy stated on the booking page before
          making a reservation.
        </p>
        <p style={{ fontSize: width > WIDTH_LIMIT ? 15 : 14, marginTop: 10 }}>
          4.2. To request a refund, you must adhere to the cancellation policy
          provided by the host or activity provider. Refunds will be processed
          according to their terms.
        </p>
        <p style={{ fontSize: width > WIDTH_LIMIT ? 15 : 14, marginTop: 10 }}>
          4.3. We reserve the right to charge a processing fee for handling
          refunds, as specified in the cancellation policy.
        </p>

        <p
          style={{
            fontWeight: "bold",
            marginTop: 20,
            fontSize: width > WIDTH_LIMIT ? 18 : 16,
          }}
        >
          5. User Responsibilities
        </p>
        <p style={{ fontSize: width > WIDTH_LIMIT ? 15 : 14, marginTop: 10 }}>
          5.1. You agree to provide accurate and complete information when
          making reservations.
        </p>
        <p style={{ fontSize: width > WIDTH_LIMIT ? 15 : 14, marginTop: 10 }}>
          5.2. You are responsible for adhering to the rules and guidelines set
          by hosts and activity providers during your stay or activity.
        </p>

        <p
          style={{
            fontWeight: "bold",
            marginTop: 20,
            fontSize: width > WIDTH_LIMIT ? 18 : 16,
          }}
        >
          6. Intellectual Property
        </p>
        <p style={{ fontSize: width > WIDTH_LIMIT ? 15 : 14, marginTop: 10 }}>
          6.1. The content on our Platform, including text, images, logos, and
          trademarks, is protected by intellectual property laws. You may not
          use or reproduce this content without our permission.
        </p>

        <p
          style={{
            fontWeight: "bold",
            marginTop: 20,
            fontSize: width > WIDTH_LIMIT ? 18 : 16,
          }}
        >
          7. Privacy
        </p>
        <p style={{ fontSize: width > WIDTH_LIMIT ? 15 : 14, marginTop: 10 }}>
          7.1. We collect and use personal information in accordance with our
          Privacy Policy, which can be found on our Platform.
        </p>

        <p
          style={{
            fontWeight: "bold",
            marginTop: 20,
            fontSize: width > WIDTH_LIMIT ? 18 : 16,
          }}
        >
          8. Liability
        </p>
        <p style={{ fontSize: width > WIDTH_LIMIT ? 15 : 14, marginTop: 10 }}>
          8.1. We are not responsible for the actions or omissions of hosts,
          activity providers, or other users. You agree to release us from any
          claims, damages, or liabilities arising from your use of our Platform.
        </p>

        <p
          style={{
            fontWeight: "bold",
            marginTop: 20,
            fontSize: width > WIDTH_LIMIT ? 18 : 16,
          }}
        >
          9. Termination
        </p>
        <p style={{ fontSize: width > WIDTH_LIMIT ? 15 : 14, marginTop: 10 }}>
          9.1. We reserve the right to terminate or suspend your account and
          access to our Platform if you violate these Terms and Conditions.
        </p>

        <p
          style={{
            fontWeight: "bold",
            marginTop: 20,
            fontSize: width > WIDTH_LIMIT ? 18 : 16,
          }}
        >
          10. Governing Law
        </p>
        <p style={{ fontSize: width > WIDTH_LIMIT ? 15 : 14, marginTop: 10 }}>
          10.1. These Terms and Conditions are governed by the laws of India.
          Any disputes will be subject to the exclusive jurisdiction of the
          Indian courts.
        </p>

        <p
          style={{
            fontWeight: "bold",
            fontSize: width > WIDTH_LIMIT ? "25px" : "20px",
            textAlign: "center",
            marginTop: 50,
          }}
        >
          Refund Policy
        </p>

        <p
          style={{
            fontWeight: "bold",
            marginTop: 20,
            fontSize: width > WIDTH_LIMIT ? 18 : 16,
          }}
        >
          1. Cancellation by the Guest
        </p>
        <p style={{ fontSize: width > WIDTH_LIMIT ? 15 : 14, marginTop: 10 }}>
          1.1. If you wish to cancel a booking, please review the cancellation
          policy provided by the host or activity provider on the booking page.
        </p>
        <p style={{ fontSize: width > WIDTH_LIMIT ? 15 : 14, marginTop: 10 }}>
          1.2. Refunds, if applicable, will be processed according to the
          cancellation policy and timeline set by the host or activity provider.
        </p>

        <p
          style={{
            fontWeight: "bold",
            marginTop: 20,
            fontSize: width > WIDTH_LIMIT ? 18 : 16,
          }}
        >
          2. Cancellation by the Host or Activity Provider
        </p>
        <p style={{ fontSize: width > WIDTH_LIMIT ? 15 : 14, marginTop: 10 }}>
          2.1. In the event that a host or activity provider cancels a
          reservation, you will receive a full refund as per their cancellation
          policy.
        </p>

        <p
          style={{
            fontWeight: "bold",
            marginTop: 20,
            fontSize: width > WIDTH_LIMIT ? 18 : 16,
          }}
        >
          3. Disputes and Exceptions
        </p>
        <p style={{ fontSize: width > WIDTH_LIMIT ? 15 : 14, marginTop: 10 }}>
          3.1. If you encounter extenuating circumstances or have a dispute
          regarding a refund, please contact us at [contact email/phone] to
          resolve the issue.
        </p>

        <p
          style={{
            fontWeight: "bold",
            marginTop: 20,
            fontSize: width > WIDTH_LIMIT ? 18 : 16,
          }}
        >
          4. Processing Time
        </p>
        <p style={{ fontSize: width > WIDTH_LIMIT ? 15 : 14, marginTop: 10 }}>
          4.1. Refunds will be processed as soon as possible, but the processing
          time may vary depending on the payment method and the policies of the
          host or activity provider.
        </p>

        <p
          style={{
            fontWeight: "bold",
            marginTop: 20,
            fontSize: width > WIDTH_LIMIT ? 18 : 16,
          }}
        >
          5. Processing Fee
        </p>
        <p style={{ fontSize: width > WIDTH_LIMIT ? 15 : 14, marginTop: 10 }}>
          5.1. We reserve the right to charge a processing fee for handling
          refunds, as specified in the cancellation policy.
        </p>

        <p
          style={{
            fontWeight: "bold",
            marginTop: 20,
            fontSize: width > WIDTH_LIMIT ? 18 : 16,
          }}
        >
          6. No-Show Policy
        </p>
        <p style={{ fontSize: width > WIDTH_LIMIT ? 15 : 14, marginTop: 10 }}>
          6.1. If you fail to show up for a reservation without prior
          cancellation, you may not be eligible for a refund. Please refer to
          the host or activity provider's no-show policy.
        </p>

        <p
          style={{
            fontWeight: "bold",
            marginTop: 20,
            fontSize: width > WIDTH_LIMIT ? 18 : 16,
          }}
        >
          7. Force Majeure
        </p>
        <p style={{ fontSize: width > WIDTH_LIMIT ? 15 : 14, marginTop: 10 }}>
          7.1. In cases of unforeseen circumstances such as natural disasters or
          government-imposed restrictions, the refund policy may be adjusted by
          the host or activity provider. Please refer to their specific force
          majeure policy.
        </p>
      </div>
    </>
  );
};

export default TermsAndConditions;
