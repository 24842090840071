import { Typography } from "@mui/material";
import { ReactComponent as Img1 } from "../../../assets/svg/whyChooseUs1.svg";
import { ReactComponent as Img2 } from "../../../assets/svg/whyChooseUs2.svg";
import { ReactComponent as Img3 } from "../../../assets/svg/whyChooseUs3.svg";
import React from "react";
import "./index.css";
import ResizeListener from "../../../utility/ResizeListener";
import {
  WIDTH_LIMIT,
  WIDTH_LIMIT as limit,
} from "../../../data/constants/Constants";

const WhyChooseUs = () => {
  const { width } = ResizeListener();

  return (
    <div className="home-content-container">
      <div className="home-content-header">
        <p
          style={{
            fontFamily: "Poppins",
            fontSize: width > WIDTH_LIMIT ? "30px" : 20,
            fontWeight: "500",
            textAlign: "center",
          }}
        >
          Why Choose{" "}
          <span style={{ color: "#489E00", fontWeight: "700" }}>
            {" "}
            Wandering Lama?
          </span>
        </p>
      </div>
      <section
        className="boxes"
        style={{ width: width > limit ? width * 0.9 : width }}
      >
        <div className="container">
          <div
            className={width > WIDTH_LIMIT ? "box" : "box-mobile"}
            style={{ backgroundColor: "#fff" }}
          >
            <div className="img-container">
              <Img1 style={{ width: width > WIDTH_LIMIT ? 50 : 40 }} />
            </div>
            <Typography
              sx={{
                fontFamily: "Poppins",
                fontSize: width > WIDTH_LIMIT ? "20px" : 16,
                fontWeight: "400",
                color: "#101010",
              }}
            >
              Offer a diverse range of activities, tours, and experiences.
            </Typography>
          </div>

          <div
            className={width > WIDTH_LIMIT ? "box" : "box-mobile"}
            style={{ backgroundColor: "#fff" }}
          >
            <div className="img-container">
              <Img2 style={{ width: width > WIDTH_LIMIT ? 50 : 40 }} />
            </div>
            <Typography
              sx={{
                fontFamily: "Poppins",
                fontSize: width > WIDTH_LIMIT ? "20px" : 16,
                fontWeight: "400",
                color: "#101010",
              }}
            >
              Competitive pricing for various activities makes it an attractive
              option for those looking to get the best value for their money.
            </Typography>
          </div>

          <div
            className={width > WIDTH_LIMIT ? "box" : "box-mobile"}
            style={{ backgroundColor: "#fff" }}
          >
            <div className="img-container">
              <Img3 style={{ width: width > WIDTH_LIMIT ? 50 : 40 }} />
            </div>
            <Typography
              sx={{
                fontFamily: "Poppins",
                fontSize: width > WIDTH_LIMIT ? "20px" : 16,
                fontWeight: "400",
                color: "#101010",
              }}
            >
              Convenient booking process allowing travelers to quickly find and
              book their desired activities without hassle.
            </Typography>
          </div>
        </div>
      </section>
    </div>
  );
};

export default WhyChooseUs;
