import React, { useEffect, useState } from "react";
import ConnectUs from "../../dashboard/ConnectUs";
import {
  CheckMarkIcon,
  RatingStar,
  TriShareIcon,
} from "../../../resources/assetsManager";
import CustomText from "../../../components/CustomText";
import { CustomButton } from "../../../components/customButton/CustomButton";
import { WHITE_COLOR } from "../../../resources/colorsManager";
import { ApiRequestGet } from "../../../../data/network/services/ApiRequestGet";
import secureStorage from "../../../../utility/secureStorage";
import { STAYS } from "../../../resources/constantsManager";
import { useSnackbar } from "notistack";
import CustomLoader from "../../../components/customLoader";
import ResizeListener from "../../../../utility/ResizeListener";
import { useLocation, useNavigate } from "react-router";
import {
  WIDTH_LIMIT,
  WIDTH_LIMIT as limit,
} from "../../../../data/constants/Constants";
import CustomModal from "../../../components/customModal/CustomModal";
import ImageModal from "../PropertyList/ImageModal";
import SwiperCore, { Autoplay } from "swiper";
import "swiper/css";
import "swiper/css/autoplay";
import { Swiper, SwiperSlide } from "swiper/react";
import Enquiry from "../../../modals/Enquiry";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { Parser } from "html-to-react";
import he from "he";
import { SS_ACTIVE_TAB } from "../../../resources/secureStorageManager";
import { connect } from "react-redux";
import Login from "../../../modals/Login";

const AmenitiesItem = ({ title, width, limit }) => (
  <div
    style={{
      display: "flex",
      textAlign: "center",
      padding: "10px",
      borderRadius: "3px",
      justifyContent: "left",
      alignItems: "center",
      fontSize: width > limit ? 18 : 14,
      //border: "1px dashed var(--stroke, #E2E2E2)",
    }}
  >
    <img src={CheckMarkIcon} />
    <p style={{ margin: 0 }}>{title}</p>
  </div>
);

const PackageDetails = ({
  parser,
  item,
  isImage,
  setSelectedImages,
  setShowImageModal,
  width,
  limit,
  property,
  activeType,
}) => {
  let checkIn, checkOut, label;
  if (property.kind.toLowerCase() == "stay") {
    checkIn = property.checkIn;
    checkOut = property.checkOut;
    label = "Check-in & Check-out:";
  } else {
    checkIn = item.checkIn;
    checkOut = item.checkOut;
    label = "Timings:";
  }

  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          margin: "20px 0px 0px 0px",
        }}
      >
        <div
          style={{
            width: width > limit ? "68%" : "100%",
            borderRadius: 5,
          }}
        >
          <div
            style={{
              width: width > limit ? "100%" : "",
              padding: 10,
              background: "#101010",
              borderTopLeftRadius: 5,
              borderTopRightRadius: 5,
            }}
          >
            <p
              style={{
                color: "#fff",
                margin: 0,
                fontSize: width > limit ? 20 : 16,
              }}
            >
              <span style={{ fontWeight: "700" }}>
                {property?.kind === "stay" ? "Room type:" : "Package type:"}
              </span>{" "}
              {item?.name}
            </p>
          </div>

          <div
            style={{
              padding: 9,
              width: width > limit ? "100%" : "",
              border: "1px solid #E2E2E2",
            }}
          >
            <div
              style={{
                padding: width > limit ? 14 : 0,
                fontSize: width > limit ? 18 : 16,
              }}
            >
              <p style={{ color: "#101010", margin: 0 }}>
                <span style={{ fontWeight: "700" }}>
                  {property?.kind === "stay"
                    ? "Room Description"
                    : "Package Description"}
                  :
                </span>{" "}
                {parser.parse(he.decode(item?.description))}
              </p>

              <p style={{ color: "#101010", margin: "10px 0px 0px 0px" }}>
                <span style={{ fontWeight: "700" }}>{label}</span>{" "}
                {new Date(checkIn).toLocaleString("en-US", {
                  hour: "numeric",
                  minute: "numeric",
                  hour12: true,
                })}{" "}
                to{" "}
                {new Date(checkOut).toLocaleString("en-US", {
                  hour: "numeric",
                  minute: "numeric",
                  hour12: true,
                })}
              </p>
              {item?.maxNoOfPeople != undefined && (
                <p style={{ color: "#101010", margin: "10px 0px 0px 0px" }}>
                  <span style={{ fontWeight: "700" }}>
                    Max. No. of Guests :
                  </span>{" "}
                  {item?.maxNoOfPeople}
                </p>
              )}
              {item?.extraBedCost !== undefined && (
                <p style={{ color: "#101010", margin: "10px 0px 0px 0px" }}>
                  <span style={{ fontWeight: "700" }}>Extra Bed Cost :</span> ₹
                  {item?.extraBedCost}
                </p>
              )}
              {item?.inventory !== undefined && (
                <p style={{ color: "#101010", margin: "10px 0px 0px 0px" }}>
                  <span style={{ fontWeight: "700" }}>
                    {property?.kind === "stay"
                      ? "Room Inventory"
                      : "Package Inventory"}
                    :
                  </span>{" "}
                  {item?.inventory}
                </p>
              )}
              <p
                style={{
                  color: "#101010",
                  margin: "10px 0px 0px 0px",
                  fontWeight: "700",
                }}
              >
                Amenities:
              </p>

              <div
                style={{
                  margin: "10px 0px 10px 0px",
                  display: "grid",
                  gridGap: "20px",
                  gridTemplateColumns:
                    width > limit ? "1fr 1fr 1fr" : "1fr 1fr",
                }}
              >
                {item?.ac && (
                  <AmenitiesItem title="AC" width={width} limit={limit} />
                )}
                {item?.breakfast && (
                  <AmenitiesItem
                    title="Breakfast"
                    width={width}
                    limit={limit}
                  />
                )}
                {item?.lunch && (
                  <AmenitiesItem title="Lunch" width={width} limit={limit} />
                )}
                {item?.dinner && (
                  <AmenitiesItem title="Dinner" width={width} limit={limit} />
                )}
                {item?.fridge && (
                  <AmenitiesItem title="Fridge" width={width} limit={limit} />
                )}
                {item?.hitea && (
                  <AmenitiesItem title="Hi-Tea" width={width} limit={limit} />
                )}
                {item?.tv && (
                  <AmenitiesItem title="TV" width={width} limit={limit} />
                )}
                {item?.wifi && (
                  <AmenitiesItem title="Wi-Fi" width={width} limit={limit} />
                )}
                {item?.indoor && (
                  <AmenitiesItem
                    title="Indoor activities"
                    width={width}
                    limit={limit}
                  />
                )}
                {item?.outdoor && (
                  <AmenitiesItem
                    title="Outdoor activities"
                    width={width}
                    limit={limit}
                  />
                )}
                {item?.swimmingPool && (
                  <AmenitiesItem
                    title="Swimming pool"
                    width={width}
                    limit={limit}
                  />
                )}
              </div>

              {isImage && width > limit && (
                <div
                  // onClick={}
                  style={{
                    margin: "20px 0px 10px 0px",
                    display: "grid",
                    gridGap: "20px",
                    gridTemplateColumns: width > limit ? "1fr 1fr 1fr" : "1fr",
                  }}
                >
                  {item?.photo?.map((photo) => (
                    <div
                      onClick={() => {
                        if (width > limit) {
                          setSelectedImages(item?.photo);
                          setShowImageModal(true);
                        }
                      }}
                      style={{
                        height: 200,
                        borderRadius: 5,
                        background: "#ccc",
                        cursor: "pointer",
                      }}
                    >
                      <img
                        style={{
                          width: "100%",
                          height: "100%",
                          borderRadius: 5,
                        }}
                        src={photo}
                      />
                    </div>
                  ))}
                </div>
              )}
              {isImage && width <= limit && (
                <div
                  style={{
                    height: "auto",
                    width: "100%",
                    //paddingBottom: 20,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    borderRadius: 5,
                  }}
                >
                  {item?.photo[0] && item?.photo[0] !== "no-photo.jpg" ? (
                    <Swiper
                      style={{
                        width: "100%",
                        height: "100%",
                      }}
                      modules={[Autoplay]}
                      spaceBetween={30}
                      slidesPerView={1}
                      autoplay
                    >
                      {item?.photo?.map((item, index) => (
                        <SwiperSlide key={index}>
                          <img
                            style={{
                              width: "100%",
                              height: "100%",
                              objectFit: "cover",
                              borderRadius: 5,
                            }}
                            src={item}
                          />
                        </SwiperSlide>
                      ))}
                    </Swiper>
                  ) : (
                    <p style={{ fontSize: 30, color: "#000" }}>No Images</p>
                  )}
                </div>
              )}
              {width <= limit && (
                <div
                  style={{
                    marginTop: "20px",
                    borderRadius: 5,
                    //marginRight: 20,
                  }}
                >
                  <div
                    style={{
                      width: "100%",
                      padding: 10,
                      background: "#101010",
                      borderTopLeftRadius: 5,
                      borderTopRightRadius: 5,
                    }}
                  >
                    <p
                      style={{
                        color: "#fff",
                        margin: 0,
                        fontWeight: "700",
                      }}
                    >
                      Price
                    </p>
                  </div>

                  <div
                    style={{
                      padding: 9,
                      width: "100%",
                      border: "1px solid #E2E2E2",
                    }}
                  >
                    <div style={{ padding: 14 }}>
                      <div style={{ display: "flex", alignItems: "flex-end" }}>
                        <p
                          style={{
                            margin: 0,
                            fontSize: 20,
                            fontWeight: "bold",
                          }}
                        >
                          ₹{item?.currentPrice}
                        </p>

                        <p
                          style={{
                            margin: "0px 0px 5px 15px",
                            textDecoration: "line-through",
                            color: "#525252",
                            fontSize: 14,
                          }}
                        >
                          ₹{item?.actualPrice}
                        </p>

                        <p
                          style={{
                            margin: "0px 0px 5px 5px",
                            color: "#DB9000",
                            fontSize: 14,
                          }}
                        >
                          {item?.discount}% off
                        </p>
                      </div>
                      <div>
                        <p style={{ margin: 0, fontSize: 14 }}>
                          per {item?.priceFor === "ROOM" ? "room" : "person"}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
        {width > limit && (
          <div
            style={{
              width: "25.5%",
              borderRadius: 5,
              marginRight: 20,
            }}
          >
            <div
              style={{
                width: "100%",
                padding: 10,
                background: "#101010",
                borderTopLeftRadius: 5,
                borderTopRightRadius: 5,
              }}
            >
              <p
                style={{
                  color: "#fff",
                  margin: 0,
                  fontWeight: "700",
                  fontSize: 20,
                }}
              >
                Price
              </p>
            </div>

            <div
              style={{
                padding: 9,
                width: "100%",
                border: "1px solid #E2E2E2",
              }}
            >
              <div style={{ padding: 14 }}>
                <div style={{ display: "flex", alignItems: "flex-end" }}>
                  <p style={{ margin: 0, fontSize: 30, fontWeight: "bold" }}>
                    ₹{item?.currentPrice}
                  </p>

                  <p
                    style={{
                      margin: "0px 0px 5px 15px",
                      textDecoration: "line-through",
                      color: "#525252",
                      fontSize: 18,
                    }}
                  >
                    ₹{item?.actualPrice}
                  </p>

                  <p
                    style={{
                      margin: "0px 0px 5px 5px",
                      color: "#DB9000",
                      fontSize: 16,
                    }}
                  >
                    {item?.discount}% off
                  </p>
                </div>
                <div>
                  <p style={{ margin: 0 }}>
                    per {item?.priceFor === "ROOM" ? "room" : "person"}
                  </p>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

const PropertyDetails = ({ isAuthenticated, customerDetails }) => {
  const { enqueueSnackbar } = useSnackbar();
  let location = useLocation();

  const parser = new Parser();

  const [productDetails, setProductDetails] = useState({});
  const [productDetailsErr, setProductDetailsErr] = useState(false);
  const [showImageModal, setShowImageModal] = useState(false);
  const [selectedImages, setSelectedImages] = useState(false);
  const [loading, setLoading] = useState(true);
  const [enquiryOpen, setEnquiryOpen] = useState(false);
  const navigate = useNavigate();
  const { width, height } = ResizeListener();
  const [activeType, setActiveType] = useState("");
  const [loginOpen, setLoginOpen] = useState(false);

  const getActiveType = async () => {
    const type = await secureStorage.setItem(SS_ACTIVE_TAB, STAYS);
    setActiveType(type);
  };

  useEffect(() => {
    getActiveType();
  }, []);

  useEffect(() => {
    if (location?.state?.openEnquiry) setEnquiryOpen(true);
  }, [location]);

  const getPropertyDetailsBySlug = async () => {
    try {
      setLoading(true);
      let pathname = window?.location?.pathname;
      pathname = pathname?.split("/");
      const slug = pathname[pathname?.length - 1];
      const type = pathname[pathname?.length - 2];
      ApiRequestGet.getPropertyDetailsBySlug(type, slug)
        .then((res) => {
          if (res?.data?.success) {
            setProductDetails(res?.data?.data);
          } else {
            setProductDetailsErr(true);
            enqueueSnackbar(res?.data?.message, {
              variant: "error",
              anchorOrigin: { vertical: "top", horizontal: "right" },
            });
          }
        })
        .catch((err) => {
          setProductDetailsErr(true);
          enqueueSnackbar(err.response.data.message, {
            variant: "error",
            anchorOrigin: { vertical: "top", horizontal: "right" },
          });
        })
        .finally(() => {
          setLoading(false);
        });
    } catch (err) {
      setLoading(false);
    }
  };

  useEffect(() => {
    getPropertyDetailsBySlug();
  }, []);

  return (
    <>
      {loading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: width,
            height: height,
          }}
        >
          <CustomLoader />
        </div>
      ) : (
        <>
          {productDetailsErr ? (
            <>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  width: width,
                  height: width > WIDTH_LIMIT ? height * 0.4 : height * 0.9,
                }}
              >
                <p style={{ fontSize: 30, fontWeight: "bold", color: "#444" }}>
                  404 NOT FOUND
                </p>
                <div
                  onClick={() => {
                    navigate(`/dashboard`);
                  }}
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "150px",
                    border: "1px solid #82C34C",
                    borderRadius: 5,
                    cursor: "pointer",
                  }}
                >
                  <CustomText style={{ color: "#82C34C" }}>
                    Go to Home
                  </CustomText>
                </div>
              </div>
            </>
          ) : (
            <div
              style={{
                width: "100%",
              }}
            >
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <div
                  style={{
                    width: width > limit ? "95%" : "88%",
                    padding: "24px 16px",
                    borderRadius: 10,
                    border: "1px solid #D9D9D9",
                  }}
                >
                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <div
                      style={{
                        width: width > limit ? "70%" : "100%",
                        background: "#aaa",
                        borderRadius: 5,
                        height: width > limit ? 500 : 300,
                      }}
                    >
                      <div
                        onClick={() => {
                          if (width > limit) {
                            setSelectedImages(productDetails?.photo);
                            setShowImageModal(true);
                          }
                        }}
                        style={{
                          height: width > limit ? 500 : 300,
                          //paddingBottom: 20,
                          cursor: "pointer",
                          backgroundColor:
                            productDetails?.photo[0] &&
                            productDetails?.photo[0] !== "no-photo.jpg"
                              ? ""
                              : "#ccc",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          borderRadius: 5,
                        }}
                      >
                        {productDetails?.photo[0] &&
                        productDetails?.photo[0] !== "no-photo.jpg" ? (
                          <Swiper
                            style={{
                              width: "100%",
                              height: "100%",
                            }}
                            modules={[Autoplay]}
                            spaceBetween={30}
                            slidesPerView={1}
                            autoplay
                          >
                            {productDetails?.photo?.map((item, index) => (
                              <SwiperSlide key={index}>
                                <img
                                  style={{
                                    width: "100%",
                                    height: "100%",
                                    objectFit: "cover",
                                    borderRadius: 5,
                                  }}
                                  src={item}
                                />
                              </SwiperSlide>
                            ))}
                          </Swiper>
                        ) : (
                          <p style={{ fontSize: 30, color: "#000" }}>
                            No Images
                          </p>
                        )}
                      </div>
                    </div>
                    {width > limit && (
                      <div
                        style={{
                          position: "relative",
                          width: "28%",
                          background: "#fff",
                          borderRadius: 5,
                          height: width > limit ? 500 : 300,
                          border: "1px solid #E2E2E2",
                        }}
                      >
                        <div
                          style={{
                            background: "#000",
                            padding: 20,
                            borderTopRightRadius: 5,
                            borderTopLeftRadius: 5,
                          }}
                        >
                          <p
                            style={{
                              color: "#fff",
                              margin: 0,
                              fontSize: 22,
                              fontWeight: "500",
                            }}
                          >
                            Book Now!
                          </p>
                        </div>

                        <div
                          style={{
                            padding: 30,
                            height: "350px",
                          }}
                        >
                          <p
                            style={{
                              color: "#101010",
                              fontWeight: "700",
                              fontSize: 22,
                              margin: 0,
                            }}
                          >
                            {productDetails?.name}
                          </p>
                          <p
                            style={{
                              color: "#525252",
                              fontSize: 18,
                              margin: 0,
                              marginTop: 5,
                            }}
                          >
                            {productDetails?.city ||
                              productDetails?.location?.city}
                            , {productDetails?.location?.state}
                          </p>
                          <div>
                            <p
                              style={{
                                fontSize: 20,
                                fontWeight: "500",
                                color: "#525252",
                                margin: "20px 0px 0px 0px",
                              }}
                            >
                              Starting from
                            </p>
                          </div>
                          <div
                            style={{
                              margin: 0,
                              display: "flex",
                              alignItems: "flex-end",
                            }}
                          >
                            <CustomText
                              style={{
                                fontSize: 36,
                                fontWeight: "bold",
                                color: "#101010",
                                marginTop: 0,
                              }}
                            >
                              ₹{productDetails?.currentStartingPrice}
                            </CustomText>

                            <CustomText
                              style={{
                                fontSize: 20,
                                fontWeight: "500",
                                color: "#525252",
                                marginTop: 0,
                                marginLeft: 10,
                                marginBottom: 7,
                                textDecoration: "line-through",
                              }}
                            >
                              ₹{productDetails?.actualStartingPrice}
                            </CustomText>
                            <CustomText
                              style={{
                                fontSize: 20,
                                fontWeight: "500",
                                color: "#E89B08",
                                marginTop: 0,
                                marginLeft: 10,
                                marginBottom: 7,
                              }}
                            >
                              {productDetails?.discount}% off
                            </CustomText>
                          </div>

                          <div>
                            <p style={{ margin: 0, fontSize: 20 }}>
                              per{" "}
                              {productDetails?.priceFor === "ROOM"
                                ? "room"
                                : "person"}
                            </p>
                          </div>

                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              position: "absolute",
                              bottom: 20,
                              left: 0,
                              width: "100%",
                              padding: "0px 30px",
                            }}
                          >
                            {productDetails?.kind !== "stay" &&
                              productDetails?.isPaymentEnabled && (
                                <CustomButton
                                  // loading={loading}
                                  title="Book Now"
                                  styles={{
                                    width: "38%",
                                    borderRadius: "4px",
                                    border: 0,
                                  }}
                                  textStyles={{
                                    color: WHITE_COLOR,
                                    fontSize: 14,
                                    fontWeight: "600",
                                  }}
                                  onClick={() => {
                                    if (isAuthenticated) {
                                      navigate("/book-now", {
                                        state: productDetails,
                                      });
                                    } else {
                                      setLoginOpen(true);
                                    }
                                  }}
                                  // disabled={ disabled }
                                />
                              )}

                            <CustomButton
                              // loading={loading}
                              title="Send Enquiry"
                              styles={{
                                background:
                                  productDetails?.kind !== "stay" &&
                                  productDetails?.isPaymentEnabled
                                    ? WHITE_COLOR
                                    : "#101010",
                                width:
                                  productDetails?.kind !== "stay" &&
                                  productDetails?.isPaymentEnabled
                                    ? "38%"
                                    : "78%",
                                borderRadius: "4px",
                                border: "1px solid black",
                              }}
                              textStyles={{
                                // color: WHITE_COLOR,
                                color:
                                  productDetails?.kind !== "stay" &&
                                  productDetails?.isPaymentEnabled
                                    ? "black"
                                    : WHITE_COLOR,
                                fontSize: 14,
                                fontWeight: "600",
                              }}
                              onClick={() => {
                                setEnquiryOpen(true);
                              }}
                              // disabled={ disabled }
                            />

                            <CopyToClipboard
                              text={window.location.href}
                              onCopy={() => {
                                enqueueSnackbar("Link Copied to clipboard!", {
                                  variant: "success",
                                  anchorOrigin: {
                                    vertical: "top",
                                    horizontal: "right",
                                  },
                                });
                              }}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  width: "18%",
                                  border: "1px solid #101010",
                                  borderRadius: "5px",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  cursor: "pointer",
                                }}
                              >
                                <img style={{ width: 23 }} src={TriShareIcon} />
                                {/* <p
                                  style={{
                                    margin: 0,
                                    marginLeft: 5,
                                  }}
                                >
                                  Share
                                </p> */}
                              </div>
                            </CopyToClipboard>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>

                  <div
                    style={{
                      margin: width > limit ? "0px 20px 0px 20px" : "0px",
                    }}
                  >
                    <p
                      style={{
                        color: "#101010",
                        fontSize: width > limit ? 25 : 22,
                        fontWeight: 700,
                        margin: "30px 0px 10px 0px",
                      }}
                    >
                      {productDetails?.name}
                    </p>
                    <p
                      style={{
                        color: "#101010",
                        fontSize: width > limit ? 18 : 16,
                        margin: "0px 0px 8px 0px",
                      }}
                    >
                      {productDetails?.city || productDetails?.location?.city},{" "}
                      {productDetails?.location?.state}
                    </p>

                    {/* <div
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  background: "#428D04",
                  width: 35,
                  padding: "2px 5px 2px 5px",
                  height: 20,
                  borderRadius: 3,
                }}
              >
                <p style={{ margin: 0, fontSize: 12, color: "#fff" }}>4.5</p>
                <img style={{ width: 10, marginLeft: 5 }} src={RatingStar} />
              </div>

              <p
                style={{
                  margin: "0px 10px 0px 10px",
                  color: "#525252",
                  fontSize: 14,
                }}
              >
                (424 Ratings){" "}
              </p>

              <div
                style={{
                  background: "#525252",
                  width: 3,
                  height: 3,
                  borderRadius: 10,
                }}
              />

              <p
                style={{
                  margin: "0px 10px 0px 10px",
                  color: "#525252",
                  fontSize: 14,
                }}
              >
                Very Good
              </p>
            </div> */}

                    {productDetails.kind.toLowerCase() === "stay" && (
                      <div
                        style={{
                          margin:
                            width > limit ? "30px 0px 30px 0px" : "20px 0px",
                          display: "grid",
                          gridGap: "20px",
                          gridTemplateColumns:
                            width > limit ? "1fr 1fr 1fr" : "1fr 1fr",
                        }}
                      >
                        {productDetails?.ac && <AmenitiesItem title="AC" />}
                        {productDetails?.breakfast && (
                          <AmenitiesItem
                            title="Breakfast"
                            width={width}
                            limit={limit}
                          />
                        )}
                        {productDetails?.lunch && (
                          <AmenitiesItem
                            title="Lunch"
                            width={width}
                            limit={limit}
                          />
                        )}
                        {productDetails?.dinner && (
                          <AmenitiesItem
                            title="Dinner"
                            width={width}
                            limit={limit}
                          />
                        )}
                        {productDetails?.freeParking && (
                          <AmenitiesItem
                            title="Free Parking"
                            width={width}
                            limit={limit}
                          />
                        )}
                        {productDetails?.fridge && (
                          <AmenitiesItem
                            title="Fridge"
                            width={width}
                            limit={limit}
                          />
                        )}
                        {productDetails?.hitea && (
                          <AmenitiesItem
                            title="Hi-Tea"
                            width={width}
                            limit={limit}
                          />
                        )}
                        {productDetails?.houseKeeping && (
                          <AmenitiesItem
                            title="House Keeping"
                            width={width}
                            limit={limit}
                          />
                        )}
                        {productDetails?.indoorAct && (
                          <AmenitiesItem
                            title="Indoor Activity"
                            width={width}
                            limit={limit}
                          />
                        )}
                        {productDetails?.outdoorAct && (
                          <AmenitiesItem
                            title="Outdoor Activity"
                            width={width}
                            limit={limit}
                          />
                        )}
                        {productDetails?.petFriendly && (
                          <AmenitiesItem
                            title="Pet Friendly"
                            width={width}
                            limit={limit}
                          />
                        )}
                        {productDetails?.powerBackup && (
                          <AmenitiesItem
                            title="Power Backup"
                            width={width}
                            limit={limit}
                          />
                        )}
                        {productDetails?.swimmingPool && (
                          <AmenitiesItem
                            title="Swimming Pool"
                            width={width}
                            limit={limit}
                          />
                        )}
                        {productDetails?.wifi && (
                          <AmenitiesItem
                            title="Wi-Fi"
                            width={width}
                            limit={limit}
                          />
                        )}
                      </div>
                    )}
                    <div>
                      <p
                        style={{
                          fontSize: width > limit ? 20 : 14,
                          fontWeight: "500",
                          color: "#525252",
                          margin: 0,
                        }}
                      >
                        Starting from
                      </p>
                    </div>

                    <div style={{ display: "flex", alignItems: "flex-end" }}>
                      <p
                        style={{
                          margin: 0,
                          fontSize: width > limit ? 36 : 22,
                          fontWeight: "bold",
                        }}
                      >
                        ₹{productDetails?.currentStartingPrice}
                      </p>

                      <p
                        style={{
                          margin: "0px 0px 5px 15px",
                          textDecoration: "line-through",
                          color: "#525252",
                          fontSize: width > limit ? 20 : 14,
                        }}
                      >
                        ₹{productDetails?.actualStartingPrice}
                      </p>

                      <p
                        style={{
                          margin: "0px 0px 5px 5px",
                          color: "#DB9000",
                          fontSize: width > limit ? 20 : 14,
                        }}
                      >
                        {productDetails?.discount}% off
                      </p>
                    </div>

                    <p
                      style={{
                        margin: 0,
                        fontSize: width > limit ? 20 : 14,
                        color: "#525252",
                      }}
                    >
                      per{" "}
                      {productDetails?.priceFor === "ROOM" ? "room" : "person"}
                    </p>

                    {width < limit && (
                      <>
                        {productDetails?.kind !== "stay" &&
                          productDetails?.isPaymentEnabled && (
                            <CustomButton
                              // loading={loading}
                              title="Book Now"
                              styles={{
                                marginTop: 10,
                                width: "100%",
                                borderRadius: "4px",
                                border: 0,
                              }}
                              textStyles={{
                                color: WHITE_COLOR,
                                fontSize: 16,
                                fontWeight: "600",
                              }}
                              onClick={() => {
                                if (isAuthenticated) {
                                  navigate("/book-now", {
                                    state: productDetails,
                                  });
                                } else {
                                  setLoginOpen(true);
                                }
                              }}
                              // disabled={ disabled }
                            />
                          )}
                        <div
                          style={{
                            padding: "10px 0px 0px 0px",
                            display: "flex",
                            columnGap: "2%",
                          }}
                        >
                          <CustomButton
                            // loading={loading}
                            title="Send Enquiry"
                            styles={{
                              width: "65%",
                              borderRadius: "4px",
                              border: 0,
                            }}
                            textStyles={{
                              color: WHITE_COLOR,
                              fontSize: 16,
                              fontWeight: "600",
                            }}
                            onClick={() => {
                              setEnquiryOpen(true);
                            }}
                            // disabled={ disabled }
                          />

                          <CopyToClipboard
                            text={window.location.href}
                            onCopy={() => {
                              enqueueSnackbar("Link Copied to clipboard!", {
                                variant: "success",
                                anchorOrigin: {
                                  vertical: "top",
                                  horizontal: "right",
                                },
                              });
                            }}
                          >
                            <div
                              style={{
                                display: "flex",
                                width: "33%",
                                border: "1px solid #101010",
                                borderRadius: "5px",
                                justifyContent: "center",
                                alignItems: "center",
                                cursor: "pointer",
                              }}
                            >
                              <img style={{ width: 23 }} src={TriShareIcon} />
                              <p
                                style={{
                                  margin: 0,
                                  marginLeft: 5,
                                  fontSize: 16,
                                }}
                              >
                                Share
                              </p>
                            </div>
                          </CopyToClipboard>
                        </div>
                      </>
                    )}

                    <div
                      style={{
                        margin:
                          width > limit
                            ? "30px 0px 0px 0px"
                            : "20px 0px 0px 0px",
                      }}
                    >
                      <p
                        style={{
                          margin: 0,
                          color: "#101010",
                          fontSize: width > limit ? "24px" : "18px",
                          fontWeight: "600",
                          lineHeight: "normal",
                        }}
                      >
                        Description
                      </p>

                      <p
                        style={{
                          margin: 0,
                          color: "#101010",
                          fontSize: width > limit ? "18px" : "16px",
                        }}
                      >
                        {parser.parse(he.decode(productDetails?.description))}
                      </p>
                    </div>

                    <p
                      style={{
                        margin:
                          width > limit
                            ? "30px 0px 0px 0px"
                            : "20px 0px 0px 0px",
                        color: "#101010",
                        fontSize: width > limit ? "24px" : "18px",
                        fontWeight: "600",
                        lineHeight: "normal",
                      }}
                    >
                      {productDetails?.kind === "stay"
                        ? "Room Details"
                        : "Package Details"}
                    </p>

                    {productDetails?.packages?.map((item) => (
                      <PackageDetails
                        parser={parser}
                        item={item}
                        isImage={item?.photo?.length > 0}
                        setShowImageModal={setShowImageModal}
                        setSelectedImages={setSelectedImages}
                        width={width}
                        limit={limit}
                        property={productDetails}
                        activeType={activeType}
                      />
                    ))}
                  </div>
                  <div
                    style={{
                      width: "100%",
                      overflow: "hidden",
                      height: width > limit ? "500px" : "300px",
                      marginTop: 20,
                    }}
                  >
                    <iframe
                      width="100%"
                      height={width > limit ? 650 : 400}
                      frameborder="0"
                      style={{
                        border: 0,
                        marginTop: width > limit ? -150 : -100,
                      }}
                      src={
                        "https://www.google.com/maps/embed/v1/place?key=AIzaSyA59va5uG3VRd21n9UiFXWMCVBvjCC-Ajc&q=" +
                        //`${propertyDetails?.address.replace(/ /g, "+")}`
                        `${productDetails?.location?.coordinates[1]},${productDetails?.location?.coordinates[0]}`
                      }
                      allowfullscreen
                    />
                  </div>
                  {productDetails?.termsAndConditions != undefined && (
                    <div>
                      <p
                        style={{
                          margin: "20px 0px 0px 0px",
                          color: "#101010",
                          fontSize: width > limit ? "24px" : "18px",
                          fontWeight: "600",
                          lineHeight: "normal",
                        }}
                      >
                        Terms and conditions
                      </p>
                      <p
                        style={{
                          margin: 0,
                          color: "#101010",
                          fontSize: width > limit ? "18px" : "15px",
                          whiteSpace: "pre-wrap",
                        }}
                      >
                        {parser.parse(
                          he.decode(productDetails?.termsAndConditions)
                        )}
                      </p>
                    </div>
                  )}
                </div>
              </div>
              {showImageModal ? (
                <>
                  <CustomModal
                    open={showImageModal}
                    onClose={() => setShowImageModal(false)}
                  >
                    <ImageModal
                      onClose={() => setShowImageModal(false)}
                      imgs={selectedImages}
                    />
                  </CustomModal>
                </>
              ) : null}
              <div
                style={{
                  marginTop: 35,
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <ConnectUs />
              </div>

              {enquiryOpen && (
                <Enquiry
                  open={enquiryOpen}
                  setEnquiryOpen={setEnquiryOpen}
                  activeEnquiryProperty={productDetails}
                />
              )}

              {/* {loginOpen && ( */}
              <Login
                loginOpen={loginOpen}
                setLoginOpen={setLoginOpen}
                isMessage={true}
                onSuccess={() => {
                  navigate("/book-now", {
                    state: productDetails,
                  });
                }}
              />
              {/* )} */}
            </div>
          )}
        </>
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    customerDetails: state.AuthReducer.customerDetails,
    isAuthenticated: state.AuthReducer.isAuthenticated,
  };
};

export default connect(mapStateToProps)(PropertyDetails);
