import React, { useEffect } from "react";
import ResizeListener from "../../utility/ResizeListener";
import { WanderinglamaColor } from "../resources/assetsManager";
import { WIDTH_LIMIT } from "../../data/constants/Constants";
import { useNavigate } from "react-router";

const Footer = () => {
  const { width } = ResizeListener();
  const navigate = useNavigate();

  useEffect(() => {
    let pathname = window.location.pathname;
    pathname = pathname.split("/");
    pathname = pathname[pathname.length - 1];

    let scrollEle = "";
    if (
      pathname === "homestays" ||
      pathname === "resorts" ||
      pathname === "stays"
    ) {
      scrollEle = "stays";
    }
    if (pathname === "experiences" || pathname === "dayouting") {
      scrollEle = "dayoutings";
    }
    if (pathname === "camping") {
      scrollEle = "campings";
    }

    if (scrollEle) {
      var element = document.getElementById(scrollEle);
      if (element) {
        element.scrollIntoView({
          behavior: "smooth",
          block: "center",
          inline: "nearest",
        });
      }
    }
  }, [window.location.pathname]);

  const BottomBox = ({ data }) => (
    <div style={{ display: "flex", justifyContent: "space-between" }}>
      {data.map((item) => (
        <div
          style={{
            width: width > WIDTH_LIMIT ? `${90 / data.length}%` : "50%",
          }}
        >
          <div style={{ margin: "15px 0px" }}>
            <text
              onClick={item.onClick}
              style={{
                ...item.style,
                fontSize: width > WIDTH_LIMIT ? 17 : 13,
                padding: width > WIDTH_LIMIT ? 10 : 0,
                cursor: "pointer",
                transition: "font-weight 0.3s",
                fontWeight: "normal",
              }}
              onMouseEnter={(e) => {
                e.target.style.fontWeight = "bold";
              }}
              onMouseLeave={(e) => {
                e.target.style.fontWeight = "normal";
              }}
            >
              {item.title}
            </text>
          </div>

          {item.subItem.map((subItem) => (
            <div style={{ margin: "15px 0px" }}>
              <text
                onClick={subItem.onClick}
                style={{
                  ...subItem.style,
                  padding: width > WIDTH_LIMIT ? 10 : 0,
                  fontSize: width > WIDTH_LIMIT ? 17 : 13,
                  cursor: "pointer",
                  transition: "font-weight 0.3s",
                  fontWeight: "normal",
                }}
                onMouseEnter={(e) => {
                  e.target.style.fontWeight = "bold";
                }}
                onMouseLeave={(e) => {
                  e.target.style.fontWeight = "normal";
                }}
              >
                {subItem.title}
              </text>
              <br />
            </div>
          ))}
        </div>
      ))}
      <p></p>
    </div>
  );

  const footerData = [
    {
      title: "About Us",
      link: "",
      style: {},
      onClick: () => {
        navigate("/about-us");
      },
      subItem: [
        {
          title: "Contact Us",
          link: "",
          style: {},
          onClick: () => {
            // window.scrollTo(0, 0);
            navigate("/contact-us");
          },
        },
        {
          title: "Privacy Policy",
          link: "",
          style: {},
          onClick: () => {
            // window.scrollTo(0, 0);
            navigate("/privacy-policy");
          },
        },
      ],
    },
    {
      title: "Stays",
      link: "",
      style: {},
      onClick: () => {
        // window.scrollTo(0, 0);
        navigate("/home/stays");
      },
      subItem: [
        {
          title: "Home Stays",
          link: "",
          style: {},
          onClick: () => {
            // window.scrollTo(0, 0);
            navigate("/home/homestays");
          },
        },
        {
          title: "Resorts",
          link: "",
          style: {},
          onClick: () => {
            // window.scrollTo(0, 0);
            navigate("/home/resorts");
          },
        },
      ],
    },
    {
      title: "Experiences",
      link: "",
      style: {},
      onClick: () => {
        // window.scrollTo(0, 0);
        navigate("/home/experiences");
      },
      subItem: [
        {
          title: "Campings",
          link: "",
          style: {},
          onClick: () => {
            // window.scrollTo(0, 0);
            navigate("/home/camping");
          },
        },
        {
          title: "Day Outings",
          link: "",
          style: {},
          onClick: () => {
            // window.scrollTo(0, 0);
            navigate("/home/dayouting");
          },
        },
      ],
    },
    // {
    //   title: "Events",
    //   link: "",
    //   style: {},
    //   subItem: [
    //     {
    //       title: "School",
    //       link: "",
    //       style: {},
    //     },
    //     {
    //       title: "Corporates",
    //       link: "",
    //       style: {},
    //     },
    //   ],
    // },
    // {
    //   title: "Tours",
    //   link: "",
    //   style: {},
    //   subItem: [
    //     {
    //       title: "Domestic",
    //       link: "",
    //       style: {},
    //     },
    //     {
    //       title: "International",
    //       link: "",
    //       style: {},
    //     },
    //   ],
    // },
  ];

  return (
    <>
      <div
        style={{
          //height: 400,
          position: "relative",
          width: "100%",
          backgroundColor: "#fff",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          padding: "100px 0px",
          borderTop: "1px solid #B0B0B0",
        }}
      >
        <img
          style={{ width: width > WIDTH_LIMIT ? 350 : 200 }}
          src={WanderinglamaColor}
        />

        <div
          style={{
            width: width > WIDTH_LIMIT ? "80%" : "100%",
            marginLeft: width > WIDTH_LIMIT ? "17%" : 0,
            padding: width > WIDTH_LIMIT ? 0 : 30,
            marginTop: 60,
          }}
        >
          <BottomBox style={{}} data={footerData} />
        </div>

        <div
          style={{
            width: "100%",
            position: "absolute",
            bottom: 0,
            textAlign: "center",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <p style={{ color: "#000", fontSize: 12 }}>
            Copyright © WANDERING LAMA
          </p>
          <span style={{ fontSize: 16, margin: "0px 2px" }}> | </span>
          <p
            onClick={() => {
              navigate("/terms-and-conditions");
            }}
            style={{ color: "#000", fontSize: 12, cursor: "pointer" }}
          >
            {" "}
            Terms and Conditions
          </p>
        </div>
      </div>
    </>
  );
};

export default Footer;
