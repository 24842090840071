import Modal from "@mui/material/Modal";
import { styled } from "@mui/system";
import { WHITE_COLOR } from "../../resources/colorsManager";

const Backdrop = styled("div")`
  z-index: -1;
  position: fixed;
  right: 0;
  bottom: 0;
  top: 0;
  left: 0;
  background-color: rgb(29, 45, 68, 0.6);
  -webkit-tap-highlight-color: transparent;
`;

export const CustomModal1 = ({ children, open, onClose, viewMoreStyle }) => {
  return (
    <>
      <Modal
        open={open}
        onClose={onClose}
        BackdropComponent={Backdrop}
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          border: "0px solid gray",
          ...viewMoreStyle,
        }}
      >
        <div
          style={{
            width: "40%",
            height: "100%",
            minWidth: "500px",
            backgroundColor: WHITE_COLOR,
            border: "0px solid white",
            position: "relative",
            overflowY: "scroll",
            right: 0,
            top: 0,
          }}
        >
          {children}
        </div>
      </Modal>
    </>
  );
};

function CustomModal({ children, open, onClose, viewMoreStyle }) {
  return (
    <>
      <Modal
        open={open}
        onClose={onClose}
        BackdropComponent={Backdrop}
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          border: "0px solid gray",
          ...viewMoreStyle,
        }}
      >
        <div>{children}</div>
      </Modal>
    </>
  );
}

export default CustomModal;
