import { React, useCallback, useState, useEffect } from "react";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import { Typography, Button, TextField, CircularProgress } from "@mui/material";
import "./index.css";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { ApiRequestPost } from "../../data/network/services/ApiRequestPost";
import { useSnackbar } from "notistack";
import ResizeListener from "../../utility/ResizeListener";
import { WIDTH_LIMIT as limit } from "../../data/constants/Constants";

export default function Otp({
  otpOpen,
  setOtpOpen,
  isEmailOrNumber,
  senderDetails,
  setStep,
  email,
  setLoginOpen,
  setResetOpen,
  isResetPassword,
  setAccessToken,
}) {
  // @props details
  // otpOpen,setOtpOpen to open and close the modal
  // isEmailOrNumber - send either "Email" or "Number" based on Type of Otp Validation
  // senderDetails - Email or Number to which Otp is sent.
  const { enqueueSnackbar } = useSnackbar();
  const { width } = ResizeListener();
  const handleClose = () => {
    setStep(1);
    setOtpOpen(false);
  };
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: width > limit ? "30%" : "80%",
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
    borderRadius: "13px",
  };
  const [otp, setOtp] = useState("");
  const [loading, setLoading] = useState("");
  const [error, setError] = useState({
    otp: {
      status: "",
      message: "",
    },
  });

  const [timer, setTimer] = useState(60);
  const timeOutCallback = useCallback(
    () => setTimer((currTimer) => currTimer - 1),
    []
  );
  const [maskedDetails, setMaskedDetails] = useState("");

  useEffect(() => {
    timer > 0 && setTimeout(timeOutCallback, 1000);
  }, [timer, timeOutCallback]);

  useEffect(() => {
    if (isEmailOrNumber === "Email") {
      setMaskedDetails(
        senderDetails.replace(
          /^(.)(.*)(.@.*)$/,
          (_, a, b, c) => a + b.replace(/./g, "*") + c
        )
      );
    } else if (isEmailOrNumber === "Number") {
      const num = `${senderDetails.substring(
        0,
        2
      )} ${"****"} ${senderDetails.substring(6, senderDetails.length)}`;
      setMaskedDetails(num);
    }
  }, []);

  const resetTimer = function () {
    if (!timer || timer == -1) {
      setLoading(true);
      ApiRequestPost.forgotPassword(email)
        .then((res) => {
          if (res.data.success) {
            setTimer(60);
          }
          enqueueSnackbar(
            res?.data?.success
              ? "OTP sent Successfully "
              : "Something went wrong!",
            {
              variant: res?.data?.success ? "success" : "error",
              anchorOrigin: { vertical: "top", horizontal: "right" },
            }
          );
        })
        .catch((err) => {
          if (err?.response?.data?.statusCode == 404) {
            enqueueSnackbar("No user found for the provided email/phone", {
              variant: "error",
              anchorOrigin: { vertical: "top", horizontal: "right" },
            });
          } else {
            enqueueSnackbar(err.response.data.message, {
              variant: "error",
              anchorOrigin: { vertical: "top", horizontal: "right" },
            });
          }
          console.log("errerr", err);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  function validateNumber(num) {
    let regex = /^\d*(\.\d+)?$/;
    return num.match(regex);
  }

  const handleChange = (event) => {
    if (event.target.id === "otp") {
      if (validateNumber(event.target.value)) {
        setOtp(event.target.value.trim());
      }
    }
  };

  const handleValidation = (event) => {
    let newErr = { ...error };
    if (event.target.id === "otp") {
      if (otp && otp.length != 6) {
        newErr.otp.status = "error";
        newErr.otp.message = "Enter a valid otp";
      } else {
        newErr.otp.status = "";
        newErr.otp.message = "";
      }
    }
    setError(newErr);
  };
  const handleSubmit = () => {
    let newErr = { ...error };
    if (otp) {
      if (!error.otp.status) {
        /// otp Validation
        setLoading(true);
        ApiRequestPost.verifyOtp(email, otp)
          .then((res) => {
            if (res.data.success) {
              if (!isResetPassword) {
                setOtpOpen(false);
                setStep(1);
                setLoginOpen(true);
                enqueueSnackbar(
                  res?.data?.success
                    ? "Sign-up Successful "
                    : "Something went wrong",
                  {
                    variant: res?.data?.success ? "success" : "error",
                    anchorOrigin: { vertical: "top", horizontal: "right" },
                  }
                );
              } else {
                setOtpOpen(false);
                setStep(5);
                setResetOpen(true);
                setAccessToken(res?.data?.token);
                enqueueSnackbar(
                  res?.data?.success
                    ? "Email verification Successful "
                    : "Something went wrong",
                  {
                    variant: res?.data?.success ? "success" : "error",
                    anchorOrigin: { vertical: "top", horizontal: "right" },
                  }
                );
              }
            }
          })
          .catch((err) => {
            enqueueSnackbar(err.response.data.message, {
              variant: "error",
              anchorOrigin: { vertical: "top", horizontal: "right" },
            });
            console.log(err);
          })
          .finally(() => {
            setLoading(false);
          });
      }
    } else {
      if (!otp) {
        newErr.otp = { status: "error", message: "Please enter the otp" };
      }

      setError(newErr);
    }
  };

  return (
    <div>
      <Modal
        open={otpOpen}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={otpOpen}>
          <Box sx={style}>
            <IconButton
              style={{
                position: "absolute",
                top: "0",
                right: "0",
              }}
              onClick={() => setOtpOpen(false)}
            >
              <CloseIcon fontSize="large" />
            </IconButton>
            <div className="model-title">
              <Typography
                sx={{
                  fontFamily: "Poppins",
                  fontSize: width > limit ? "40px" : "30px",
                  fontWeight: 600,
                  color: "#000000",
                  textAlign: "center",
                }}
              >
                Verify{" "}
                {isEmailOrNumber === "Number"
                  ? "Mobile Number"
                  : isEmailOrNumber}
              </Typography>
            </div>
            <div className="otp-fields">
              <div className="otp-field">
                <TextField
                  autoFocus
                  id="otp"
                  label="Otp"
                  placeholder="Enter Your OTP Here"
                  size="medium"
                  required
                  inputProps={{ maxLength: 6 }}
                  sx={{ width: width > limit ? "80%" : "100%" }}
                  error={error.otp.status === "error"}
                  helperText={
                    error.otp.status === "error" ? error.otp.message : ""
                  }
                  onChange={(e) => handleChange(e)}
                  onBlur={(e) => handleValidation(e)}
                  value={otp}
                />
                <Typography
                  sx={{
                    fontFamily: "Poppins",
                    fontSize: "13px",
                    fontWeight: 300,
                    color: "#6C6A6A",
                    textAlign: "center",
                  }}
                >
                  a 6-digit code has been sent to {maskedDetails}
                </Typography>
              </div>
              <Button
                onClick={() => {
                  handleSubmit();
                }}
                variant="contained"
                type="submit"
                size="small"
                sx={{
                  width: 150,
                  height: 40,
                  backgroundColor: "#000000",
                  color: "#FFF",
                  fontWeight: "500",
                  textTransform: "none",
                  fontSize: "20px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  ":hover": {
                    bgcolor: "#000000",
                    fontWeight: 800,
                  },
                }}
              >
                {loading ? (
                  <CircularProgress style={{ color: "#fff" }} size={20} />
                ) : (
                  "Verify"
                )}
              </Button>

              <Typography
                sx={{
                  fontFamily: "Poppins",
                  fontSize: "16px",
                  fontWeight: 300,
                  color: "#6C6A6A",
                }}
              >
                <a
                  href="#"
                  onClick={resetTimer}
                  style={{
                    color: "#000000",
                    textDecoration: "none",
                    fontWeight: "600",
                  }}
                >
                  Resend{" "}
                </a>{" "}
                in {timer} seconds
              </Typography>
            </div>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}
