import { React, useState } from "react";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import CountryCode from "../../data/constants/CountryCode.json";
import {
  Typography,
  TextField,
  Button,
  InputAdornment,
  IconButton,
  CircularProgress,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import "./index.css";
import CloseIcon from "@mui/icons-material/Close";
import { ApiRequestPost } from "../../data/network/services/ApiRequestPost";
import { useSnackbar } from "notistack";
import ResizeListener from "../../utility/ResizeListener";
import { validateEmail, validateName, validatePhoneNumber, validatePassword } from "../../utility/inputValidations";
import { WIDTH_LIMIT as limit } from "../../data/constants/Constants";

export default function SignUp({
  signUpOpen,
  setSignUpOpen,
  setLoginOpen,
  setStep,
  setOtpOpen,
  setSignUpEmail,
}) {
  //@props details
  // signUpOpen,setSignUpOpen to open and close the modal
  const { enqueueSnackbar } = useSnackbar();

  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [phone, setPhone] = useState("");
  const [loading, setLoading] = useState("");
  const [code, setCode] = useState("+91");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [error, setError] = useState({
    email: {
      status: "",
      message: "",
    },
    name: {
      status: "",
      message: "",
    },
    password: {
      status: "",
      message: "",
    },
    phone: {
      status: "",
      message: "",
    },
    confirmPassword: {
      status: "",
      message: "",
    },
    code: {
      status: "",
      message: "",
    },
  });
  const { width } = ResizeListener();

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: width > limit ? "30%" : "80%",
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
    borderRadius: "13px",
    overflowY: "scroll",
    maxHeight: "85%",
  };

  const handleClose = () => {
    setStep(1);
    setSignUpOpen(false);
  };

  const handleValidation = (event) => {
    let newErr = { ...error };
    if (event.target.id === "email") {
      if (email && !validateEmail(email)) {
        newErr.email.status = "error";
        newErr.email.message = "Enter a valid Email";
      } else {
        newErr.email.status = "";
        newErr.email.message = "";
      }
    } else if (event.target.id === "password") {
      if (password && !validatePassword(password)) {
        newErr.password.status = "error";
        newErr.password.message =
          "Choose a stronger password. Try a mix of letters, numbers, symbols and minimum of 8 characters";
      } else {
        newErr.password.status = "";
        newErr.password.message = "";
      }
    } else if (event.target.id === "phone") {
      if (phone && phone.length != 10) {
        newErr.phone.status = "error";
        newErr.phone.message = "Enter a valid phone number";
      } else {
        newErr.phone.status = "";
        newErr.phone.message = "";
      }
    } else if (event.target.id === "name") {
      if (name && !validateName(name)) {
        newErr.name.status = "error";
        newErr.name.message = "Enter a valid name";
      } else {
        {
          setName(name.trim());
          newErr.name.status = "";
          newErr.name.message = "";
        }
      }
    } else if (event.target.id === "confirmPassword") {
      if (confirmPassword && confirmPassword !== password) {
        newErr.confirmPassword.status = "error";
        newErr.confirmPassword.message = "Passwords Don't match";
      } else {
        newErr.confirmPassword.status = "";
        newErr.confirmPassword.message = "";
      }
    }

    setError(newErr);
  };

  const handleChange = (event) => {
    if (event.target.id === "email") {
      setEmail(event.target.value.trim());
      setSignUpEmail(event.target.value.trim());
    } else if (event.target.id === "name") {
      setName(event.target.value);
    } else if (event.target.id === "password") {
      setPassword(event.target.value.trim());
    } else if (event.target.id === "phone") {
      if (validatePhoneNumber(event.target.value)) {
        setPhone(event.target.value);
      }
    } else if (event.target.id === "confirmPassword") {
      setConfirmPassword(event.target.value.trim());
    } else if (event.target.name === "code") {
      setCode(event.target.value);
    }
  };

  const handleSubmit = () => {
    let newErr = { ...error };
    if (name && password && email && phone && confirmPassword && code) {
      if (
        !error.email.status &&
        !error.name.status &&
        !error.password.status &&
        !error.phone.status &&
        !error.confirmPassword.status &&
        !error.code.status
      ) {
        if (confirmPassword === password) {
          /// insert signUp intergration here

          const userData = {
            name,
            email,
            password,
            phone,
          };

          setLoading(true);
          ApiRequestPost.register(userData)
            .then((res) => {
              if (res.data.success) {
                setOtpOpen(true);
                setSignUpOpen(false);
                setStep(3);
              }
              enqueueSnackbar(
                res?.data?.success
                  ? "OTP Sent Successful "
                  : "Something went wrong",
                {
                  variant: res?.data?.success ? "success" : "error",
                  anchorOrigin: { vertical: "top", horizontal: "right" },
                }
              );
            })
            .catch((err) => {
              enqueueSnackbar(err.response.data.message, {
                variant: "error",
                anchorOrigin: { vertical: "top", horizontal: "right" },
              });
              console.log(err);
            })
            .finally(() => {
              setLoading(false);
            });
        } else {
          newErr.confirmPassword.status = "error";
          newErr.confirmPassword.message = "Passwords Don't match";
        }
      }
    } else {
      if (!name) {
        newErr.name = { status: "error", message: "Please enter the name" };
      }
      if (!password) {
        newErr.password = {
          status: "error",
          message: "Please enter the password",
        };
      }
      if (!email) {
        newErr.email = { status: "error", message: "Please enter the email" };
      }
      if (!phone) {
        newErr.phone = {
          status: "error",
          message: "Please enter the mobile number",
        };
      }
      if (!confirmPassword) {
        newErr.confirmPassword = {
          status: "error",
          message: "Please confirm password",
        };
      }
      if (!code) {
        newErr.code = {
          status: "error",
          message: "Please enter phone code",
        };
      }
    }
    setError(newErr);
  };

  return (
    <div>
      <Modal
        open={signUpOpen}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={signUpOpen}>
          <Box sx={style}>
            <IconButton
              style={{
                position: "absolute",
                top: "0",
                right: "0",
              }}
              onClick={() => setSignUpOpen(false)}
            >
              <CloseIcon fontSize="large" />
            </IconButton>
            <div className="model-title">
              <Typography
                sx={{
                  fontFamily: "Poppins",
                  fontSize: width > limit ? "40px" : "30px",
                  fontWeight: 600,
                  color: "#000000",
                }}
              >
                SIGN-UP
              </Typography>
            </div>
            <div className="signup-fields">
              <TextField
                id="name"
                label="Name"
                placeholder="Enter Your Name"
                size="medium"
                required
                type="text"
                sx={{ width: width > limit ? "80%" : "100%" }}
                autoFocus
                error={error.name.status === "error"}
                helperText={
                  error.name.status === "error" ? error.name.message : ""
                }
                onBlur={(e) => handleValidation(e)}
                onChange={(e) => handleChange(e)}
                value={name}
              />
              <div
                style={{
                  display: "flex",
                  width: width > limit ? "80%" : "100%",
                }}
              >
                <Box sx={{ width: width > limit ? "30%" : "35%" }}>
                  <FormControl fullWidth required>
                    <InputLabel id="demo-simple-select-label">Code</InputLabel>
                    <Select
                      id="code"
                      name="code"
                      value={code}
                      label="code"
                      onChange={(e) => handleChange(e)}
                      defaultValue={10}
                    >
                      {CountryCode.countries.map((data) => (
                        <MenuItem value={data?.code}>{data?.code}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Box>
                <TextField
                  id="phone"
                  label="Mobile Number"
                  placeholder="Mobile Number"
                  size="medium"
                  required
                  sx={{ width: width > limit ? "70%" : "65%" }}
                  error={error.phone.status === "error"}
                  helperText={
                    error.phone.status === "error" ? error.phone.message : ""
                  }
                  type="text"
                  onBlur={(e) => handleValidation(e)}
                  inputProps={{ maxLength: 10 }}
                  onChange={(e) => handleChange(e)}
                  value={phone}
                />
              </div>
              <TextField
                id="email"
                label="Email"
                placeholder="Enter Your Email"
                size="medium"
                required
                sx={{ width: width > limit ? "80%" : "100%" }}
                error={error.email.status === "error"}
                type="email"
                onBlur={(e) => handleValidation(e)}
                onChange={(e) => handleChange(e)}
                value={email}
                helperText={
                  error.email.status === "error" ? error.email.message : ""
                }
              />
              <TextField
                id="password"
                label="Password"
                placeholder="Enter Your Password"
                type={showPassword ? "text" : "password"}
                required
                sx={{ width: width > limit ? "80%" : "100%" }}
                error={error.password.status === "error"}
                helperText={
                  error.password.status === "error"
                    ? error.password.message
                    : ""
                }
                onBlur={(e) => handleValidation(e)}
                onChange={(e) => handleChange(e)}
                value={password}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                      >
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              <TextField
                id="confirmPassword"
                label="Confirm Password"
                placeholder="Confirm Password"
                type="password"
                required
                sx={{ width: width > limit ? "80%" : "100%" }}
                error={error.confirmPassword.status === "error"}
                helperText={
                  error.confirmPassword.status === "error"
                    ? error.confirmPassword.message
                    : ""
                }
                onBlur={(e) => handleValidation(e)}
                onChange={(e) => handleChange(e)}
                value={confirmPassword}
              />
              <Button
                onClick={() => {
                  handleSubmit();
                }}
                variant="contained"
                type="submit"
                size="small"
                sx={{
                  width: 150,
                  height: 40,
                  backgroundColor: "#000000",
                  color: "#FFF",
                  fontWeight: "500",
                  textTransform: "none",
                  fontSize: "20px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  ":hover": {
                    bgcolor: "#000000",
                    fontWeight: 800,
                  },
                }}
              >
                {loading ? (
                  <CircularProgress style={{ color: "#fff" }} size={20} />
                ) : (
                  "Sign-up"
                )}
              </Button>
              <Typography
                sx={{
                  fontFamily: "Poppins",
                  fontSize: "16px",
                  fontWeight: 300,
                  color: "#6C6A6A",
                  textAlign: "center",
                }}
              >
                Do you already have an account?{" "}
                <span
                  onClick={() => {
                    setLoginOpen(true);
                    setSignUpOpen(false);
                    setStep(1);
                  }}
                  style={{
                    textDecoration: "underline",
                    cursor: "pointer",
                    textAlign: "center",
                  }}
                >
                  Login Now
                </span>
              </Typography>
            </div>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}
