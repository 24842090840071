export const SUCCESS = "SUCCESS";
export const FAILURE = "FAILURE";

export const OTP_RESEND_TIME = 60;
export const RUPEE_SYMBOL = "₹";

export const experienceSubCategory = [
  { key: 1, name: "Day Outing", value: "DAYOUTING" },
  { key: 1, name: "Camping", value: "CAMPING" },
];

export const eventsSubCategory = [
  { key: 1, name: "School", value: "SCHOOL" },
  { key: 1, name: "Corporate", value: "CORPORATE" },
];

export const toursSubCategory = [
  { key: 1, name: "Internation", value: "INTERNATION" },
  { key: 1, name: "Domestic", value: "DOMESTIC" },
];

export const metaData = {
  metaTag: 'meta',
  headTag: 'head',
  title: 'title',
  description: 'description',
  keywords: 'keywords',
  ogTitle: 'og:title',
  ogDescription: 'og:description',
  ogUrl: 'og:url',
  content: 'content'
};
