import React from 'react';
import { connect } from "react-redux";
import { SIDEBAR_COLOR } from "../../resources/colorsManager";
import "./loader.css";

const CustomLoader = ( { size = 40 } ) => {
  return (
    <>
      <div
        className="spinner"
        style={ {
          borderTopColor: SIDEBAR_COLOR,
          height: size,
          width: size,
        } }
      />
    </>
  );
};

const mapStateToProps = ( state ) => {
  return {
    isLoading: state.fullScreenLoadingReducer.isLoading,
  };
};
export default connect( mapStateToProps )( CustomLoader );
