import React from "react";
import { CustomButton } from "../../components/customButton/CustomButton";
import { ApiRequestPost } from "../../../data/network/services/ApiRequestPost";
import { WanderinglamaColor } from "../../resources/assetsManager";

const PaymentTest = () => {
  const loadScript = (src) => {
    return new Promise((resolve) => {
      const script = document.createElement("script");
      script.src = src;
      script.onload = () => {
        resolve(true);
      };
      script.onerror = () => {
        resolve(false);
      };
      document.body.appendChild(script);
    });
  };

  const displayRazorpay = async () => {
    const payAmount = 100;
    const res = await loadScript(
      "https://checkout.razorpay.com/v1/checkout.js"
    );

    if (!res) {
      alert("Razorpay SDK failed to load. Are you online?");
      return;
    }

    // creating a new order
    ApiRequestPost.rpCreateOrder(payAmount)
      .then((result) => {
        console.log("Order created", result);

        const { amount, id: order_id, currency } = result?.data?.data;

        const options = {
          key: "rzp_test_3iJgaboou4AiY5", // Enter the Key ID generated from the Dashboard
          amount: amount.toString(),
          currency: currency,
          name: "Wandering Lama",
          description: "Test Transaction",
          image: { WanderinglamaColor },
          order_id: order_id,
          handler: async function (response) {
            const data = {
              orderCreationId: order_id,
              razorpayPaymentId: response.razorpay_payment_id,
              razorpayOrderId: response.razorpay_order_id,
              razorpaySignature: response.razorpay_signature,
            };

            ApiRequestPost.rpVerifyPayment(data)
              .then((resp) => {})
              .catch((err) => {
                console.log("error in verify payment", err);
              });
          },
          prefill: {
            name: "Wanderinglama",
            email: "tech@wanderinglama.com",
            contact: "8105810894",
          },
          notes: {
            address: "Wandering Lama India Private Limited",
          },
          theme: {
            color: "#489E00",
          },
        };

        const paymentObject = new window.Razorpay(options);
        paymentObject.open();
      })
      .catch((err) => {
        console.log("error in create order", err);
      });
  };

  return (
    <div
      style={{
        width: "100%",
        height: "700px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <CustomButton
        onClick={() => {
          displayRazorpay();
        }}
        styles={{
          border: 0,
          width: 200,
          borderRadius: 10,
          padding: 20,
        }}
        textStyles={{
          fontWeight: "bold",
          fontSize: "20px",
        }}
        title="Buy Now"
      />
    </div>
  );
};

export default PaymentTest;
