import React, { useState, useRef, useEffect } from "react";
import { CustomButton } from "../../components/customButton/CustomButton";
import "./index.css";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { TextField } from "@mui/material";
import dayjs from "dayjs";
import ResizeListener from "../../../utility/ResizeListener";
import { DONE_ICON } from "../../resources/assetsManager";
import { useLocation, useNavigate } from "react-router";
import moment from "moment";
import { ApiRequestPost } from "../../../data/network/services/ApiRequestPost";
import { WanderinglamaColor } from "../../resources/assetsManager";
import {
  SS_EMAIL,
  SS_MOBILE_NUMBER,
  SS_USERID,
  SS_USER_NAME,
} from "../../resources/secureStorageManager";
import secureStorage from "../../../utility/secureStorage";
import { validatePhoneNumber } from "../../../utility/inputValidations";
import { useSnackbar } from "notistack";
import PaymentSuccess from "../../modals/PaymentSuccess";
import PaymentFailure from "../../modals/PaymentFailure";
import { Autoplay } from "swiper";
import "swiper/css";
import "swiper/css/autoplay";
import { Swiper, SwiperSlide } from "swiper/react";
import { WIDTH_LIMIT } from "../../../data/constants/Constants";

const BookNow = () => {
  const { width } = ResizeListener();
  const { enqueueSnackbar } = useSnackbar();
  let location = useLocation();
  const navigate = useNavigate();

  const [data, setData] = useState({});
  const [paymentStatus, setPaymentStatus] = useState("CLOSE");
  const [bookingNo, setBookingNo] = useState("");
  const [paymentDetails, setPaymentDetails] = useState("");
  const [step, setStep] = useState(1);

  const [error, setError] = useState({
    email: {
      status: "",
      message: "",
    },
    name: {
      status: "",
      message: "",
    },
    checkInOut: {
      status: "",
      message: "true",
    },
    phone: {
      status: "",
      message: "",
    },
    noOfPeople: {
      status: "",
      message: "",
    },
    code: {
      status: "",
      message: "",
    },
    packageType: {
      status: "",
      message: "",
    },
    checkIn: {
      status: "",
      message: "",
    },
    checkOut: {
      status: "",
      message: "",
    },
  });
  const [step1Height, setStep1Height] = useState("auto");
  const [step2Height, setStep2Height] = useState("0");
  const step1Ref = useRef(null);
  const step2Ref = useRef(null);
  const [changeStep, setChangeStep] = useState(false);
  const [packageArr, setPackageArr] = useState([]);
  const [packageTotal, setPackageTotal] = useState(0);
  const [totalPay, setTotalPay] = useState(0);
  const [totalPayPerDay, setTotalPayPerDay] = useState(0);
  const [noDays, setNoDays] = useState(1);
  const [payDisabled, setPayDisabled] = useState(true);
  const [loading, setLoading] = useState(false);

  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [checkIn, setCheckIn] = useState(null);
  const [checkOut, setCheckOut] = useState(null);

  useEffect(() => {
    const email = secureStorage.getItem(SS_EMAIL);
    const name = secureStorage.getItem(SS_USER_NAME);
    const phone = secureStorage.getItem(SS_MOBILE_NUMBER);

    setName(name ? name : "");
    setPhone(phone ? phone : "");
    setEmail(email ? email : "");
  }, []);

  useEffect(() => {
    setData(location.state);
    setPackageArr(location.state?.packages);
    calculateTotal();
  }, [location.state]);

  useEffect(() => {
    if (
      name.length > 3 &&
      phone.toString().length &&
      email.length > 3 &&
      parseInt(totalPay) > 0 &&
      step > 1
    ) {
      setPayDisabled(false);
    } else {
      setPayDisabled(true);
    }
  }, [name, phone, email, checkIn, checkOut, totalPay, step]);

  useEffect(() => {
    // Update the height when the step changes
    if (changeStep) {
      setStep1Height(step === 1 ? `${step1Ref.current.scrollHeight}px` : "0");
      setStep2Height(step === 2 ? `${step2Ref.current.scrollHeight}px` : "0");
    } else {
      setTimeout(() => {
        setStep1Height(step === 1 ? `${step1Ref.current.scrollHeight}px` : "0");
        setStep2Height(step === 2 ? `${step2Ref.current.scrollHeight}px` : "0");
      }, 500);
    }
  }, [step]);

  const handleChange = (event) => {
    if (event.target.id === "email") {
      setEmail(event.target.value.trim());
    } else if (event.target.id === "name") {
      setName(event.target.value);
    } else if (event.target.id === "phone") {
      if (validatePhoneNumber(event.target.value)) {
        setPhone(event.target.value);
      }
    } else if (event.target.id === "message") {
      setMessage(event.target.value);
    }
  };

  const handleDateValidation = (field, value) => {
    let newErr = { ...error };
    const parsedDate = dayjs(value);
    if (!parsedDate.isValid()) {
      newErr[field].status = "error";
      newErr[field].message = "Invalid Date";
      return;
    }
    if (parsedDate.isBefore(dayjs(), "day")) {
      newErr[field].status = "error";
      newErr[field].message = "Cannot be passed Date";
      return;
    }
    if (field === "checkIn" && checkOut) {
      if (dayjs(checkOut).toDate() > dayjs(value).toDate()) {
        newErr.checkOut.status = "";
        newErr.checkOut.message = "";
      } else {
        newErr.checkOut.status = "error";
        newErr.checkOut.message =
          "Check-Out cannot be lesser than Check-In date";
      }
      setError(newErr);
    }
    if (field === "checkOut" && checkIn) {
      if (dayjs(value).toDate() > dayjs(checkIn).toDate()) {
        newErr.checkOut.status = "";
        newErr.checkOut.message = "";
      } else {
        newErr.checkOut.status = "error";
        newErr.checkOut.message =
          "Check-Out cannot be lesser than Check-In date";
      }
      setError(newErr);
    }
  };

  const BillItems = ({ item }) => {
    const totalPackagePrice =
      item?.currentPrice * item.adults +
      (item?.currentPrice / 2) * item.children;

    return (
      <>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <div style={{ width: "60%" }}>
            <p style={{ fontSize: 16 }}>{item?.name}:</p>
          </div>
          <div style={{ width: "40%" }}>
            <p
              style={{
                fontSize: 16,
                fontWeight: "bold",
                textAlign: "right",
              }}
            >
              ₹{totalPackagePrice.toFixed(2)}
            </p>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: 20,
          }}
        >
          <div style={{ width: "60%" }}>
            <p style={{ fontSize: 14 }}>{item?.gstPercentage}% GST</p>
          </div>
          <div style={{ width: "40%" }}>
            <p
              style={{
                fontSize: 16,
                fontWeight: "bold",
                textAlign: "right",
              }}
            >
              ₹{(totalPackagePrice * (item?.gstPercentage / 100)).toFixed(2)}
            </p>
          </div>
        </div>
      </>
    );
  };

  const PackageDetails = ({ item, key }) => {
    const index = packageArr?.findIndex((obj) => obj._id === item._id);

    if (packageArr[index].adults === undefined) packageArr[index].adults = 0;
    if (packageArr[index].children === undefined)
      packageArr[index].children = 0;

    return (
      <div
        key={key}
        style={{
          padding: width > WIDTH_LIMIT ? 30 : 20,
          boxShadow:
            width > WIDTH_LIMIT
              ? "0 1px 2px 0 rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.19)"
              : "",
          borderRadius: 5,
          marginTop: 20,
          background: "#fff",
          border: width > WIDTH_LIMIT ? "0px" : "1px solid #ccc",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: 20,
          }}
        >
          <div style={{ width: "40%" }}>
            <p style={{ fontWeight: "500", fontSize: 20 }}>
              {packageArr[index]?.name}
            </p>
          </div>
        </div>

        <hr />

        <div
          style={{
            display: width > WIDTH_LIMIT ? "flex" : "",
            justifyContent: width > WIDTH_LIMIT ? "space-between" : "",
            marginTop: 20,
          }}
        >
          <div style={{ width: width > WIDTH_LIMIT ? "40%" : "100%" }}>
            <p
              style={{
                fontSize: width > WIDTH_LIMIT ? 18 : 16,
                color: "#000",
                fontWeight: "500",
              }}
            >
              Timing:
            </p>
          </div>
          <div style={{ width: width > WIDTH_LIMIT ? "60%" : "100%" }}>
            <p
              style={{
                fontWeight: "500",
                fontSize: 18,
              }}
            >
              {moment(packageArr[index]?.checkIn).format("hh:mm A")} to{" "}
              {moment(packageArr[index]?.checkOut).format("hh:mm A")}
            </p>
          </div>
        </div>

        <div
          style={{
            display: width > WIDTH_LIMIT ? "flex" : "",
            justifyContent: width > WIDTH_LIMIT ? "space-between" : "",
            marginTop: 20,
            alignItems: "center",
            marginBottom: 20,
          }}
        >
          <div style={{ width: width > WIDTH_LIMIT ? "40%" : "100%" }}>
            <p
              style={{
                fontSize: width > WIDTH_LIMIT ? 18 : 16,
                color: "#000",
                fontWeight: "500",
              }}
            >
              Number of Adults:
            </p>
            <p
              style={{
                fontSize: 12,
                color: "#525252",
              }}
            >
              age above 16 years
            </p>
          </div>
          <div
            style={{
              width: width > WIDTH_LIMIT ? "60%" : "100%",
              display: "flex",
              alignItems: "center",
              marginTop: width > WIDTH_LIMIT ? 0 : 10,
            }}
          >
            <div
              onClick={() => {
                if (packageArr[index].adults > 0) {
                  let tempArr = [...packageArr];
                  tempArr[index].adults = tempArr[index].adults - 1;
                  setPackageArr(tempArr);
                  calculateTotal();
                }
              }}
              style={{
                display: "flex",
                width: 50,
                height: 50,
                border: "1px solid #8E8E8E",
                borderRadius: 5,
                justifyContent: "center",
                alignItems: "center",
                cursor: "pointer",
              }}
            >
              <p
                style={{
                  color: "#8E8E8E",
                  fontSize: width > WIDTH_LIMIT ? 30 : 25,
                  margin: 0,
                }}
              >
                -
              </p>
            </div>
            <div
              style={{
                display: "flex",
                width: 50,
                height: 50,
                border: "1px solid #8E8E8E",
                borderRadius: 5,
                justifyContent: "center",
                alignItems: "center",
                marginLeft: 10,
              }}
            >
              <p
                style={{
                  color: "#555",
                  fontWeight: "500",
                  fontSize: width > WIDTH_LIMIT ? 20 : 18,
                  margin: 0,
                }}
              >
                {packageArr[index].adults}
              </p>
            </div>
            <div
              onClick={() => {
                if (
                  packageArr[index].adults + packageArr[index].children <
                  (data?.kind === "camping" ? 20 : 50)
                ) {
                  let tempArr = [...packageArr];
                  tempArr[index].adults = tempArr[index].adults + 1;
                  setPackageArr(tempArr);
                  calculateTotal();
                } else {
                  handleMaxPeople(data.kind + "/" + data.slug);
                }
              }}
              style={{
                display: "flex",
                width: 50,
                height: 50,
                border: "1px solid #8E8E8E",
                borderRadius: 5,
                justifyContent: "center",
                alignItems: "center",
                marginLeft: 10,
                cursor: "pointer",
              }}
            >
              <p
                style={{
                  color: "#8E8E8E",
                  fontSize: width > WIDTH_LIMIT ? 30 : 25,
                  margin: 0,
                }}
              >
                +
              </p>
            </div>

            <p
              style={{
                fontSize: width > WIDTH_LIMIT ? 30 : 25,
                margin: "0px 20px",
              }}
            >
              x
            </p>
            <p
              style={{
                fontWeight: "500",
                fontSize: width > WIDTH_LIMIT ? 25 : 22,
              }}
            >
              ₹
              {(
                packageArr[index]?.currentPrice * packageArr[index].adults
              ).toFixed(2)}
            </p>
          </div>
        </div>
        <div
          style={{
            display: width > WIDTH_LIMIT ? "flex" : "",
            justifyContent: width > WIDTH_LIMIT ? "space-between" : "",
            marginTop: 20,
            alignItems: "center",
            marginBottom: 20,
          }}
        >
          <div style={{ width: width > WIDTH_LIMIT ? "40%" : "100%" }}>
            <p
              style={{
                fontSize: width > WIDTH_LIMIT ? 18 : 16,
                color: "#000",
                fontWeight: "500",
              }}
            >
              Number of Childrens:
            </p>
            <p
              style={{
                fontSize: 12,
                color: "#525252",
              }}
            >
              age between 6 - 16 years
            </p>
          </div>
          <div
            style={{
              width: width > WIDTH_LIMIT ? "60%" : "100%",
              display: "flex",
              alignItems: "center",
              marginTop: width > WIDTH_LIMIT ? 0 : 10,
            }}
          >
            <div
              onClick={() => {
                if (packageArr[index].children > 0) {
                  let tempArr = [...packageArr];
                  tempArr[index].children = tempArr[index].children - 1;
                  setPackageArr(tempArr);
                  calculateTotal();
                }
              }}
              style={{
                display: "flex",
                width: 50,
                height: 50,
                border: "1px solid #8E8E8E",
                borderRadius: 5,
                justifyContent: "center",
                alignItems: "center",
                cursor: "pointer",
              }}
            >
              <p
                style={{
                  color: "#8E8E8E",
                  fontSize: width > WIDTH_LIMIT ? 30 : 25,
                  margin: 0,
                }}
              >
                -
              </p>
            </div>
            <div
              style={{
                display: "flex",
                width: 50,
                height: 50,
                border: "1px solid #8E8E8E",
                borderRadius: 5,
                justifyContent: "center",
                alignItems: "center",
                marginLeft: 10,
              }}
            >
              <p
                style={{
                  color: "#555",
                  fontWeight: "500",
                  fontSize: width > WIDTH_LIMIT ? 20 : 18,
                  margin: 0,
                }}
              >
                {packageArr[index].children}
              </p>
            </div>
            <div
              onClick={() => {
                if (
                  packageArr[index].adults + packageArr[index].children <
                  (data?.kind === "camping" ? 20 : 50)
                ) {
                  let tempArr = [...packageArr];
                  tempArr[index].children = tempArr[index].children + 1;
                  setPackageArr(tempArr);
                  calculateTotal();
                } else {
                  handleMaxPeople();
                }
              }}
              style={{
                display: "flex",
                width: 50,
                height: 50,
                border: "1px solid #8E8E8E",
                borderRadius: 5,
                justifyContent: "center",
                alignItems: "center",
                marginLeft: 10,
                cursor: "pointer",
              }}
            >
              <p
                style={{
                  color: "#8E8E8E",
                  fontSize: width > WIDTH_LIMIT ? 30 : 25,
                  margin: 0,
                }}
              >
                +
              </p>
            </div>
            <p
              style={{
                fontSize: width > WIDTH_LIMIT ? 30 : 25,
                margin: "0px 20px",
              }}
            >
              x
            </p>
            <p
              style={{
                fontWeight: "500",
                fontSize: width > WIDTH_LIMIT ? 25 : 22,
              }}
            >
              ₹
              {(
                (packageArr[index]?.currentPrice / 2) *
                packageArr[index].children
              ).toFixed(2)}
            </p>
          </div>
        </div>

        <hr />

        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginTop: width > WIDTH_LIMIT ? 20 : 10,
          }}
        >
          <div style={{ width: "40%" }}>
            <p
              style={{
                fontSize: width > WIDTH_LIMIT ? 18 : 16,
                color: "#525252",
                fontWeight: "500",
              }}
            >
              Total:
            </p>
          </div>
          <div style={{ width: "60%" }}>
            <p
              style={{
                fontWeight: "500",
                fontSize: width > WIDTH_LIMIT ? 30 : 25,
                textAlign: width > WIDTH_LIMIT ? "left" : "right",
              }}
            >
              ₹
              {(
                packageArr[index]?.currentPrice * packageArr[index].adults +
                (packageArr[index]?.currentPrice / 2) *
                  packageArr[index].children
              ).toFixed(2)}
            </p>
          </div>
        </div>
      </div>
    );
  };

  const handleNext = () => {
    if (!packageTotal) {
      enqueueSnackbar("Select No. of adults before going next", {
        variant: "error",
        anchorOrigin: { vertical: "top", horizontal: "right" },
      });
    } else if (!checkIn) {
      enqueueSnackbar("Select check-in before going next", {
        variant: "error",
        anchorOrigin: { vertical: "top", horizontal: "right" },
      });
    } else if (data?.kind === "camping" && !checkOut) {
      enqueueSnackbar("Select check-out before going next", {
        variant: "error",
        anchorOrigin: { vertical: "top", horizontal: "right" },
      });
    } else {
      if (width > WIDTH_LIMIT) {
        setChangeStep(true);
        window.scrollTo({ top: 0, behavior: "smooth" });
        setStep(2);
      } else {
        window.scrollTo({ top: 0, behavior: "smooth" });
        setStep(2);
      }
    }
  };

  const handleNext2 = () => {
    if (name && phone && email) {
      window.scrollTo({ top: 0, behavior: "smooth" });
      setStep(3);
    } else {
      enqueueSnackbar("Fill All Mandatory Details", {
        variant: "error",
        anchorOrigin: { vertical: "top", horizontal: "right" },
      });
    }
  };

  const calculateTotal = () => {
    let packageTotal = 0;

    packageArr.map((item) => {
      let subPackageTotal = 0;

      if (parseInt(item?.adults) > 0) {
        subPackageTotal =
          item?.currentPrice * item.adults +
          (item?.currentPrice / 2) * item.children;

        subPackageTotal =
          subPackageTotal + subPackageTotal * (item?.gstPercentage / 100);

        packageTotal = packageTotal + subPackageTotal;
      }
    });
    const totalPayable = packageTotal;
    setPackageTotal(packageTotal);

    if (data?.kind === "camping") {
      const fromDate = new Date(checkIn?.$d);
      const toDate = new Date(checkOut?.$d);
      const timeDifference = toDate.getTime() - fromDate.getTime();
      const daysDifference = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
      console.log(
        "Number of days between the date range (inclusive):",
        daysDifference
      );
      setTotalPayPerDay(totalPayable);
      setNoDays(daysDifference);
      setTotalPay(totalPayable * daysDifference);
    } else {
      setTotalPay(totalPayable);
    }
  };

  const handleMaxPeople = (path) => {
    enqueueSnackbar(
      `Booking can be made for max of ${
        data.kind === "camping" ? "20" : "50"
      } for each package, more than ${
        data.kind === "camping" ? "20" : "50"
      } pax please Send Enquiry`,
      {
        variant: "warning",
        anchorOrigin: { vertical: "top", horizontal: "center" },
        action: () => (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
            }}
          >
            <CustomButton
              onClick={() => {
                setTimeout(() => {
                  navigate("/property-details/" + path, {
                    state: { openEnquiry: true },
                  });
                }, 10);
              }}
              styles={{
                background: "transparent",
                border: "2px solid #fff",
                borderRadius: 5,
              }}
              textStyles={{ fontWeight: "bold", color: "#fff" }}
              title="Send Enquiry"
            />
          </div>
        ),
      }
    );
  };

  useEffect(() => {
    calculateTotal();
  }, [checkIn, checkOut]);

  const loadScript = (src) => {
    return new Promise((resolve) => {
      const script = document.createElement("script");
      script.src = src;
      script.onload = () => {
        resolve(true);
      };
      script.onerror = () => {
        resolve(false);
      };
      document.body.appendChild(script);
    });
  };

  const buildPackageDetails = (packageDetails) => {
    let newPackageDetails = [];
    packageDetails?.packages?.map((item) => {
      if (item?.adults > 0) {
        const pkgTotal =
          item?.currentPrice * item.adults +
          (item?.currentPrice / 2) * item.children;

        const newItem = {
          packageID: item?._id,
          noOfAdults: item?.adults,
          noOfChildren: item?.children,
          noOfInfants: 0,
          packageTotalAmount: pkgTotal + pkgTotal * (item?.gstPercentage / 100),
        };

        newPackageDetails.push(newItem);
      }
    });

    return newPackageDetails;
  };

  const displayRazorpay = async () => {
    setLoading(true);
    const userId = await secureStorage.getItem(SS_USERID);

    let addBookingObj = {
      name: name,
      email: email,
      phone: phone,
      code: "+91",
      checkIn: moment(checkIn?.$d).format("YYYY-MM-DD"),
      checkOut: moment(checkOut?.$d).format("YYYY-MM-DD"),
      productType: data?.kind,
      packageDetails: buildPackageDetails(data),
      message: message || "booking",
      totalAmount: totalPay,
      user: userId,
    };

    if (data?.kind === "dayOuting") {
      addBookingObj = {
        ...addBookingObj,
        dayOuting: data?._id,
      };
    } else if (data?.kind === "camping") {
      addBookingObj = {
        ...addBookingObj,
        camping: data?._id,
      };
    } else if (data?.kind === "stay") {
      addBookingObj = {
        ...addBookingObj,
        stay: data?._id,
      };
    }

    const res = await loadScript(
      "https://checkout.razorpay.com/v1/checkout.js"
    );

    if (!res) {
      alert("Razorpay SDK failed to load. Are you online?");
      return;
    }

    // creating a new order

    ApiRequestPost.addBooking(addBookingObj)
      .then((response) => {
        if (response?.data?.data) {
          ApiRequestPost.rpCreateOrder(response?.data?.data)
            .then((result) => {
              const { amount, id: order_id, currency } = result?.data?.data;
              let isXButton = true;

              const options = {
                // key: "rzp_test_3iJgaboou4AiY5", // Enter the Key ID generated from the Dashboard
                key: "rzp_live_qW6HVi5XXdFvhT", // Enter the Key ID generated from the Dashboard
                amount: amount.toString(),
                currency: currency,
                retry: false,
                name: "Wandering Lama",
                description: "Test Transaction",
                image: { WanderinglamaColor },
                order_id: order_id,
                handler: async function (response) {
                  const data = {
                    orderCreationId: order_id,
                    razorpayPaymentId: response.razorpay_payment_id,
                    razorpayOrderId: response.razorpay_order_id,
                    razorpaySignature: response.razorpay_signature,
                  };

                  setPaymentDetails(data);

                  ApiRequestPost.rpVerifyPayment(data)
                    .then((resp) => {
                      setPaymentStatus("SUCCESS");
                      setBookingNo(resp?.data?.data?.bookingNo);
                    })
                    .catch((err) => {
                      setPaymentStatus("FAILURE");
                      console.log("error in verify payment", err);
                    });
                },
                modal: {
                  ondismiss: function () {
                    console.log("isXButton", isXButton);
                    if (isXButton) {
                      setLoading(true);

                      ApiRequestPost.updatePaymentStatus(order_id)
                        .then((res) => {
                          console.log("resupdatePaymentStatus", res);
                          setLoading(false);
                        })
                        .catch((e) => {
                          console.log("eupdatePaymentStatus", e);
                          setLoading(false);
                        });
                    }
                  },
                },
                prefill: {
                  name: "Wanderinglama",
                  email: email,
                  contact: phone,
                },
                notes: {
                  address: "Wandering Lama India Private Limited",
                },
                theme: {
                  color: "#489E00",
                },
              };

              const paymentObject = new window.Razorpay(options);

              paymentObject.on("payment.failed", function (response) {
                setPaymentStatus("FAILURE");
                isXButton = false;
                // Prevent the Razorpay SDK from retrying the payment.
                response.preventDefault();
              });

              paymentObject.open();
              setLoading(false);
            })
            .catch((err) => {
              console.log("error in create order", err);
              setLoading(false);
            });
        } else {
          enqueueSnackbar("Something went wrong!", {
            variant: "error",
            anchorOrigin: { vertical: "top", horizontal: "right" },
          });
        }
      })
      .catch((err) => {
        setLoading(false);
        enqueueSnackbar("Something went wrong!", {
          variant: "error",
          anchorOrigin: { vertical: "top", horizontal: "right" },
        });
      });
  };

  return (
    <div
      style={{
        display: width > WIDTH_LIMIT ? "flex" : "",
        flexDirection: "column",
        width: "100%",
        padding: width > WIDTH_LIMIT ? "30px 60px" : 25,
      }}
    >
      {width > WIDTH_LIMIT && (
        <p
          style={{
            fontWeight: "bold",
            fontSize: 25,
            textAlign: "center",
            marginBottom: 20,
          }}
        >
          Book Now
        </p>
      )}

      <div
        style={{
          width: "100%",
          display: width > WIDTH_LIMIT ? "flex" : "",
          justifyContent: width > WIDTH_LIMIT ? "space-between" : "",
        }}
      >
        <div
          style={{
            width: width > WIDTH_LIMIT ? "68%" : "100%",
            marginRight: width > WIDTH_LIMIT ? "2%" : 0,
          }}
        >
          {(width > WIDTH_LIMIT || (width < WIDTH_LIMIT && step === 1)) && (
            <div
              style={{
                borderRadius: 5,
                border: width > WIDTH_LIMIT ? "1px solid #8E8E8E" : "0px",
                background: width > WIDTH_LIMIT ? "#FAFCFF" : "#fff",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  padding: "20px",
                  background: step === 1 ? "#000" : "#FAFCFF",
                  borderTopLeftRadius: 5,
                  borderTopRightRadius: 5,
                  borderBottomLeftRadius:
                    width > WIDTH_LIMIT && step === 1 ? 0 : 5,
                  borderBottomRightRadius:
                    width > WIDTH_LIMIT && step === 1 ? 0 : 5,
                  alignItems: "center",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: width > WIDTH_LIMIT ? "" : "center",
                  }}
                >
                  <p
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      background: step === 1 ? "#fff" : "#000",
                      width: 30,
                      height: 30,
                      padding: 5,
                      borderRadius: 50,
                      fontWeight: "bold",
                      color: step === 1 ? "#000" : "#fff",
                    }}
                  >
                    1
                  </p>

                  <p
                    style={{
                      marginLeft: 15,
                      color: step === 1 ? "#fff" : "#000",
                      fontWeight: "500",
                      fontSize: width > WIDTH_LIMIT ? 18 : 16,
                    }}
                  >
                    Select Package
                  </p>
                  {step === 2 && (
                    <img
                      src={DONE_ICON}
                      style={{ width: 30, height: 30, marginLeft: 15 }}
                    />
                  )}
                </div>
                {step === 2 && (
                  <div
                    onClick={() => {
                      setChangeStep(true);
                      setStep(1);
                    }}
                    style={{ cursor: "pointer" }}
                  >
                    <p style={{ fontWeight: "bold", color: "#60442E" }}>Edit</p>
                  </div>
                )}
              </div>

              <div
                class="notworking"
                ref={step1Ref} // Attach the ref to Step 1 div
                style={{
                  // Add height and transition properties
                  height: width > WIDTH_LIMIT ? step1Height : "100%",
                  overflow: "hidden",
                  transition: "height 1s ease",
                }}
              >
                <div style={{ padding: width > WIDTH_LIMIT ? 30 : 0 }}>
                  <div
                    style={{
                      padding: width > WIDTH_LIMIT ? 15 : "15px 0px ",
                      boxShadow:
                        width > WIDTH_LIMIT
                          ? "0 1px 2px 0 rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.19)"
                          : "",
                      borderRadius: 5,
                      background: "#fff",
                    }}
                  >
                    {/* <div
                    style={{
                      width: "100%",
                      height: 250,
                      background: "#ccc",
                      borderRadius: 5,
                    }}
                  >

                  </div> */}

                    <div
                      style={{
                        height: "auto",
                        width: "100%",
                        height: 250,
                        //paddingBottom: 20,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        borderRadius: 5,
                      }}
                    >
                      {data?.photo &&
                      data?.photo.length > 0 &&
                      data?.photo[0] !== "no-photo.jpg" ? (
                        <Swiper
                          style={{
                            width: "100%",
                            height: "100%",
                          }}
                          modules={[Autoplay]}
                          spaceBetween={30}
                          slidesPerView={1}
                          autoplay
                        >
                          {data?.photo?.map((item, index) => (
                            <SwiperSlide key={index}>
                              <img
                                style={{
                                  width: "100%",
                                  height: "100%",
                                  objectFit: "cover",
                                  borderRadius: 5,
                                }}
                                src={item}
                              />
                            </SwiperSlide>
                          ))}
                        </Swiper>
                      ) : (
                        <p
                          style={{
                            fontSize: width > WIDTH_LIMIT ? 30 : 20,
                            color: "#000",
                          }}
                        >
                          No Images
                        </p>
                      )}
                    </div>

                    <p
                      style={{
                        fontWeight: "500",
                        fontSize: width > WIDTH_LIMIT ? 20 : 16,
                        marginTop: 15,
                      }}
                    >
                      {data?.name}
                    </p>
                    <p
                      style={{
                        fontSize: width > WIDTH_LIMIT ? 16 : 14,
                        marginTop: 5,
                      }}
                    >
                      {data?.location?.formattedAddress}
                    </p>
                  </div>

                  <div
                    style={{
                      marginTop: width > WIDTH_LIMIT ? 20 : 0,
                      padding: width > WIDTH_LIMIT ? 15 : "0px",
                      boxShadow:
                        width > WIDTH_LIMIT
                          ? "0 1px 2px 0 rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.19)"
                          : "",
                      borderRadius: 5,
                      background: "#fff",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <div style={{ width: "48%" }}>
                          <p
                            style={{
                              marginBottom: 10,
                              fontSize: width > WIDTH_LIMIT ? 18 : 15,
                            }}
                          >
                            Check-In <span style={{ color: "red" }}>*</span>
                          </p>
                          <DesktopDatePicker
                            id="checkIn"
                            disablePast
                            // label="Check In"
                            inputFormat="DD/MM/YYYY"
                            value={checkIn}
                            onChange={(e) => {
                              let newErr = { ...error };
                              newErr.checkIn.status = "";
                              newErr.checkIn.message = "";
                              setError(newErr);
                              setCheckIn(e);
                              handleDateValidation("checkIn", e);
                            }}
                            sx={{ width: "100%", border: "1px solid #ccc" }}
                            PaperProps={{
                              sx: {
                                "& .MuiPickersDay-root": {
                                  "&.Mui-selected": {
                                    backgroundColor: "#82C34C",
                                  },
                                },
                                "&MuiPickersDay-dayWithMargin": {
                                  backgroundColor: "#82C34C",
                                },
                              },
                            }}
                            renderInput={(params) => (
                              <TextField
                                variant="outlined"
                                {...params}
                                required
                                sx={{
                                  width: "100%",
                                  border: "1px solid #888",
                                  borderRadius: 1,
                                  //marginTop: width > limit ? "0px" : "30px",
                                }}
                                error={error.checkIn.status === "error"}
                                helperText={
                                  error.checkIn.status === "error"
                                    ? error.checkIn.message
                                    : ""
                                }
                              />
                            )}
                          />
                        </div>
                      </LocalizationProvider>
                      {data?.kind === "camping" && (
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <div style={{ width: "48%" }}>
                            <p
                              style={{
                                marginBottom: 10,
                                fontSize: width > WIDTH_LIMIT ? 18 : 15,
                              }}
                            >
                              Check-Out <span style={{ color: "red" }}>*</span>
                            </p>
                            <DesktopDatePicker
                              id="checkOut"
                              disablePast
                              inputFormat="DD/MM/YYYY"
                              value={checkOut}
                              minDate={checkIn || dayjs()}
                              onChange={(e) => {
                                let newErr = { ...error };
                                newErr.checkOut.status = "";
                                newErr.checkOut.message = "";
                                setError(newErr);
                                setCheckOut(e);
                                handleDateValidation("checkOut", e);
                              }}
                              sx={{ width: "100%" }}
                              PaperProps={{
                                sx: {
                                  "& .MuiPickersDay-root": {
                                    "&.Mui-selected": {
                                      backgroundColor: "#82C34C",
                                    },
                                  },
                                  "&MuiPickersDay-dayWithMargin": {
                                    backgroundColor: "#82C34C",
                                  },
                                },
                              }}
                              renderInput={(params) => (
                                <TextField
                                  variant="outlined"
                                  {...params}
                                  required
                                  sx={{
                                    width: "100%",
                                    border: "1px solid #888",
                                    borderRadius: 1,
                                  }}
                                  error={error.checkOut.status === "error"}
                                  helperText={
                                    error.checkOut.status === "error"
                                      ? error.checkOut.message
                                      : ""
                                  }
                                />
                              )}
                            />
                          </div>
                        </LocalizationProvider>
                      )}
                    </div>
                  </div>

                  {data?.packages?.map((item, index) => (
                    <PackageDetails
                      key={index}
                      packageArr={packageArr}
                      setPackageArr={setPackageArr}
                      item={item}
                    />
                  ))}

                  <div
                    style={{
                      display: width > WIDTH_LIMIT ? "flex" : "",
                      justifyContent:
                        width > WIDTH_LIMIT ? "space-between" : "",
                      alignItems: "center",
                      padding: width > WIDTH_LIMIT ? "15px 30px" : 0,
                      boxShadow:
                        width > WIDTH_LIMIT
                          ? "0 1px 2px 0 rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.19)"
                          : "",
                      borderRadius: 5,
                      marginTop: 20,
                      background: "#fff",
                    }}
                  >
                    <p>
                      Booking confirmation email will sent to{" "}
                      <span style={{ fontWeight: "500" }}>'{email}'</span>
                    </p>

                    <CustomButton
                      backgroundColor="#60442E"
                      onClick={() => {
                        handleNext();
                      }}
                      styles={{
                        border: 0,
                        borderRadius: 5,
                        marginTop: width > WIDTH_LIMIT ? 0 : 15,
                        width: width > WIDTH_LIMIT ? 150 : "100%",
                      }}
                      textStyles={{
                        fontWeight: "500",
                        fontSize: width > WIDTH_LIMIT ? 18 : 16,
                      }}
                      title="Next"
                    />
                  </div>
                </div>
              </div>
            </div>
          )}

          {(width > WIDTH_LIMIT || (width < WIDTH_LIMIT && step === 2)) && (
            <div
              style={{
                borderRadius: 5,
                border: width > WIDTH_LIMIT ? "1px solid #8E8E8E" : "0px",
                background: width > WIDTH_LIMIT ? "#FAFCFF" : "#fff",
                marginTop: width > WIDTH_LIMIT ? 15 : 0,
              }}
            >
              <div
                style={{
                  display: "flex",
                  padding: "20px",
                  background: step === 2 ? "#000" : "#FAFCFF",
                  borderTopLeftRadius: 5,
                  borderTopRightRadius: 5,
                  borderBottomLeftRadius:
                    width > WIDTH_LIMIT && step === 2 ? 0 : 5,
                  borderBottomRightRadius:
                    width > WIDTH_LIMIT && step === 2 ? 0 : 5,
                  alignItems: "center",
                }}
              >
                <p
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    background: step === 2 ? "#fff" : "#000",
                    width: 30,
                    height: 30,
                    padding: 5,
                    borderRadius: 50,
                    fontWeight: "bold",
                    color: step === 2 ? "#000" : "#fff",
                  }}
                >
                  2
                </p>
                <p
                  style={{
                    marginLeft: 15,
                    color: step === 2 ? "#fff" : "#000",
                    fontWeight: "500",
                    fontSize: width > WIDTH_LIMIT ? 18 : 16,
                  }}
                >
                  Personal details
                </p>
              </div>

              <div
                ref={step2Ref} // Attach the ref to Step 1 div
                style={{
                  // Add height and transition properties
                  height: width > WIDTH_LIMIT ? step2Height : "100%",
                  overflow: "hidden",
                  transition: "height 1s ease",
                }}
              >
                <div
                  style={{
                    padding: width > WIDTH_LIMIT ? 30 : 0,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <div
                    style={{
                      width: "100%",
                      padding: width > WIDTH_LIMIT ? 30 : "20px 0px",
                      boxShadow:
                        width > WIDTH_LIMIT
                          ? "0 1px 2px 0 rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.19)"
                          : "",
                      borderRadius: 5,
                      background: "#fff",
                    }}
                  >
                    <div
                      style={{
                        display: width > WIDTH_LIMIT ? "flex" : "",
                        justifyContent:
                          width > WIDTH_LIMIT ? "space-between" : "",
                      }}
                    >
                      <div
                        style={{ width: width > WIDTH_LIMIT ? "48%" : "100%" }}
                      >
                        <p>
                          Full Name <span style={{ color: "red" }}>*</span>
                        </p>
                        <TextField
                          id="name"
                          value={name}
                          onChange={(e) => {
                            handleChange(e);
                          }}
                          variant="outlined"
                          placeholder="Enter Your Name"
                          required
                          sx={{
                            width: "100%",
                            border: "1px solid #888",
                            borderRadius: 1,
                            marginTop: 1,
                          }}
                        />
                      </div>
                      <div
                        style={{
                          width: width > WIDTH_LIMIT ? "48%" : "100%",
                          marginTop: width > WIDTH_LIMIT ? 0 : 20,
                        }}
                      >
                        <p>
                          Mobile Number <span style={{ color: "red" }}>*</span>
                        </p>
                        <TextField
                          id="phone"
                          value={phone}
                          onChange={(e) => {
                            handleChange(e);
                          }}
                          variant="outlined"
                          placeholder="Enter Your Mobile Number"
                          required
                          sx={{
                            width: "100%",
                            border: "1px solid #888",
                            borderRadius: 1,
                            marginTop: 1,
                          }}
                        />
                      </div>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <div style={{ width: "100%", marginTop: 20 }}>
                        <p>
                          Email <span style={{ color: "red" }}>*</span>
                        </p>
                        <TextField
                          id="email"
                          value={email}
                          onChange={(e) => {
                            handleChange(e);
                          }}
                          variant="outlined"
                          placeholder="Email Address"
                          required
                          sx={{
                            width: "100%",
                            border: "1px solid #888",
                            borderRadius: 1,
                            marginTop: 1,
                          }}
                        />
                      </div>
                    </div>

                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <div style={{ width: "100%", marginTop: 20 }}>
                        <p style={{ marginBottom: 10 }}>Message</p>
                        <TextField
                          id="message"
                          value={message}
                          onChange={(e) => {
                            handleChange(e);
                          }}
                          variant="outlined"
                          placeholder="Message"
                          multiline
                          rows={4}
                          required
                          sx={{
                            width: "100%",
                            border: "1px solid #888",
                            borderRadius: 1,
                          }}
                          // error={error.checkOut.status === "error"}
                          // helperText={
                          //   error.checkOut.status === "error"
                          //     ? error.checkOut.message
                          //     : ""
                          // }
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {width < WIDTH_LIMIT && (
                <div
                  style={{
                    width: "100%",
                  }}
                >
                  <CustomButton
                    backgroundColor="#60442E"
                    onClick={() => {
                      handleNext2();
                    }}
                    styles={{
                      border: 0,
                      borderRadius: 5,
                      marginTop: 0,
                      width: width > WIDTH_LIMIT ? 150 : "100%",
                    }}
                    textStyles={{
                      fontWeight: "500",
                      fontSize: width > WIDTH_LIMIT ? 18 : 16,
                    }}
                    title="Next"
                  />

                  {width < WIDTH_LIMIT && (
                    <CustomButton
                      backgroundColor="#fff"
                      onClick={() => {
                        window.scrollTo({ top: 0, behavior: "smooth" });
                        setStep(1);
                      }}
                      styles={{
                        border: "1px solid #60442E",
                        borderRadius: 5,
                        marginTop: 10,
                        width: width > WIDTH_LIMIT ? 150 : "100%",
                      }}
                      textStyles={{
                        fontWeight: "500",
                        fontSize: width > WIDTH_LIMIT ? 18 : 16,
                        color: "#60442E",
                      }}
                      title="Go Back"
                    />
                  )}
                </div>
              )}
            </div>
          )}
        </div>

        {(width > WIDTH_LIMIT || (width < WIDTH_LIMIT && step === 3)) && (
          <div
            style={{
              width: width > WIDTH_LIMIT ? "30%" : "100%",
              position: "relative",
              borderRadius: 5,
            }}
          >
            <div
              style={{
                position: "sticky",
                top: 120,
                borderRadius: 5,
                border: width > WIDTH_LIMIT ? "1px solid #8E8E8E" : 0,
              }}
            >
              <div
                style={{
                  display: "flex",
                  padding: "20px",
                  background: "black",
                  borderTopLeftRadius: 5,
                  borderTopRightRadius: 5,
                  borderBottomLeftRadius:
                    width > WIDTH_LIMIT && step === 3 ? 0 : 5,
                  borderBottomRightRadius:
                    width > WIDTH_LIMIT && step === 3 ? 0 : 5,
                  border: width > WIDTH_LIMIT ? "1px solid #8E8E8E" : 0,
                  alignItems: "center",
                }}
              >
                {width < WIDTH_LIMIT && (
                  <p
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      background: step === 3 ? "#fff" : "#000",
                      width: 30,
                      height: 30,
                      padding: 5,
                      borderRadius: 50,
                      fontWeight: "bold",
                      color: step === 3 ? "#000" : "#fff",
                    }}
                  >
                    3
                  </p>
                )}

                <p
                  style={{
                    marginLeft: 15,
                    color: "#fff",
                    fontWeight: "bold",
                    fontSize: width > WIDTH_LIMIT ? 18 : 16,
                  }}
                >
                  Price Details
                </p>
              </div>

              <div
                style={{
                  padding: width > WIDTH_LIMIT ? 30 : 5,
                  marginTop: width > WIDTH_LIMIT ? 0 : 20,
                }}
              >
                {packageArr.map((item) => (
                  <BillItems item={item} />
                ))}

                <hr />

                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    marginTop: 20,
                    marginBottom: 20,
                  }}
                >
                  <div style={{ width: "60%" }}>
                    <p style={{ fontSize: 16 }}>Package total:</p>
                  </div>
                  <div style={{ width: "40%" }}>
                    <p
                      style={{
                        fontSize: 16,
                        fontWeight: "bold",
                        textAlign: "right",
                      }}
                    >
                      ₹{packageTotal.toFixed(2)}
                    </p>
                  </div>
                </div>
                {/* <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    marginTop: 20,
                    marginBottom: 20,
                  }}
                >
                  <div style={{ width: "60%" }}>
                    <p style={{ fontSize: 16 }}>18% GST:</p>
                  </div>
                  <div style={{ width: "40%" }}>
                    <p
                      style={{
                        fontSize: 16,
                        fontWeight: "bold",
                        textAlign: "right",
                      }}
                    >
                      ₹{(packageTotal * 0.18).toFixed(2)}
                    </p>
                  </div>
                </div> */}

                <hr />

                {data.kind === "camping" && (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      marginTop: 20,
                      marginBottom: 20,
                    }}
                  >
                    <div style={{ width: "60%" }}>
                      <p style={{ fontSize: 16, fontWeight: "500" }}>
                        Total <span style={{ fontSize: 14 }}>per day</span>
                      </p>
                    </div>
                    <div style={{ width: "40%" }}>
                      <p
                        style={{
                          fontSize: 20,
                          fontWeight: "bold",
                          textAlign: "right",
                        }}
                      >
                        ₹{totalPayPerDay.toFixed(2)}
                      </p>
                    </div>
                  </div>
                )}

                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    marginTop: 20,
                    marginBottom: 20,
                  }}
                >
                  <div style={{ width: "60%" }}>
                    <p style={{ fontSize: 16, fontWeight: "500" }}>
                      Total Payable
                    </p>
                    <span>X {noDays} days</span>
                  </div>
                  <div style={{ width: "40%" }}>
                    <p
                      style={{
                        fontSize: 20,
                        fontWeight: "bold",
                        textAlign: "right",
                      }}
                    >
                      {data?.kind === "camping" && !checkIn && !checkOut
                        ? "--NA--"
                        : "₹" + totalPay.toFixed(2)}
                    </p>
                  </div>
                </div>

                <hr />

                <CustomButton
                  loading={loading}
                  disabled={payDisabled}
                  onClick={() => {
                    displayRazorpay();
                  }}
                  backgroundColor="#60442E"
                  styles={{
                    marginTop: 20,
                    width: "100%",
                    border: 0,
                    borderRadius: 5,
                  }}
                  textStyles={{
                    fontWeight: "500",
                    fontSize: width > WIDTH_LIMIT ? 18 : 16,
                  }}
                  title={
                    data?.kind === "camping" && (!checkIn || !checkOut)
                      ? "--NA--"
                      : `Pay ₹${totalPay.toFixed(2)}`
                  }
                />

                {width < WIDTH_LIMIT && (
                  <CustomButton
                    backgroundColor="#fff"
                    onClick={() => {
                      window.scrollTo({ top: 0, behavior: "smooth" });
                      setStep(2);
                    }}
                    styles={{
                      border: "1px solid #60442E",
                      borderRadius: 5,
                      marginTop: 10,
                      width: width > WIDTH_LIMIT ? 150 : "100%",
                    }}
                    textStyles={{
                      fontWeight: "500",
                      fontSize: width > WIDTH_LIMIT ? 18 : 16,
                      color: "#60442E",
                    }}
                    title="Go Back"
                  />
                )}
              </div>
            </div>
          </div>
        )}
      </div>

      {paymentStatus === "SUCCESS" && (
        <PaymentSuccess
          handleClose={() => setPaymentStatus("CLOSE")}
          paymentDetails={paymentDetails}
          bookingNo={bookingNo}
        />
      )}
      {paymentStatus === "FAILURE" && (
        <PaymentFailure
          handleClose={() => setPaymentStatus("CLOSE")}
          paymentDetails={paymentDetails}
        />
      )}
    </div>
  );
};

export default BookNow;
