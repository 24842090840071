import React, { useEffect } from "react";
import { useState } from "react";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import CountryCode from "../../data/constants/CountryCode.json";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import {
  Typography,
  TextField,
  Button,
  MenuItem,
  InputLabel,
  FormControl,
  Select,
  Autocomplete,
} from "@mui/material";
import "./index.css";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { connect } from "react-redux";
import { ApiRequestPost } from "../../data/network/services/ApiRequestPost";
import { useSnackbar } from "notistack";
import ResizeListener from "../../utility/ResizeListener";
import { validateEmail, validateName, validatePhoneNumber } from "../../utility/inputValidations";
import { WIDTH_LIMIT as limit } from "../../data/constants/Constants";
import dayjs from "dayjs";
import { STAYS } from "../resources/constantsManager";
import secureStorage from "../../utility/secureStorage";
import { SS_ACTIVE_TAB } from "../resources/secureStorageManager";

const Enquiry = ({
  open,
  setEnquiryOpen,
  checkInCheckOutRed,
  customerDetails,
  activeEnquiryProperty,
}) => {
  const { enqueueSnackbar } = useSnackbar();

  const [name, setName] = useState("");
  const [code, setCode] = useState("+91");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [pkg, setPkg] = useState(toSentenceCase(activeEnquiryProperty?.kind));
  const [noOfPeople, setNoOfPeople] = useState(2);
  const [loading, setLoading] = useState(false);
  const [checkIn, setCheckIn] = useState(null);
  const [checkOut, setCheckOut] = useState(null);
  const [packageType, setPackageType] = useState([]);

  const packageDetails = activeEnquiryProperty?.packages
    ? [...activeEnquiryProperty?.packages]
    : [];
  const { width } = ResizeListener();
  const [error, setError] = useState({
    email: {
      status: "",
      message: "",
    },
    name: {
      status: "",
      message: "",
    },
    checkInOut: {
      status: "",
      message: "true",
    },
    phone: {
      status: "",
      message: "",
    },
    noOfPeople: {
      status: "",
      message: "",
    },
    code: {
      status: "",
      message: "",
    },
    packageType: {
      status: "",
      message: "",
    },
    checkIn: {
      status: "",
      message: "",
    },
    checkOut: {
      status: "",
      message: "",
    },
  });
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: width > limit ? "50%" : "85%",
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
    borderRadius: "13px",
    overflowY: "scroll",
    maxHeight: "85%",
  };

  const handleChange = (event) => {
    if (event.target.id === "email") {
      setEmail(event.target.value.trim());
    } else if (event.target.id === "name") {
      setName(event.target.value);
    } else if (event.target.id === "phone") {
      if (validatePhoneNumber(event.target.value)) {
        setPhone(event.target.value);
      }
    } else if (event.target.id === "noOfPeople") {
        setNoOfPeople(event.target.value);
    } else if (event.target.id === "message") {
      setMessage(event.target.value);
    } else if (event.target.name === "code") {
      setCode(event.target.value);
    }
  };
  function toSentenceCase(word) {
    if (!word) return "";

    const sentenceCaseWord =
      word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
    return sentenceCaseWord;
  }
  const handleValidation = (event) => {
    let newErr = { ...error };
    if (event.target.id === "email") {
      if (email && !validateEmail(email)) {
        newErr.email.status = "error";
        newErr.email.message = "Enter a valid Email";
      } else {
        newErr.email.status = "";
        newErr.email.message = "";
      }
    } else if (event.target.id === "phone") {
      if (phone && phone.length != 10) {
        newErr.phone.status = "error";
        newErr.phone.message = "Enter a valid phone number";
      } else {
        newErr.phone.status = "";
        newErr.phone.message = "";
      }
    } else if (event.target.id === "name") {
      if (name && !validateName(name)) {
        newErr.name.status = "error";
        newErr.name.message = "Enter a valid name";
      } else {
        {
          setName(name?.trim());
          newErr.name.status = "";
          newErr.name.message = "";
        }
      }
    } else if (event.target.id === "noOfPeople") {
      if (noOfPeople && noOfPeople <= 0) {
        newErr.noOfPeople.status = "error";
        newErr.noOfPeople.message = "Enter a valid number";
      } else {
        {
          newErr.noOfPeople.status = "";
          newErr.noOfPeople.message = "";
        }
      }
    }
    setError(newErr);
  };

  const handleDateValidation = (field, value) => {
    let newErr = { ...error };
    const parsedDate = dayjs(value);
    if (!parsedDate.isValid()) {
      newErr[field].status = "error";
      newErr[field].message = "Invalid Date";
      return;
    }
    if (parsedDate.isBefore(dayjs(), "day")) {
      newErr[field].status = "error";
      newErr[field].message = "Cannot be passed Date";
      return;
    }
    if (field === "checkIn" && checkOut) {
      if (dayjs(checkOut).toDate() > dayjs(value).toDate()) {
        newErr.checkOut.status = "";
        newErr.checkOut.message = "";
      } else {
        newErr.checkOut.status = "error";
        newErr.checkOut.message =
          "Check-Out cannot be lesser than Check-In date";
      }
      setError(newErr);
    }
    if (field === "checkOut" && checkIn) {
      if (dayjs(value).toDate() > dayjs(checkIn).toDate()) {
        newErr.checkOut.status = "";
        newErr.checkOut.message = "";
      } else {
        newErr.checkOut.status = "error";
        newErr.checkOut.message =
          "Check-Out cannot be lesser than Check-In date";
      }
      setError(newErr);
    }
  };

  const handleClose = () => {
    setEnquiryOpen(false);
  };

  const handleSubmit = () => {
    let submitCheck = true;

    let newErr = { ...error };
    if (!name) {
      newErr.name = { status: "error", message: "Please enter the name" };
      submitCheck = false;
    }
    if (!noOfPeople) {
      newErr.noOfPeople = {
        status: "error",
        message: "Please enter no of people",
      };
      submitCheck = false;
    }
    if (!email) {
      newErr.email = { status: "error", message: "Please enter the email" };
      submitCheck = false;
    }
    if (!phone) {
      newErr.phone = {
        status: "error",
        message: "Please enter the mobile number",
      };
      submitCheck = false;
    }
    if (!code) {
      newErr.code = {
        status: "error",
        message: "Please enter phone code",
      };
      submitCheck = false;
    }
    if (pkg !== "Dayouting") {
      if (checkIn == null || checkIn == "") {
        newErr.checkIn = {
          status: "error",
          message: "Please enter Check In",
        };
        submitCheck = false;
      }
      if (checkOut == null || checkOut == "") {
        newErr.checkOut = {
          status: "error",
          message: "Please enter Check Out",
        };
        submitCheck = false;
      }
    }
    if (pkg === "Dayouting") {
      if (checkIn == null || checkIn == "") {
        newErr.checkIn = {
          status: "error",
          message: "Please enter Check In",
        };
        submitCheck = false;
      }
    }
    if (packageType.length === 0) {
      newErr.packageType = {
        status: "error",
        message:
          activeEnquiryProperty?.kind === "stay"
            ? "Please select the room type"
            : "Please select the package type",
      };
      submitCheck = false;
    }
    setError(newErr);
    if (submitCheck) {
      if (
        !error.email.status &&
        !error.name.status &&
        !error.noOfPeople.status &&
        !error.phone.status &&
        !error.code.status &&
        !error.checkIn.status &&
        !error.checkOut.status &&
        !error.packageType.status
      ) {
        // send enquiry
        let pkgType = packageType
          ?.map(function (p) {
            return p.name;
          })
          .join(", ");
        let newEnquiry;
        if (customerDetails?.userId) {
          newEnquiry = {
            name,
            noOfPeople,
            email,
            phone,
            code,
            checkIn: checkIn?.$d?.toLocaleDateString(),
            checkOut:
              pkg !== "Dayouting" ? checkOut?.$d?.toLocaleDateString() : "",
            message,
            [activeEnquiryProperty.kind]: activeEnquiryProperty._id,
            productName: activeEnquiryProperty.name,
            user: customerDetails?.userId || "",
            productType: pkg,
            packageType: pkgType || "",
          };
        } else {
          newEnquiry = {
            name,
            noOfPeople,
            email,
            phone,
            code,
            checkIn: checkIn?.$d?.toLocaleDateString(),
            checkOut:
              pkg !== "Dayouting" ? checkOut?.$d?.toLocaleDateString() : "",
            message,
            [activeEnquiryProperty.kind]: activeEnquiryProperty._id,
            productName: activeEnquiryProperty.name,
            productType: pkg,
            packageType: pkgType || "",
          };
        }
        setLoading(true);
        ApiRequestPost.addEnquiry(newEnquiry)
          .then((res) => {
            if (res.data.success) {
              handleClose();
            }
            enqueueSnackbar(
              res?.data?.success ? res?.data?.message : "Something went wrong!",
              {
                variant: res?.data?.success ? "success" : "error",
                anchorOrigin: { vertical: "top", horizontal: "right" },
              }
            );
          })
          .catch((err) => {
            enqueueSnackbar(err.response.data.message, {
              variant: "error",
              anchorOrigin: { vertical: "top", horizontal: "right" },
            });
          })
          .finally(() => {
            setLoading(false);
          });
      }
    } else {
      enqueueSnackbar("Please fill the required fields", {
        variant: "error",
        anchorOrigin: { vertical: "top", horizontal: "right" },
      });
    }
  };

  useEffect(() => {
    if (customerDetails) {
      setName(customerDetails.name);
      setPhone(customerDetails.phone);
      setEmail(customerDetails.email);
    }
  }, [customerDetails]);

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <Box sx={style}>
            <IconButton
              style={{
                position: "absolute",
                top: "0",
                right: "0",
              }}
              onClick={() => setEnquiryOpen(false)}
            >
              <CloseIcon fontSize="large" />
            </IconButton>
            <div className="enquiry-title">
              <Typography
                sx={{
                  fontFamily: "Poppins",
                  fontSize: width > limit ? "40px" : "30px",
                  fontWeight: 600,
                  color: "#000000",
                  textAlign: "center",
                }}
              >
                Send Enquiry
              </Typography>
              <Typography
                sx={{
                  fontFamily: "Poppins",
                  fontSize: width > limit ? "20px" : "15px",
                  fontWeight: 500,
                  color: "#000",
                  textAlign: "center",
                }}
              >
                {pkg} Name :&nbsp;
                <span
                  style={{
                    fontFamily: "Poppins",
                    fontSize: width > limit ? "20px" : "15px",
                    fontWeight: 400,
                    color: "#808080",
                    display: "inline-block",
                  }}
                >
                  {activeEnquiryProperty?.name}
                </span>
              </Typography>
            </div>
            <div className="enquiry-body">
              <div className="enquiry-row">
                <TextField
                  id="name"
                  label="Name"
                  placeholder="Enter Your Name"
                  size="medium"
                  required
                  type="text"
                  sx={{ width: "100%" }}
                  autoFocus
                  error={error.name.status === "error"}
                  helperText={
                    error.name.status === "error" ? error.name.message : ""
                  }
                  onBlur={(e) => handleValidation(e)}
                  onChange={(e) => handleChange(e)}
                  value={name}
                />
                <div
                  style={{
                    display: "flex",
                    width: "100%",
                    paddingTop: width > limit ? "0px" : "30px",
                  }}
                >
                  <Box sx={{ width: "30%" }}>
                    <FormControl fullWidth required>
                      <InputLabel id="demo-simple-select-label">
                        Code
                      </InputLabel>
                      <Select
                        id="code"
                        name="code"
                        value={code}
                        label="code"
                        onChange={(e) => handleChange(e)}
                        defaultValue={10}
                      >
                        {CountryCode.countries.map((data) => (
                          <MenuItem value={data?.code}>{data?.code}</MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Box>
                  <TextField
                    id="phone"
                    label="Mobile Number"
                    placeholder="Enter Your Mobile Number"
                    size="medium"
                    required
                    sx={{ width: "70%" }}
                    error={error.phone.status === "error"}
                    helperText={
                      error.phone.status === "error" ? error.phone.message : ""
                    }
                    type="text"
                    onBlur={(e) => handleValidation(e)}
                    inputProps={{ maxLength: 10 }}
                    onChange={(e) => handleChange(e)}
                    value={phone}
                  />
                </div>
              </div>
              <div className="enquiry-row">
                <TextField
                  id="email"
                  label="Email"
                  placeholder="Enter Your Email"
                  size="medium"
                  required
                  sx={{ width: width > limit ? "50%" : "60%" }}
                  error={error.email.status === "error"}
                  type="email"
                  onBlur={(e) => handleValidation(e)}
                  onChange={(e) => handleChange(e)}
                  value={email}
                  helperText={
                    error.email.status === "error" ? error.email.message : ""
                  }
                />
                <TextField
                  id="noOfPeople"
                  label="No of people"
                  placeholder="No of people"
                  size="medium"
                  required
                  sx={{ width: width > limit ? "50%" : "40%" }}
                  defaultValue={2}
                  InputProps={{
                    inputProps: { min: 1 },
                  }}
                  error={error.noOfPeople.status === "error"}
                  type="number"
                  onBlur={(e) => handleValidation(e)}
                  onChange={(e) => handleChange(e)}
                  value={noOfPeople}
                  helperText={
                    error.noOfPeople.status === "error"
                      ? error.noOfPeople.message
                      : ""
                  }
                />
              </div>
              <div className="enquiry-row">
                <Autocomplete
                  multiple
                  id="combo-box-demo"
                  options={packageDetails}
                  getOptionLabel={(option) => option.name}
                  sx={{
                    width: "100%",
                    //marginTop: width > limit ? "0px" : "0px",
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      required
                      label={
                        activeEnquiryProperty?.kind === "stay"
                          ? "Room Type"
                          : "Package Type"
                      }
                      variant="outlined"
                      error={error.packageType.status === "error"}
                      helperText={
                        error.packageType.status === "error"
                          ? error.packageType.message
                          : ""
                      }
                    />
                  )}
                  value={packageType}
                  onChange={(e, v) => {
                    setPackageType(v);
                    let newErr = { ...error };
                    newErr.packageType.status = "";
                    newErr.packageType.message = "";
                    setError(newErr);
                  }}
                />
              </div>
              {pkg === "Dayouting" && (
                <div className="enquiry-row">
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DesktopDatePicker
                      id="checkIn"
                      disablePast
                      label="Date"
                      inputFormat="DD/MM/YYYY"
                      value={checkIn}
                      onChange={(e) => {
                        let newErr = { ...error };
                        newErr.checkIn.status = "";
                        newErr.checkIn.message = "";
                        setError(newErr);
                        setCheckIn(e);
                        handleDateValidation("checkIn", e);
                      }}
                      sx={{ width: "100%" }}
                      PaperProps={{
                        sx: {
                          "& .MuiPickersDay-root": {
                            "&.Mui-selected": {
                              backgroundColor: "#82C34C",
                            },
                          },
                          "&MuiPickersDay-dayWithMargin": {
                            backgroundColor: "#82C34C",
                          },
                        },
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          required
                          sx={{
                            width: width > limit ? "100%" : "100%",
                            // marginTop: width > limit ? "0px" : "30px",
                          }}
                          error={error.checkIn.status === "error"}
                          helperText={
                            error.checkIn.status === "error"
                              ? error.checkIn.message
                              : ""
                          }
                        />
                      )}
                    />
                  </LocalizationProvider>
                </div>
              )}
              {pkg !== "Dayouting" && (
                <div className="enquiry-row">
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DesktopDatePicker
                      id="checkIn"
                      disablePast
                      label="Check In"
                      inputFormat="DD/MM/YYYY"
                      value={checkIn}
                      onChange={(e) => {
                        let newErr = { ...error };
                        newErr.checkIn.status = "";
                        newErr.checkIn.message = "";
                        setError(newErr);
                        setCheckIn(e);
                        handleDateValidation("checkIn", e);
                      }}
                      sx={{ width: "100%" }}
                      PaperProps={{
                        sx: {
                          "& .MuiPickersDay-root": {
                            "&.Mui-selected": {
                              backgroundColor: "#82C34C",
                            },
                          },
                          "&MuiPickersDay-dayWithMargin": {
                            backgroundColor: "#82C34C",
                          },
                        },
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          required
                          sx={{
                            width: width > limit ? "50%" : "100%",
                            //marginTop: width > limit ? "0px" : "30px",
                          }}
                          error={error.checkIn.status === "error"}
                          helperText={
                            error.checkIn.status === "error"
                              ? error.checkIn.message
                              : ""
                          }
                        />
                      )}
                    />
                  </LocalizationProvider>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DesktopDatePicker
                      id="checkOut"
                      disablePast
                      label="Check Out"
                      inputFormat="DD/MM/YYYY"
                      value={checkOut}
                      minDate={checkIn || dayjs()}
                      onChange={(e) => {
                        let newErr = { ...error };
                        newErr.checkOut.status = "";
                        newErr.checkOut.message = "";
                        setError(newErr);
                        setCheckOut(e);
                        handleDateValidation("checkOut", e);
                      }}
                      sx={{ width: "100%" }}
                      PaperProps={{
                        sx: {
                          "& .MuiPickersDay-root": {
                            "&.Mui-selected": {
                              backgroundColor: "#82C34C",
                            },
                          },
                          "&MuiPickersDay-dayWithMargin": {
                            backgroundColor: "#82C34C",
                          },
                        },
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          required
                          sx={{
                            width: width > limit ? "50%" : "100%",
                            marginTop: width > limit ? "0px" : "30px",
                          }}
                          error={error.checkOut.status === "error"}
                          helperText={
                            error.checkOut.status === "error"
                              ? error.checkOut.message
                              : ""
                          }
                        />
                      )}
                    />
                  </LocalizationProvider>
                </div>
              )}
              <div className="enquiry-row">
                <TextField
                  id="message"
                  label="Message"
                  multiline
                  rows={5}
                  defaultValue=""
                  sx={{ width: "100%" }}
                  value={message}
                  onChange={(e) => handleChange(e)}
                />
              </div>
            </div>
            <div className="send-btn">
              <Button
                onClick={() => {
                  handleSubmit();
                }}
                variant="contained"
                size="small"
                sx={{
                  width: "35%",
                  backgroundColor: "#000000",
                  color: "#FFF",
                  fontWeight: "500",
                  textTransform: "none",
                  fontSize: "20px",
                  fontFamily: "Poppins",
                  ":hover": {
                    bgcolor: "#000000",
                    fontWeight: 800,
                  },
                }}
              >
                Send
              </Button>
            </div>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    checkInCheckOutRed: state.SearchReducer.checkInCheckOut,
    customerDetails: state.AuthReducer.customerDetails,
  };
};
export default connect(mapStateToProps)(Enquiry);
