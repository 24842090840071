import { SnackbarProvider } from "notistack";
import React, { useEffect} from "react";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import RouteChangeTracker from "./routes/RouteChangeTracker";
import "./App.css";
import { store } from "./data/redux/store";
import Layout from "./presentation/layout/index";
import Router from "./routes";
import history from "./routes/history";
import getMetaDataDetails from "./utility/getMetaDataDetails";
import { metaData } from "./utility/Constants";

const App = () => {

  useEffect(() => {
    const [metaTitle, metaDescription, metaKeywords] = getMetaDataDetails(window.location.pathname);
    if(metaTitle && metaDescription && metaKeywords) {
      getMetaDetails(metaTitle, metaDescription, metaKeywords);
    }
  },[]);

  const getMetaDetails = (metaTitle, metaDescription, metaKeywords) => {

    const metaDataConstants = metaData;
    document
      .getElementsByTagName(metaDataConstants.metaTag)
      .namedItem(metaDataConstants.description)
      .setAttribute(metaDataConstants.content, metaDescription);
    document
      .getElementsByTagName(metaDataConstants.metaTag)
      .namedItem(metaDataConstants.keywords)
      .setAttribute(metaDataConstants.content, metaKeywords);
    document.title = metaTitle;

    const ogMetaTitle = document.querySelector(`meta[property="${metaDataConstants.ogTitle}"]`);
    const ogMetaDescription = document.querySelector(`meta[property="${metaDataConstants.ogDescription}"]`);
    const ogMetaUrl = document.querySelector(`meta[property="${metaDataConstants.ogUrl}"]`);
    if(ogMetaTitle && ogMetaDescription && ogMetaUrl) {
      ogMetaTitle.setAttribute(metaDataConstants.content, metaTitle);
      ogMetaDescription.setAttribute(metaDataConstants.content, metaDescription);
      ogMetaUrl.setAttribute(metaDataConstants.content, window.location.href);
    }

  };

  return (
    <Provider store={store}>
      <SnackbarProvider
        maxSnack={1}
        autoHideDuration={3000}
        auto
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <BrowserRouter history={history}>
          <Layout>
            <Router />
            <RouteChangeTracker/>
          </Layout>
        </BrowserRouter>
      </SnackbarProvider>
    </Provider>
  );
};

export default App;
