import React, { useEffect, useState } from "react";
import { ApiRequestGet } from "../../../data/network/services/ApiRequestGet";
import ResizeListener from "../../../utility/ResizeListener";
import { Shimmer } from "react-shimmer";
import "./TrendingComponent.css";

import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Scrollbar, A11y } from "swiper";
import { Grid } from "@mui/material";
// import SwiperCore, { Autoplay } from "swiper";
import "swiper/css";
// import "swiper/css/autoplay";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";

import { CustomButton } from "../../components/customButton/CustomButton";
import { useNavigate } from "react-router";
import {
  WIDTH_LIMIT,
  WIDTH_LIMIT as limit,
} from "../../../data/constants/Constants";
import "./index.css";

const TrendingComponent = ({ Title, data = [], type, disableSlider }) => {
  // SwiperCore.use([Autoplay]);

  const idTitle = Title.split(' ').slice(1).join('').toLowerCase();
  const navigate = useNavigate();
  const { width } = ResizeListener();

  const TrendingItem = ({ index, item }) => {
    const [isShown, setIsShown] = useState(false);

    return (
      <div
        key={index}
        onMouseEnter={() => setIsShown(true)}
        onMouseLeave={() => setIsShown(false)}
        style={{
          width: "100%",
          height: 350,
          borderRadius: 10,
          position: "relative",
        }}
      >
        {item?.photo[0] !== "no-photo.jpg" ? (
          <img
            style={{
              width: "100%",
              height: "100%",
              objectFit: "cover",
              borderRadius: 15,
            }}
            src={item?.photo[0]}
          />
        ) : (
          <>
            <div
              style={{
                width: "100%",
                height: "100%",
                backgroundImage: "linear-gradient(#aaa, #444)",
                borderRadius: 15,
              }}
            />
          </>
        )}
        {!isShown ? (
          <div
            className="fade-out"
            style={{
              position: "absolute",
              top: 0,
              bottom: 0,
              left: 0,
              right: 0,
              width: "100%",
              height: "100%",
              backgroundImage:
                "linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.6))",
              borderRadius: 15,
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-end",
            }}
          >
            <div style={{ padding: width > limit ? 10 : 5 }}>
              <p
                style={{
                  margin: 0,
                  color: "#fff",
                  fontWeight: "600",
                  //whiteSpace: "nowrap",
                  fontSize: 18,
                }}
              >
                {width > limit
                  ? item?.name.slice(0, 80)
                  : item?.name?.slice(0, 84)}
                {width > limit
                  ? item?.name?.length > 80
                    ? "..."
                    : ""
                  : item?.name?.length > 84
                  ? "..."
                  : ""}
              </p>

              <p
                style={{
                  margin: 0,
                  color: "#fff",
                  fontWeight: "400",
                  fontSize: 16,
                  marginBottom: 15,
                }}
              >
                {item?.city || item?.location?.city}, {item?.location?.state}
              </p>

              <p
                style={{
                  margin: "5px 0px 0px 0px",
                  color: "#fff",
                  fontWeight: "400",
                  fontSize: 16,
                }}
              >
                Average:{" "}
                <span style={{ fontWeight: "600", fontSize: 18 }}>
                  ₹{item?.currentStartingPrice}
                </span>{" "}
                / {item?.priceFor?.toLowerCase() || "person"}
              </p>
            </div>
          </div>
        ) : (
          <div
            className="fade-in"
            style={{
              position: "absolute",
              top: 0,
              bottom: 0,
              left: 0,
              right: 0,
              width: "100%",
              height: "100%",
              backgroundColor: "rgba(0, 0, 0, 0.7)",
              borderRadius: 15,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <CustomButton
              onClick={() => {
                const url =
                  window.location.origin +
                  `/property-details/${type.toLowerCase()}/${item.slug}`;
                window.open(url, "_blank", "noreferrer");
              }}
              textStyles={{
                fontWeight: "500",
                color: "#101010",
                fontSize: 15,
              }}
              styles={{
                border: 0,
                width: "90%",
                height: 50,
                borderRadius: 7,
                backgroundColor: "#fff",
              }}
              title="view property"
            />
          </div>
        )}
      </div>
    );
  };

  return (
    <>
      <div
        style={{
          margin: '70px 0px',
          width: width * 0.9,
        }}
      >
        <div
          id={idTitle}
          style={{
            display: "flex",
            height: 50,
            justifyContent: "center",
            alignItems: "center",
            marginTop: 20,
            marginBottom: 20,
            position: "relative",
          }}
        >
          <div style={{ background: "#E2E2E2", width: "100%", height: 2 }} />
          <p
            style={{
              fontSize: width > WIDTH_LIMIT ? 26 : 20,
              fontWeight: "500",
              color: "#101010",
              textAlign: "center",
              position: "absolute",
              background: "#fff",
              padding: "0px 20px",
            }}
          >
            {Title}
          </p>
        </div>

        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            justifyContent: width > WIDTH_LIMIT ? "space-between" : "center",
            alignItems: "center",
            borderRadius: 15,
          }}
        >
          {data.length > 0 ? (
            <div
              style={{
                width: "100%",
              }}
            >
              {disableSlider ? (
                <Grid container gap='4px' justifyContent='center'>
                  {data?.map((item, index) => (
                    <Grid item xs={12} sm={12} md={2.5} lg={2.5} xl={2.5} key={index}>
                      <TrendingItem index={index} item={item} />
                    </Grid>
                  ))}
                </Grid>
              ) : (
                <Swiper
                  modules={[Navigation, Pagination, Scrollbar, A11y]}
                  spaceBetween={width > WIDTH_LIMIT ? 10 : 10}
                  slidesPerView={width > WIDTH_LIMIT ? 4 : 1}
                  navigation
                  updateOnWindowResize
                  observer
                  observerParents
                  initialSlide={4}
                  loop
                  // autoplay
                >
                  {data?.map((item, index) => (
                    <SwiperSlide key={index}>
                      <TrendingItem index={index} item={item} />
                    </SwiperSlide>
                  ))}
                </Swiper>
              )}
            </div>
          ) : (
            <>
              <Shimmer
                className={
                  width > WIDTH_LIMIT ? 'image-shimmer' : 'image-shimmer-mobile'
                }
                width={250}
                height={250}
              />
              {width > WIDTH_LIMIT && (
                <>
                  <Shimmer
                    className="image-shimmer-mobile"
                    width={250}
                    height={250}
                  />
                  <Shimmer
                    className="image-shimmer-mobile"
                    width={250}
                    height={250}
                  />
                  <Shimmer
                    className="image-shimmer-mobile"
                    width={250}
                    height={250}
                  />
                </>
              )}
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default TrendingComponent;
