import AddIcon from "../../assets/svg/add.svg";
import BlackClearIcon from "../../assets/svg/blackClear.svg";
import ClearIcon from "../../assets/svg/clear.svg";
import dashboard_search from "../../assets/svg/dashboard_search.svg";
import DateRangeIcon from "../../assets/svg/dateRange.svg";
import UploadDownloadIcon from "../../assets/svg/downloadFile.svg";
import DropDownIcon from "../../assets/svg/dropDownArrow.svg";
import EditIcon from "../../assets/svg/edit.svg";
import failure_trans from "../../assets/svg/failed_trans.svg";
import wanderinglama from "../../assets/png/wanderinglama.png";
import PaginationIcon from "../../assets/svg/paginationArrow.svg";
import PasswordHider_Icon from "../../assets/svg/passwordhide.svg";
import PasswordView_Icon from "../../assets/svg/passwordvisible.svg";
import success_trans from "../../assets/svg/success_trans.svg";
import UploadIcon from "../../assets/svg/upload.svg";
import rating_star from "../../assets/svg/RatingStar.svg";
import profile_icon from "../../assets/svg/ProfileIcon.svg";
import location_icon from "../../assets/svg/LocationIcon.svg";
import search_icon from "../../assets/svg/SearchIcon.svg";
import location_pin_icon from "../../assets/svg/locationPinIcon.svg";
import facebook_icon from "../../assets/svg/FacebookIcon.svg";
import instagram_icon from "../../assets/svg/InstagramIcon.svg";
import linkedin_icon from "../../assets/svg/LinkedInIcon.svg";
import twitter_icon from "../../assets/svg/TwitterIcon.svg";
import youtube_icon from "../../assets/svg/YouTubeIcon.svg";
import check_mark_icon from "../../assets/svg/checkMark.svg";
import close_icon from "../../assets/svg/CloseIcon.svg";
import back_icon from "../../assets/svg/BackIcon.svg";
// import landing_img from "../../assets/png/LandingImg.png";
import frontLanding from "../../assets/webp/frontLanding.webp";
import frontLandingM from "../../assets/webp/frontLandingM.webp";
import backLanding from "../../assets/webp/backLanding.webp";
import backLandingM from "../../assets/webp/backLandingM.webp";
import filter_icon from "../../assets/svg/FilterIcon.svg";
import payment_success from "../../assets/svg/paymentSuccess.svg";
import payment_failure from "../../assets/svg/paymentFailure.svg";
import share_icon from "../../assets/png/ShareIcon.png";
import { ReactComponent as wishlist_heart } from "../../assets/svg/WishlistHeart.svg";
import { ReactComponent as stay_tab_icon } from "../../assets/svg/StayTabIcon.svg";
import { ReactComponent as tour_tab_icon } from "../../assets/svg/TourTabIcon.svg";
import { ReactComponent as event_tab_icon } from "../../assets/svg/EventTabIcon.svg";
import { ReactComponent as experince_tab_icon } from "../../assets/svg/ExperienceTabIcon.svg";
import experince_image from "../../assets/png/ExperianceImage.png";
import wanderinglama_color from "../../assets/svg/WanderinglamaColor.svg";
// import wanderinglama_color from "../../assets/png/WanderinglamaColor.png";
import { ReactComponent as full_star } from "../../assets/svg/FullStar.svg";
import instagram from "../../assets/svg/Instagram.svg";
import facebook from "../../assets/svg/Facebook.svg";
import twitter from "../../assets/svg/Twitter.svg";
import youtube from "../../assets/svg/YouTube.svg";
import linkedin from "../../assets/svg/LinkedIn.svg";
import shareIcon from "../../assets/svg/shareIcon.svg";
import aboutus1 from "../../assets/svg/aboutus1.svg";
import aboutus2 from "../../assets/svg/aboutus2.svg";
import aboutus3 from "../../assets/svg/aboutus3.svg";
import aboutus4 from "../../assets/svg/aboutus4.svg";
import aboutus5 from "../../assets/svg/aboutus5.svg";
import aboutus6 from "../../assets/svg/aboutus6.svg";
import aboutus7 from "../../assets/svg/aboutus7.svg";
import aboutus8 from "../../assets/svg/aboutus8.svg";
import adarsh from "../../assets/svg/adarsh.svg";
import aniketh from "../../assets/svg/aniketh.svg";
import shalva from "../../assets/svg/shalva.svg";
import aboutusinsta from "../../assets/svg/aboutusinsta.svg";
import aboutuslinkedIn from "../../assets/svg/aboutuslinkedIn.svg";
import aboutusmobile from "../../assets/svg/aboutusmobile.svg";
import aboutusgroup from "../../assets/svg/aboutusgroup.svg";
import aboutusvector from "../../assets/svg/aboutusvector.svg";
import doneIcon from "../../assets/svg/doneIcon.svg";
import { ReactComponent as myProfileIcon } from "../../assets/svg/MyProfileIcon.svg";
import { ReactComponent as logoutIcon } from "../../assets/svg/LogoutIcon.svg";
import downloadIcon from "../../assets/svg/downloadIcon.svg";
import triangleRight from "../../assets/svg/triangleRight.svg";

export const WanderinglamaLogo = wanderinglama;
export const Dropdown = DropDownIcon;
export const DateRange = DateRangeIcon;
export const Add = AddIcon;
export const Upload = UploadIcon;
export const UploadDownload = UploadDownloadIcon;
export const Clear = ClearIcon;
export const BlackClear = BlackClearIcon;
export const Edit = EditIcon;
export const PaginationArrow = PaginationIcon;
export const PasswordHide = PasswordHider_Icon;
export const PasswordView = PasswordView_Icon;
export const FailedTransaction = failure_trans;
export const SuccessTransaction = success_trans;
export const DashboardSearchIcon = dashboard_search;
export const RatingStar = rating_star;
export const ProfileIcon = profile_icon;
export const WishlistHeart = wishlist_heart;
export const LocationIcon = location_icon;
export const SearchIcon = search_icon;
// export const LandingImg = landing_img;
export const FrontLanding = frontLanding;
export const BackLanding = backLanding;
export const FrontLandingM = frontLandingM;
export const BackLandingM = backLandingM;
export const LocationPinIcon = location_pin_icon;
export const FacebookIcon = facebook_icon;
export const InstagramIcon = instagram_icon;
export const LinkedInIcon = linkedin_icon;
export const TwitterIcon = twitter_icon;
export const YouTubeIcon = youtube_icon;
export const CheckMarkIcon = check_mark_icon;
export const CloseIcon = close_icon;
export const BackIcon = back_icon;
export const FilterIcon = filter_icon;
export const ShareIcon = share_icon;
export const StayTabIcon = stay_tab_icon;
export const TourTabIcon = tour_tab_icon;
export const EventTabIcon = event_tab_icon;
export const ExperienceTabIcon = experince_tab_icon;
export const ExperianceImage = experince_image;
export const WanderinglamaColor = wanderinglama_color;
export const FullStar = full_star;
export const Instagram = instagram;
export const Facebook = facebook;
export const Youtube = youtube;
export const Twitter = twitter;
export const LinkedIn = linkedin;
export const TriShareIcon = shareIcon;
export const AboutUs1 = aboutus1;
export const AboutUs2 = aboutus2;
export const AboutUs3 = aboutus3;
export const AboutUs4 = aboutus4;
export const AboutUs5 = aboutus5;
export const AboutUs6 = aboutus6;
export const AboutUs7 = aboutus7;
export const AboutUs8 = aboutus8;
export const Adarsh = adarsh;
export const Aniketh = aniketh;
export const Shalva = shalva;
export const AboutUsInsta = aboutusinsta;
export const AboutUsLinkedIn = aboutuslinkedIn;
export const AboutUsMobile = aboutusmobile;
export const AboutUsGroup = aboutusgroup;
export const AboutUsVector = aboutusvector;
export const MyProfileIcon = myProfileIcon;
export const LogoutIcon = logoutIcon;
export const DONE_ICON = doneIcon;
export const PaymentSuccessIcon = payment_success;
export const PaymentFailureIcon = payment_failure;
export const DownloadIcon = downloadIcon;
export const TriangleRight = triangleRight;
