import { SS_TOKEN } from "../../../presentation/resources/secureStorageManager";
import secureStorage from "../../../utility/secureStorage";
import { ApiGetServiceWrapper } from "../wrapperService";
import {
  ENQUIRY_URL,
  PROPERTY_URL,
  LOCATION_SUGGESTION_URL,
  PRODUCTS_URL,
  BOOKING_URL,
} from "./ApiEndPoint";

export const ApiRequestGet = {
  getAllProperty: () => {
    return new Promise((resolve, reject) => {
      ApiGetServiceWrapper({
        url: PROPERTY_URL + "/list",
        headers: {},
      })
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  getPropertyByFilter: (location, price, type, size, page) => {
    return new Promise((resolve, reject) => {
      ApiGetServiceWrapper({
        url:
          PRODUCTS_URL +
          "/list/" +
          location +
          "/" +
          type +
          "?startPrice=0&endPrice=" +
          price,
        // + "&size=" + size + "&page=" + page
      })
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  getFeaturedProperty: () => {
    return new Promise((resolve, reject) => {
      ApiGetServiceWrapper({
        url: PROPERTY_URL + "/list",
        headers: {
          mode: "featured",
        },
      })
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  getAllEnquiriesByUser: () => {
    let token = secureStorage.getItem(SS_TOKEN);
    return new Promise((resolve, reject) => {
      ApiGetServiceWrapper({
        url: ENQUIRY_URL + `/user/list`,
        headers: {
          Authorization: "Bearer " + token,
        },
      })
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  getAllBookingsByUser: () => {
    let token = secureStorage.getItem(SS_TOKEN);
    return new Promise((resolve, reject) => {
      ApiGetServiceWrapper({
        url: BOOKING_URL + `/user`,
        headers: {
          Authorization: "Bearer " + token,
        },
      })
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  getLocationSuggestions: (search) => {
    return new Promise((resolve, reject) => {
      ApiGetServiceWrapper({
        url: LOCATION_SUGGESTION_URL + `/get/${search}`,
        headers: {},
      })
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  getAllLocationSuggestions: () => {
    return new Promise((resolve, reject) => {
      ApiGetServiceWrapper({
        url: LOCATION_SUGGESTION_URL + `/get-all`,
        headers: {},
      })
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  getPropertyDetailsById: (Id) => {
    return new Promise((resolve, reject) => {
      ApiGetServiceWrapper({
        url: PROPERTY_URL + `/${Id}`,
        headers: {},
      })
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  getPropertyDetailsBySlug: (type, slug) => {
    return new Promise((resolve, reject) => {
      ApiGetServiceWrapper({
        url: PRODUCTS_URL + `/getProduct/${type}/${slug}`,
        headers: {},
      })
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  getTrendingProducts: () => {
    return new Promise((resolve, reject) => {
      ApiGetServiceWrapper({
        url: PRODUCTS_URL + `/trending`,
        headers: {},
      })
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  getTrendingProductsByType: (type) => {
    return new Promise((resolve, reject) => {
      ApiGetServiceWrapper({
        url: PRODUCTS_URL + `/exclusive/${type}`,
        headers: {},
      })
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

};
