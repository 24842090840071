import React, { useState, useEffect } from "react";
import {
  EventTabIcon,
  ExperienceTabIcon,
  StayTabIcon,
  TourTabIcon,
} from "../../resources/assetsManager";
import {
  EVENTS,
  EXPERIENCES,
  STAYS,
  TOURS,
} from "../../resources/constantsManager";
import { CustomButton } from "../customButton/CustomButton";
import { validate } from "../customInput/validator";
import "./index.css";
import ResizeListener from "../../../utility/ResizeListener";
import { WIDTH_LIMIT } from "../../../data/constants/Constants";
import secureStorage from "../../../utility/secureStorage";
import { SS_ACTIVE_TAB } from "../../resources/secureStorageManager";
import { ApiRequestGet } from "../../../data/network/services/ApiRequestGet";

const MainSearchBar = ({
  onSearch,
  placeholder,
  disabled,
  value,
  onChange,
  activeTab,
  setActiveTab,
  validator,
  debounceFn,
  showLoactionSuggestion,
  locationArr,
  setShowLoactionSuggestion,
  searchAllLocations,
}) => {
  const [focus, setFocus] = useState(false);
  const { width } = ResizeListener();

  useEffect(() => {
    const at = secureStorage.getItem(SS_ACTIVE_TAB);
    if (at) {
      setActiveTab(at);
    } else {
      secureStorage.setItem(SS_ACTIVE_TAB, STAYS);
      setActiveTab(STAYS);
    }
  }, []);

  useEffect(() => {
    if (focus && value === "") searchAllLocations();
  }, [focus]);

  return (
    <>
      <div
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {width > WIDTH_LIMIT && (
          <div
            style={{
              background: "#F1F1F1",
              display: "flex",
              // width: "40%",
              width: "25%",
              height: 50,
              borderTopLeftRadius: 5,
              borderTopRightRadius: 5,
              zIndex: 100,
            }}
          >
            <div
              style={{
                // width: "25%",
                width: "50%",
                height: "100%",
                background: activeTab === STAYS ? "white" : "#F1F1F1",
                borderTopLeftRadius: 5,
                justifyContent: "center",
                alignItems: "center",
                display: "flex",
                cursor: "pointer",
              }}
              onClick={async () => {
                setActiveTab(STAYS);
                await secureStorage.setItem(SS_ACTIVE_TAB, STAYS);
              }}
            >
              <StayTabIcon
                fill={activeTab === STAYS ? "#3B76E8" : "#525252"}
                style={{
                  marginRight: 5,
                  width: 20,
                  height: 20,
                  marginBottom: 5,
                }}
              />
              <p
                style={{
                  color: activeTab === STAYS ? "#3B76E8" : "#525252",
                  fontWeight: activeTab === STAYS ? "500" : "400",
                }}
              >
                Stays
              </p>
            </div>
            <div
              style={{
                // width: "25%",
                width: "50%",
                height: "100%",
                background: activeTab === EXPERIENCES ? "white" : "#F1F1F1",
                justifyContent: "center",
                alignItems: "center",
                display: "flex",
                cursor: "pointer",
                borderTopRightRadius: 5,
              }}
              onClick={async () => {
                setActiveTab(EXPERIENCES);
                await secureStorage.setItem(SS_ACTIVE_TAB, EXPERIENCES);
              }}
            >
              <ExperienceTabIcon
                fill={activeTab === EXPERIENCES ? "#3B76E8" : "#525252"}
                style={{
                  marginRight: 5,
                  width: 22,
                  height: 22,
                  marginBottom: 5,
                }}
              />
              <p
                style={{
                  color: activeTab === EXPERIENCES ? "#3B76E8" : "#525252",
                  fontWeight: activeTab === EXPERIENCES ? "500" : "400",
                }}
              >
                Experiences
              </p>
            </div>
            {/* <div
            style={{
              width: "25%",
              height: "100%",
              background: activeTab === EVENTS ? "white" : "#F1F1F1",
              justifyContent: "center",
              alignItems: "center",
              display: "flex",
              cursor: "pointer",
            }}
            onClick={async () => {
              setActiveTab(EVENTS);
              await secureStorage.setItem(SS_ACTIVE_TAB, EVENTS);
            }}
          >
            <EventTabIcon
              fill={activeTab === EVENTS ? "#E83B98" : "#525252"}
              style={{
                marginRight: 5,
                width: 20,
                height: 20,
                marginBottom: 5,
              }}
            />
            <p
              style={{
                color: activeTab === EVENTS ? "#E83B98" : "#525252",
                fontWeight: activeTab === EVENTS ? "500" : "400",
              }}
            >
              Events
            </p>
          </div>
          <div
            style={{
              width: "25%",
              height: "100%",
              background: activeTab === TOURS ? "white" : "#F1F1F1",
              borderTopRightRadius: 5,
              justifyContent: "center",
              alignItems: "center",
              display: "flex",
              cursor: "pointer",
            }}
            onClick={async () => {
              setActiveTab(TOURS);
              await secureStorage.setItem(SS_ACTIVE_TAB, TOURS);
            }}
          >
            <TourTabIcon
              fill={activeTab === TOURS ? "#E83B3B" : "#525252"}
              style={{
                marginRight: 5,
                width: 20,
                height: 20,
                marginBottom: 5,
              }}
            />
            <p
              style={{
                color: activeTab === TOURS ? "#E83B3B" : "#525252",
                fontWeight: activeTab === TOURS ? "500" : "400",
              }}
            >
              Tours
            </p>
          </div> */}
          </div>
        )}
        <div
          style={{
            width: width > WIDTH_LIMIT ? "60%" : "90%",
            background: "#fff",
            borderRadius: 5,
            display: "flex",
            alignItems: "center",
            position: "relative",
            padding: width > WIDTH_LIMIT ? "15px 0px" : "8px 0px",
          }}
        >
          {showLoactionSuggestion && (
            <div
              style={{
                position: "absolute",
                top: width > WIDTH_LIMIT ? 72 : 50,
                width: "100%",
                borderRadius: 5,
                backgroundColor: "#fff",
                maxHeight: 250,
                overflow: "scroll",
                zIndex: 200,
              }}
            >
              {locationArr.map((item, index) => (
                <p
                  key={index}
                  onClick={() => {
                    onChange(item.value);
                    setShowLoactionSuggestion(false);
                  }}
                  style={{
                    cursor: "pointer",
                    borderBottom: "1px solid #ccc",
                    margin: 5,
                    padding: 10,
                    fontSize: width > WIDTH_LIMIT ? 16 : 14,
                  }}
                >
                  {item.name}
                </p>
              ))}
            </div>
          )}
          <input
            type="text"
            onClick={() => setFocus(true)}
            onBlur={() => {
              setFocus(false);
              setTimeout(() => {
                setShowLoactionSuggestion(false);
              }, 100);
            }}
            onKeyDown={(event) => {
              if (event.key === "Enter") {
                onSearch();
              }
            }}
            style={{
              border: "none",
              outline: "none",
              backgroundColor: "transparent",
              width: "70%",
              fontSize: width > WIDTH_LIMIT ? 18 : 15,
              height: 30,
              padding: width > WIDTH_LIMIT ? 20 : "10px 20px",
              borderRadius: 5,
              zIndex: 150,
            }}
            disabled={disabled}
            value={value}
            placeholder={placeholder}
            onChange={(event) => {
              onChange(event.target.value);
              debounceFn(event.target.value);

              if (event.target.value === "") {
                searchAllLocations();
              }

              // validate(
              //   validator,
              //   event.target.value,
              //   onChange,
              //   setNamesError,
              //   isSpaceRequired
              // );
            }}
            autoCorrect="off"
            // autoFocus={true}
          />

          <div
            style={{
              width: "30%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              zIndex: 150,
            }}
          >
            <CustomButton
              onClick={onSearch}
              styles={{
                borderRadius: 5,
                width: "75%",
                height: width > WIDTH_LIMIT ? 40 : 32,
                background: "#101010",
              }}
              textStyles={{
                fontWeight: "500",
                fontSize: width > WIDTH_LIMIT ? 16 : 14,
              }}
              title="search"
            />
          </div>
        </div>

        {width < WIDTH_LIMIT && (
          <div
            style={{
              width: "90%",
              marginTop: 20,
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <div
              style={{
                // width: "25%",
                width: "48%",
                height: "100%",
                background: activeTab === STAYS ? "#3B76E8" : "#fff",
                borderRadius: 5,
                border: "1px solid #fff",
              }}
              onClick={async () => {
                setActiveTab(STAYS);
                await secureStorage.setItem(SS_ACTIVE_TAB, STAYS);
              }}
            >
              <div
                style={{
                  position: "relative",
                  width: "100%",
                  height: "100%",
                  justifyContent: "center",
                  alignItems: "center",
                  display: "flex",
                  padding: 10,
                  cursor: "pointer",
                  zIndex: 150,
                }}
              >
                <StayTabIcon
                  fill={activeTab === STAYS ? "#fff" : "#3B76E8"}
                  style={{
                    marginRight: 5,
                    width: 18,
                    height: 18,
                    marginBottom: 5,
                  }}
                />
                <p
                  style={{
                    fontSize: 15,
                    color: activeTab === STAYS ? "#fff" : "#3B76E8",
                    fontWeight: activeTab === STAYS ? "500" : "400",
                  }}
                >
                  Stays
                </p>
              </div>
            </div>

            <div
              style={{
                // width: "25%",
                width: "48%",
                height: "100%",
                background: activeTab === EXPERIENCES ? "#3B76E8" : "#fff",
                borderRadius: 5,
                border: "1px solid #fff",
              }}
              onClick={async () => {
                setActiveTab(EXPERIENCES);
                await secureStorage.setItem(SS_ACTIVE_TAB, EXPERIENCES);
              }}
            >
              <div
                style={{
                  position: "relative",
                  width: "100%",
                  height: "100%",
                  justifyContent: "center",
                  alignItems: "center",
                  display: "flex",
                  padding: 10,
                  cursor: "pointer",
                  zIndex: 150,
                }}
              >
                <ExperienceTabIcon
                  fill={activeTab === EXPERIENCES ? "#fff" : "#3B76E8"}
                  style={{
                    marginRight: 5,
                    width: 18,
                    height: 18,
                    marginBottom: 5,
                  }}
                />
                <p
                  style={{
                    fontSize: 15,
                    color: activeTab === EXPERIENCES ? "#fff" : "#3B76E8",
                    fontWeight: activeTab === EXPERIENCES ? "500" : "400",
                  }}
                >
                  Experiences
                </p>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default MainSearchBar;
