import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import { authAction, updateCustomerDetails } from "../../data/redux/action/authAction";
import {
  startFullScreenLoadingAction,
  stopFullScreenLoadingAction,
} from "../../data/redux/action/fullScreenLoadingAction";
import secureStorage from "../../utility/secureStorage";
import { SIDEBAR_COLOR } from "../resources/colorsManager";
import { LogoutIcon, MyProfileIcon } from "../resources/assetsManager";
import ResizeListener from "../../utility/ResizeListener";
import { WIDTH_LIMIT } from "../../data/constants/Constants";

const DropdownOptions = ({ clickHandle, title, styles, Icon, width }) => {
  const [color, setColor] = useState("black");
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",

        ...styles,
      }}
    >
      <Icon style={{ width: width > WIDTH_LIMIT ? 23 : 20 }} />
      <p
        style={{
          fontSize: width > WIDTH_LIMIT ? 17 : 14,
          cursor: "pointer",
          color: color,
          marginLeft: 5,
          fontWeight: "500",
        }}
        onClick={() => {
          clickHandle();
        }}
        onMouseEnter={() => {
          setColor(SIDEBAR_COLOR);
        }}
        onMouseLeave={() => {
          setColor("black");
        }}
        className="roboto"
      >
        {title}
      </p>
    </div>
  );
};

function TopNavBarDropdown({ clickHandle }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { width } = ResizeListener();

  const logoutApp = () => {
    dispatch(startFullScreenLoadingAction());
    secureStorage.clear();
    dispatch(authAction(false));
    dispatch(updateCustomerDetails());
    navigate("/login");
    dispatch(stopFullScreenLoadingAction());
    clickHandle();
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
        width: "100%",
        boxSizing: "border-box",
        padding: 20,
        position: "relative",
      }}
      className="latoRegular"
    >
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          position: "absolute",
          backgroundColor: "white",
          zIndex: "1111111111111",
          width: "15px",
          height: "15px",
          top: "-5px",
          transform: "rotate(45deg)",
          borderRadius: 2,
        }}
      />
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "flex-start",
          width: "100%",
        }}
      >
        <DropdownOptions
          width={width}
          clickHandle={() => {
            navigate("/profile/details");
            clickHandle();
          }}
          title="My Profile"
          Icon={MyProfileIcon}
        />

        <DropdownOptions
          width={width}
          styles={{ marginTop: 20 }}
          clickHandle={() => {
            logoutApp();
          }}
          title="Logout"
          Icon={LogoutIcon}
        />
      </div>
    </div>
  );
}

export default TopNavBarDropdown;
