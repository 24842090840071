import { ApiRequestPost } from "../../data/network/services/ApiRequestPost";
import { authAction } from "../../data/redux/action/authAction";
import {
  startFullScreenLoadingAction,
  stopFullScreenLoadingAction,
} from "../../data/redux/action/fullScreenLoadingAction";
import { SUCCESS } from "../../presentation/resources/constantsManager";
import secureStorage from "../secureStorage";

export const logoutService = ( navigate ) => ( dispatch ) => {
  dispatch( startFullScreenLoadingAction() );
  secureStorage.clear();
  dispatch( authAction( false ) );
  navigate( "/login" );
  dispatch( stopFullScreenLoadingAction() );
};
