import { React, useState } from "react";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import {
  Typography,
  TextField,
  Button,
  InputAdornment,
  IconButton,
  CircularProgress,
} from "@mui/material";
import "./index.css";
import CloseIcon from "@mui/icons-material/Close";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { ApiRequestPost } from "../../data/network/services/ApiRequestPost";
import { useSnackbar } from "notistack";
import ResizeListener from "../../utility/ResizeListener";
import { validatePassword } from "../../utility/inputValidations";
import { WIDTH_LIMIT as limit } from "../../data/constants/Constants";

export const ResetPassword = ({
  accessToken,
  setAccessToken,
  email,
  open,
  setResetOpen,
  setStep,
  setLoginOpen,
  setResetPassword,
}) => {
  const { enqueueSnackbar } = useSnackbar();

  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const { width } = ResizeListener();
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);
  const [error, setError] = useState({
    password: {
      status: "",
      message: "",
    },
    confirmPassword: {
      status: "",
      message: "",
    },
  });

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: width > limit ? "30%" : "80%",
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
    borderRadius: "13px",
  };

  const handleChange = (event) => {
    if (event.target.id === "password") {
      setPassword(event.target.value.trim());
    } else if (event.target.id === "confirmPassword") {
      setConfirmPassword(event.target.value.trim());
    }
  };

  const handleClose = () => {
    setResetOpen(false);
  };

  const handleValidation = (event) => {
    let newErr = { ...error };
    if (event.target.id === "password") {
      if (password && !validatePassword(password)) {
        newErr.password.status = "error";
        newErr.password.message =
          "Choose a stronger password. Try a mix of letters, numbers, symbols and minimum of 8 characters";
      } else {
        newErr.password.status = "";
        newErr.password.message = "";
      }
    } else if (event.target.id === "confirmPassword") {
      if (confirmPassword && confirmPassword !== password) {
        newErr.confirmPassword.status = "error";
        newErr.confirmPassword.message = "Passwords Don't match";
      } else {
        newErr.confirmPassword.status = "";
        newErr.confirmPassword.message = "";
      }
    }
    setError(newErr);
  };

  const handleSubmit = () => {
    let newErr = { ...error };
    if (password && confirmPassword) {
      if (!error.password.status && !error.confirmPassword.status) {
        if (confirmPassword === password) {
          // update password
          ApiRequestPost.resetpassword(accessToken, email, password)
            .then((res) => {
              if (res.data.success) {
                setResetPassword(false);
                setResetOpen(false);
                setLoginOpen(true);
                setStep(1);
              }
              enqueueSnackbar(
                res?.data?.success
                  ? "Password Reset Successfully "
                  : "Something went wrong!",
                {
                  variant: res?.data?.success ? "success" : "error",
                  anchorOrigin: { vertical: "top", horizontal: "right" },
                }
              );
            })
            .catch((err) => {
              enqueueSnackbar(err.response.data.message, {
                variant: "error",
                anchorOrigin: { vertical: "top", horizontal: "right" },
              });

              console.log("errerr", err);
            })
            .finally(() => {
              setLoading(false);
            });
        } else {
          newErr.confirmPassword = {
            status: "error",
            message: "Passwords don't match",
          };
        }
      }
    } else {
      if (!password) {
        newErr.password = {
          status: "error",
          message: "Please enter the password",
        };
      }
      if (!confirmPassword) {
        newErr.confirmPassword = {
          status: "error",
          message: "Please confirm the password",
        };
      }
    }
    setError(newErr);
  };

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <Box sx={style}>
            <IconButton
              style={{
                position: "absolute",
                top: "0",
                right: "0",
              }}
              onClick={() => setResetOpen(false)}
            >
              <CloseIcon fontSize="large" />
            </IconButton>
            <div className="forgotPasswordContent">
              <Typography
                sx={{
                  fontFamily: "Poppins",
                  fontSize: width > limit ? "40px" : "30px",
                  fontWeight: 600,
                  color: "#000000",
                  textAlign: "center",
                }}
              >
                Reset Password
              </Typography>
            </div>
            <div className="login-fields">
              <TextField
                id="password"
                label="New Password"
                placeholder="New Password"
                type={showPassword ? "text" : "password"}
                required
                sx={{ width: width > limit ? "80%" : "100%" }}
                error={error.password.status === "error"}
                helperText={
                  error.password.status === "error"
                    ? error.password.message
                    : ""
                }
                onBlur={(e) => handleValidation(e)}
                onChange={(e) => handleChange(e)}
                value={password}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                      >
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              <TextField
                id="confirmPassword"
                label="Confirm New Password"
                placeholder="Confirm New Password"
                type="password"
                required
                sx={{ width: width > limit ? "80%" : "100%" }}
                error={error.confirmPassword.status === "error"}
                helperText={
                  error.confirmPassword.status === "error"
                    ? error.confirmPassword.message
                    : ""
                }
                onBlur={(e) => handleValidation(e)}
                onChange={(e) => handleChange(e)}
                value={confirmPassword}
              />

              <Button
                variant="contained"
                size="small"
                onClick={() => handleSubmit()}
                sx={{
                  width: 150,
                  height: 40,
                  backgroundColor: "#000000",
                  color: "#FFF",
                  fontWeight: "500",
                  textTransform: "none",
                  fontSize: "20px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  ":hover": {
                    bgcolor: "#000000",
                    fontWeight: 800,
                  },
                }}
              >
                {loading ? (
                  <CircularProgress style={{ color: "#fff" }} size={20} />
                ) : (
                  "Confirm"
                )}
              </Button>
            </div>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
};
