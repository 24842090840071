import { React, useState } from "react";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import { Typography, TextField, Button, CircularProgress } from "@mui/material";
import "./index.css";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { useSnackbar } from "notistack";
import { ApiRequestPost } from "../../data/network/services/ApiRequestPost";
import ResizeListener from "../../utility/ResizeListener";
import { validateEmail } from "../../utility/inputValidations";
import { WIDTH_LIMIT as limit } from "../../data/constants/Constants";

export default function ForgotPasswordEmail({
  forgetOpen,
  setForgetOpen,
  setStep,
  setOtpOpen,
  setSignUpEmail,
  setResetPassword,
}) {
  //@props details
  // loginOpen,setLoginOpen to open and close the modal
  const { enqueueSnackbar } = useSnackbar();
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState({
    email: {
      status: "",
      message: "",
    },
  });
  const { width } = ResizeListener();
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: width > limit ? "30%" : "80%",
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
    borderRadius: "13px",
  };

  const handleChange = (event) => {
    if (event.target.id === "email") {
      setEmail(event.target.value.trim());
      setSignUpEmail(event.target.value.trim());
    }
  };

  const handleClose = () => {
    setForgetOpen(false);
    setResetPassword(false);
    setStep(1);
  };

  const handleValidation = (event) => {
    let newErr = { ...error };
    if (event.target.id === "email") {
      if (email && !validateEmail(email)) {
        newErr.email.status = "error";
        newErr.email.message = "Enter a valid email";
      } else {
        newErr.email.status = "";
        newErr.email.message = "";
      }
    }
    setError(newErr);
  };

  const handleSubmit = () => {
    let newErr = { ...error };
    if (email) {
      if (!error.email.status) {
        /// check email and send otp
        setLoading(true);
        ApiRequestPost.forgotPassword(email)
          .then((res) => {
            if (res.data.success) {
              setOtpOpen(true);
              setForgetOpen(false);
              setResetPassword(true);
              setStep(3);
            }
            enqueueSnackbar(
              res?.data?.success
                ? "OTP sent Successfully "
                : "Something went wrong!",
              {
                variant: res?.data?.success ? "success" : "error",
                anchorOrigin: { vertical: "top", horizontal: "right" },
              }
            );
          })
          .catch((err) => {
            if (err?.response?.data?.statusCode == 404) {
              enqueueSnackbar("No user found for the provided email/phone", {
                variant: "error",
                anchorOrigin: { vertical: "top", horizontal: "right" },
              });
            } else {
              enqueueSnackbar(err.response.data.message, {
                variant: "error",
                anchorOrigin: { vertical: "top", horizontal: "right" },
              });
            }
            console.log("errerr", err);
          })
          .finally(() => {
            setLoading(false);
          });
      }
    } else {
      if (!email) {
        newErr.email = { status: "error", message: "Please enter the email" };
      }

      setError(newErr);
    }
  };

  return (
    <div>
      <Modal
        open={forgetOpen}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={forgetOpen}>
          <Box sx={style}>
            <IconButton
              style={{
                position: "absolute",
                top: "0",
                right: "0",
              }}
              onClick={() => setForgetOpen(false)}
            >
              <CloseIcon fontSize="large" />
            </IconButton>
            <div className="forgotPasswordContent">
              <Typography
                sx={{
                  fontFamily: "Poppins",
                  fontSize: width > limit ? "40px" : "30px",
                  fontWeight: 600,
                  color: "#000000",
                  textAlign: "center",
                }}
              >
                Forgot Password?
              </Typography>
              <Typography
                sx={{
                  fontFamily: "Poppins",
                  fontSize: "16px",
                  fontWeight: 300,
                  color: "#6C6A6A",
                  textAlign: "center",
                }}
              >
                Help us with your registered email to assist you in resetting
                your password.
              </Typography>
            </div>
            <div className="login-fields">
              <TextField
                id="email"
                label="Email"
                placeholder="Enter Your Registered Email"
                size="medium"
                required
                sx={{ width: width > limit ? "80%" : "100%" }}
                autoFocus
                error={error.email.status === "error"}
                onBlur={(e) => handleValidation(e)}
                onChange={(e) => handleChange(e)}
                value={email}
                helperText={
                  error.email.status === "error" ? error.email.message : ""
                }
              />

              <Button
                variant="contained"
                size="small"
                onClick={() => handleSubmit()}
                sx={{
                  width: 150,
                  height: 40,
                  backgroundColor: "#000000",
                  color: "#FFF",
                  fontWeight: "500",
                  textTransform: "none",
                  fontSize: "20px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  ":hover": {
                    bgcolor: "#000000",
                    fontWeight: 800,
                  },
                }}
              >
                {loading ? (
                  <CircularProgress style={{ color: "#fff" }} size={20} />
                ) : (
                  "Continue"
                )}
              </Button>
            </div>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}
