import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import DateRangePicker from "react-daterange-picker";
import "react-daterange-picker/dist/css/react-calendar.css";
import { SIDEBAR_COLOR } from "../../resources/colorsManager";
import { CustomButton } from "../customButton/CustomButton";
import "./input.css";
import { validate } from "./validator";

const Input = ({
  styles,
  value,
  placeholder,
  onChange,
  validator,
  type,
  options,
  showBadge,
  showErrorMsg,
  isSpaceRequired = false,
  setNamesError,
  disabled,
  maxLength,
  onPressEnter,
  autoFocus,
  isPassword,
  isSvgIconRequired,
  imageUpload,
  isOtpField = false,
  dataTestId = "input",
  maxDate,
  filterFocus,
  dropdownImage,
  dropdownStyle,
  addMoney = false,
  onKeyPress,
  onMouseEnter,
  tabIndex,
  inputcomponentStyles,
  isRightIconRequired = false,
  isLeftIconRequired = false,
  rightIcon,
  leftIcon,
  onBlur,
  containerStyles,
}) => {
  const [focus, setFocus] = useState(false);
  const wrapperRef = useRef(null);
  const [openDateRange, setOpenDateRange] = useState(false);
  const [openRoomGuest, setOpenRoomGuest] = useState(false);
  const [room, setRoom] = useState(1);
  const [guest, setGuest] = useState(1);

  const handleChangeRaw = (date) => {
    date.target.value = moment(date).format("DD/MM/YYYY");
  };

  useEffect(() => {
    function handleClickOutside(event) {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        setOpenDateRange(false);
      }
    }

    window.addEventListener("mousedown", handleClickOutside);
    return () => {
      window.removeEventListener("mousedown", handleClickOutside);
    };
  }, [wrapperRef]);

  const roomGuestButtonStyles = {
    display: "flex",
    justifyContent: "center",
    alignContent: "center",
    backgroundColor: SIDEBAR_COLOR,
    color: "#fff",
    height: 20,
    width: 20,
    borderRadius: 5,
    cursor: "pointer",
  };

  const roomGuestTextStyles = {
    marginLeft: 10,
    marginRight: 10,
    width: 30,
    textAlign: "center",
  };

  switch (type) {
    case "input":
      return (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            border: "1px solid #D9D9D9",
            paddingLeft: 15,
            fontSize: 20,
            alignItems: "center",
            overflow: "hidden",
            ...styles,
          }}
        >
          {isLeftIconRequired && leftIcon}

          {validator === "amount1" && <p style={{ margin: 0 }}>₹</p>}
          {validator === "amount" && <p style={{ margin: 0 }}>₹</p>}
          <input
            autoComplete={"off"}
            tabIndex={tabIndex}
            id="inputelement"
            onKeyPress={onKeyPress}
            className="latoRegular"
            data-testid={dataTestId}
            onClick={() => setFocus(true)}
            onBlur={() => {
              onBlur();
              setFocus(false);
            }}
            onKeyDown={(event) => {
              if (event.key === "Enter" && onPressEnter) {
                onPressEnter();
              }
            }}
            style={{
              border: "none",
              outline: "none",
              backgroundColor: "transparent",
              width: "80%",
              height: "100%",
              ...inputcomponentStyles,
            }}
            type={
              isPassword || isOtpField
                ? "password"
                : type === "email"
                ? "email"
                : "text"
            }
            disabled={disabled}
            maxLength={maxLength}
            value={value}
            placeholder={placeholder}
            onChange={(event) => {
              validate(
                validator,
                event.target.value,
                onChange,
                setNamesError,
                isSpaceRequired
              );
            }}
            autoCorrect="off"
            autoFocus={autoFocus}
          />
          {isRightIconRequired && rightIcon}
        </div>
      );

    case "login":
      return (
        <form style={{ width: "100%" }} autoComplete="off">
          <input
            autoComplete="new-password"
            id="inputelement"
            className="latoRegular"
            onKeyPress={onKeyPress}
            data-testid={dataTestId}
            onClick={() => setFocus(true)}
            onBlur={() => {
              onBlur();
              setFocus(false);
            }}
            onKeyDown={(event) => {
              if (event.key === "Enter" && onPressEnter) {
                onPressEnter();
              }
            }}
            style={{
              border: filterFocus
                ? "0.1px solid white"
                : focus
                ? "0.1px solid lightgrey"
                : showErrorMsg
                ? "1px solid red"
                : "0.1px solid white",
              padding: 0,
              paddingLeft: 20,
              fontSize: 20,
              minWidth: 280,
              maxWidth: 400,
              marginLeft: 10,
              marginRight: 10,
              ...styles,
              backgroundColor: "transparent",
            }}
            type={
              isPassword || isOtpField
                ? "password"
                : type === "email"
                ? "email"
                : "text"
            }
            disabled={disabled}
            maxLength={maxLength}
            value={value}
            placeholder={placeholder}
            onChange={(event) => {
              validate(
                validator,
                event.target.value,
                onChange,
                setNamesError,
                isSpaceRequired
              );
            }}
            autoCorrect="off"
            autoFocus={true}
          />
        </form>
      );

    case "select":
      return (
        <div
          style={{
            borderRadius: 7,
            display: dropdownImage && "flex",
            alignItems: dropdownImage && "center",
            ...dropdownStyle,
          }}
        >
          <select
            disabled={disabled}
            id="inputelement"
            value={value === "" ? "select" : value}
            placeholder={placeholder}
            onChange={(event) => {
              onChange(event.target.value);
            }}
            data-testid={dataTestId}
            style={{
              border: focus
                ? "1px solid black"
                : showBadge
                ? "1px solid #3F6AD8"
                : showErrorMsg
                ? "1px solid red"
                : "1px solid white",
              backgroundColor: "white",
              padding: 0,
              paddingLeft: 10,
              fontSize: 20,
              minWidth: 280,
              maxWidth: 400,
              margin: 10,
              height: 45,
              opacity: disabled && 1,
              ...styles,
              color: "grey",
            }}
            onMouseEnter={onMouseEnter}
          >
            {options &&
              options.map((option, index) => {
                return (
                  <option
                    key={index}
                    className="title"
                    style={{
                      backgroundColor: "white",
                    }}
                    value={addMoney ? option.value : option.value}
                  >
                    {option.label}
                  </option>
                );
              })}
          </select>
          {dropdownImage && (
            <img
              src={dropdownImage}
              style={{ width: 20, height: 20, transform: "rotate(90deg)" }}
              alt="Dropdown"
            />
          )}
        </div>
      );

    case "dateRange":
      return (
        <div
          style={{
            border: "1px solid #D9D9D9",
            borderRadius: 5,
            ...containerStyles,
          }}
          ref={wrapperRef}
        >
          <input
            style={{
              position: "relative",
              marginLeft: 15,
              fontSize: 14,
              border: 0,
              outline: "none",
              ...inputcomponentStyles,
            }}
            onChange={() => {}}
            value={value}
            placeholder={placeholder}
            onClick={(e) => {
              setOpenDateRange(!openDateRange);
              setOpenRoomGuest(false);
            }}
            autoFocus={autoFocus}
          />

          {openDateRange && (
            <div
              style={{
                position: "absolute",
                backgroundColor: "white",
                borderRadius: 5,
                zIndex: 21,
                boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)",
                // bottom: 310,
              }}
              className="dateRange dateRangeDiv"
            >
              <DateRangePicker
                onSelect={(date) => {
                  onChange(
                    date.start.format("DD/MM/YYYY") +
                      " " +
                      "-" +
                      " " +
                      date.end.format("DD/MM/YYYY")
                  );

                  setOpenDateRange(!openDateRange);
                }}
                minimumDate={moment().toDate()}
                singleDateRange={false}
              />
            </div>
          )}
        </div>
      );

    case "roomGuest":
      let roomGuest = value?.split(",");

      return (
        <div
          style={{
            border: "1px solid #D9D9D9",
            position: "relative",
            borderRadius: 5,
            ...containerStyles,
          }}
          ref={wrapperRef}
        >
          <input
            style={{
              position: "relative",
              marginLeft: 15,
              fontSize: 16,
              border: 0,
              outline: "none",
              ...inputcomponentStyles,
            }}
            onChange={() => {}}
            value={value ? `${roomGuest[0]} Room, ${roomGuest[1]} Guest` : ""}
            placeholder={placeholder}
            onClick={(e) => {
              setOpenRoomGuest(!openRoomGuest);
            }}
            autoFocus={autoFocus}
          />

          {openRoomGuest && (
            <div
              style={{
                position: "absolute",
                backgroundColor: "white",
                borderRadius: 5,
                zindex: 21,
                boxShadow: "0 4px 8px 0 #aaa",
                width: "100%",
                position: "absolute",
                top: 50,
              }}
              className="dateRange dateRangeDiv"
            >
              <div style={{ padding: "0px 15px 15px 15px" }}>
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <p style={{ fontWeight: "bold" }}>Room</p>
                  <div style={{ display: "flex" }}>
                    <p
                      onClick={() => {
                        if (room !== 1) setRoom((prev) => prev - 1);
                      }}
                      style={{
                        ...roomGuestButtonStyles,
                      }}
                    >
                      -
                    </p>
                    <p
                      style={{
                        ...roomGuestTextStyles,
                      }}
                    >
                      {room}
                    </p>
                    <p
                      onClick={() => {
                        setRoom((prev) => prev + 1);
                      }}
                      style={{
                        ...roomGuestButtonStyles,
                      }}
                    >
                      +
                    </p>
                  </div>
                </div>
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <p style={{ fontWeight: "bold" }}>Guest</p>
                  <div style={{ display: "flex" }}>
                    <p
                      onClick={() => {
                        if (room !== 1) setRoom((prev) => prev - 1);
                      }}
                      style={{
                        ...roomGuestButtonStyles,
                      }}
                    >
                      -
                    </p>
                    <p
                      style={{
                        ...roomGuestTextStyles,
                      }}
                    >
                      {guest}
                    </p>
                    <p
                      onClick={() => {
                        setGuest((prev) => prev + 1);
                      }}
                      style={{
                        ...roomGuestButtonStyles,
                      }}
                    >
                      +
                    </p>
                  </div>
                </div>

                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    marginTop: 20,
                  }}
                >
                  <p
                    onClick={() => {
                      setOpenRoomGuest(false);
                    }}
                    style={{
                      color: SIDEBAR_COLOR,
                      textDecoration: "underline",
                      marginRight: 10,
                      fontSize: 14,
                      cursor: "pointer",
                    }}
                  >
                    Cancel
                  </p>
                  <CustomButton
                    onClick={() => {
                      onChange(`${room},${guest}`);
                      setOpenRoomGuest(false);
                    }}
                    styles={{
                      border: 0,
                      width: 30,
                      height: 30,
                      borderRadius: 5,
                    }}
                    title="Apply"
                  />
                </div>
              </div>
            </div>
          )}
        </div>
      );

    case "date":
      return (
        <div
          style={{
            border: showErrorMsg ? "1px solid red" : "1px solid lightgrey",
            borderRadius: 5,
            fontSize: 12,
            color: "black",
            height: 38,
            display: "flex",
            alignItems: "center",
            boxSizing: "border-box",
            position: "relative",
            justifyContent: "center",
            alignSelf: "center",
            minWidth: 180,
            paddingLeft: isSvgIconRequired ? 50 : 10,
            ...styles,
          }}
        >
          <DatePicker
            selected={value}
            showYearDropdown
            yearDropdownItemNumber={150}
            scrollableYearDropdown
            maxDate={
              maxDate === "" ||
              maxDate === undefined ||
              Object.keys(maxDate).length === 0
                ? new Date()
                : new Date(maxDate.year, maxDate.month, maxDate.day)
            }
            dateFormat="dd/MM/yyyy"
            placeholderText={placeholder}
            className="title"
            onChange={(date) => {
              onChange(date);
            }}
            onChangeRaw={(e) => handleChangeRaw(e)}
          />
        </div>
      );

    case "textarea":
      return (
        <div
          id="inputelement"
          style={{
            paddingTop: 5,
            display: "flex",
            flexDirection: "column",
            width: imageUpload ? "100%" : "auto",
            justifyContent: "center",
          }}
        >
          <div
            style={{
              borderRadius: 5,
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignitems: "center",
            }}
          >
            <textarea
              data-testid={dataTestId}
              className="title"
              style={{
                border: focus
                  ? "1px solid black"
                  : showBadge
                  ? "1px solid #3F6AD8"
                  : showErrorMsg
                  ? "1px solid red"
                  : "1px solid lightgrey",
                minWidth: "100%",
                ...styles,
                borderRadius: 5,
                fontSize: 12,
                color: "black",
                resize: "none",
                paddingLeft: isSvgIconRequired ? 50 : 10,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
              value={value}
              placeholder={placeholder}
              onChange={(event) => {
                const reg = /^[a-zA-Z0-9., ]+$/;
                const value = event.target.value;
                if (reg.test(value)) onChange(event.target.value);
                if (value === "") onChange("");
              }}
            />
          </div>
        </div>
      );

    default:
      return (
        <input
          id="inputelement"
          data-testid={dataTestId}
          style={{
            ...styles,
          }}
          onKeyPress={onKeyPress}
          value={value}
          placeholder={placeholder}
          onChange={(event) => {
            let val = event.target.value;
            if (validator === "") {
              onChange(val);
            }
          }}
        />
      );
  }
};

export default Input;
