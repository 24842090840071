export const convertDate = (dateObject) => {
  let date = dateObject.getDate();
  let month = parseInt(dateObject.getMonth()) + 1;
  let year = dateObject.getFullYear();
  let hours = dateObject.getHours();
  let minutes = dateObject.getMinutes();
  let seconds = dateObject.getSeconds();

  if (minutes < 10) {
    minutes = "0" + minutes;
  }

  if (hours < 10) {
    hours = "0" + hours;
  }

  if (seconds < 10) {
    seconds = "0" + seconds;
  }
  if (date < 10) {
    date = "0" + date;
  }
  if (month < 10) {
    month = "0" + month;
  }

  return (
    date +
    " " +
    month +
    " " +
    year +
    " - " +
    hours +
    ":" +
    minutes +
    ":" +
    seconds
  );
};

export const convertOnlyDate = (dateObject) => {
  let date = dateObject.getDate();
  let month = parseInt(dateObject.getMonth()) + 1;
  let year = dateObject.getFullYear();
  return date + "/" + month + "/" + year;
};

export const convertOnlyDateNode = (dateObject) => {
  let date = parseInt(dateObject.getDate()) - 1;
  let month = parseInt(dateObject.getMonth());
  let year = dateObject.getFullYear();
  return date + "/" + month + "/" + year;
};

export const convertOnlyDateForGraph = (dateObject) => {
  let date = dateObject.getDate();
  return date;
};
export const convertOnlyDateForCustomers = (dateObject) => {
  let date = dateObject.getDate();
  let month = parseInt(dateObject.getMonth()) + 1;
  let year = dateObject.getFullYear();
  return year + "-" + month + "-" + date;
};

export const convertOnlyDate1 = (dateObject) => {
  let date = dateObject.getDate();
  let month = parseInt(dateObject.getMonth()) + 1;
  let year = dateObject.getFullYear();
  return year + "/" + month + "/" + date;
};

export const convertOnlyDateForAddCustomer = (dateObject) => {
  let date = dateObject.getDate();
  let month = parseInt(dateObject.getMonth()) + 1;
  let year = dateObject.getFullYear();
  return year + "-" + month + "-" + date;
};

export const convertDate1 = (dateObject) => {
  if (dateObject && dateObject.startDate) {
    let startDate = dateObject.startDate;
    let endDate = dateObject.endDate;
    return convertOnlyDate(startDate) + "-" + convertOnlyDate(endDate);
  } else {
    return "";
  }
};

export const convertDataForApi = (dateObject) => {
  if (dateObject && dateObject.startDate) {
    let startDate = dateObject.startDate;
    let endDate = dateObject.endDate;

    var day1 = new Date(startDate);
    var temp1 = new Date(day1);
    day1.setDate(temp1.getDate() - 1);

    var day2 = new Date(endDate);
    var temp2 = new Date(day2);
    day2.setDate(temp2.getDate() + 1);

    return convertOnlyDate(day1) + "-" + convertOnlyDate(day2);
  } else {
    return "";
  }
};

export const getOneMonthBackDateRange = () => {
  var date1 = new Date();
  date1.setMonth(date1.getMonth() - 1);
  var date2 = new Date();
  return convertOnlyDate(date1) + "-" + convertOnlyDate(date2);
};

export const getOneYearBackDateRange = () => {
  var date1 = new Date();
  date1.setMonth(date1.getMonth() - 12);
  var date2 = new Date();
  return convertOnlyDate(date1) + "-" + convertOnlyDate(date2);
};

export const isDate18orMoreYearsOld = () => {
  let dateObj = new Date();
  let day = dateObj.getDate();
  let month = parseInt(dateObj.getMonth()) + 1;
  let year = dateObj.getFullYear();
  return new Date(year - 18, month, day);
};

export const formatTimeWithZero = (time) => {
  if (time.split(":")[0] < 10) {
    return "0" + time.split(":")[0] + time.split(":")[1] + time.split(":")[2];
  } else {
    return time;
  }
};

export const firstLetterCapitalMaker = (name) => {
  return name.charAt(0).toUpperCase() + name.slice(1).toLowerCase();
};

export const convertDateWithMonthName = (dateObject) => {
  let date = dateObject.getDate();
  let month = dateObject.toLocaleString("default", { month: "long" });
  let year = dateObject.getFullYear();
  let hours = dateObject.getHours();
  let minutes = dateObject.getMinutes();
  let seconds = dateObject.getSeconds();

  var ampm = hours >= 12 ? "PM" : "AM";
  if (minutes < 10) {
    minutes = "0" + minutes;
  }

  if (hours > 12) {
    hours = hours - 12;
  }

  if (seconds < 10) {
    seconds = "0" + seconds;
  }
  if (date < 10) {
    date = "0" + date;
  }

  return (
    date + " " + month + " " + year + ", " + hours + ":" + minutes + " " + ampm
  );
};

export const convertDateWithMonthName1 = (dateObject) => {
  let date = dateObject.getDate();
  let month = dateObject.toLocaleString("default", { month: "long" });
  let year = dateObject.getFullYear();

  if (date < 10) {
    date = "0" + date;
  }

  return date + " " + month + " " + year;
};

export const convertOnlyDateForReport = (dateObject) => {
  let date = dateObject.getDate();
  let month = parseInt(dateObject.getMonth()) + 1;
  let year = dateObject.getFullYear();

  if (date < 10) {
    date = "0" + date;
  }

  if (month < 10) {
    month = "0" + month;
  }
  return year + "-" + month + "-" + date;
};

export const convertToMonthAndYear = (dateObject) => {
  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  let dateObj = new Date(dateObject);
  let month = monthNames[dateObj.getMonth()].slice(0, 3);
  let year = dateObj.getFullYear();
  return month + " - " + year;
};
