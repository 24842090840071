import { combineReducers } from "redux";
import AuthReducer from "./AuthReducer";
import { fullScreenLoadingReducer } from "./fullScreenLoadingReducer";
import SearchReducer from "./SearchReducer";

const rootReducer = combineReducers({
  AuthReducer,
  fullScreenLoadingReducer,
  SearchReducer,
});

export default rootReducer;
