import React, { useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/autoplay";
import "./index.css";
import SwiperCore, { Autoplay } from "swiper";
import { FullStar } from "../../resources/assetsManager";
import { WIDTH_LIMIT } from "../../../data/constants/Constants";
import ResizeListener from "../../../utility/ResizeListener";

const WhatTravellersSay = ({}) => {
  SwiperCore.use([Autoplay]);
  const [activeIndex, setActiveIndex] = useState(1);
  const { width } = ResizeListener();

  const reviewData = [
    {
      name: "Darshan Patil D",
      review: `Cheers team WanderingLama. We just loved your great service.
Our trip to Coorg became memorable only because of team Wanderinglama and great thanks to Abilash who co-ordinated with us.
Well planned itinerary.
Ease of booking ( the really appreciable part)
& everything was worth for the money.
It was a super great experience.We would like to plan other unseen places too.`,
      stars: 4,
    },
    {
      name: "Venkatesh R",
      review: `It was nice and beautiful place to visit. 
    Had a good time. Zip line, Wall Climbing 
    and Funky monkey was awesome 
    Experience, swimming full was good.`,
      stars: 5,
    },
    {
      name: "Lingraj S",
      review: `Awesome booking and staying experience with wandering lama. You don’t want to cherry pick home-stays or resorts of your taste, it’s already done by wandering lama, just log-in and choose one of it. Value for money and very professional. Highly recommended!!
Booking experience is top notch, Booking executives were very helpful and kind enough to share the list of home stays available and help you to choose the best one of your preference. Give a try!!`,
      stars: 5,
    },
    {
      name: "Chandan M",
      review: `Good experience with easy booking and cordonante start to end. Happy to suggest friends and family members`,
      stars: 5,
    },
    {
      name: "Samrudh Mahesh",
      review: `One of the best platforms to book and execute one’s travel plan, thank you for making my trip the most memorable and economical, I wish to keep booking with you`,
      stars: 5,
    },
    {
      name: "Nischay Kumar",
      review: `Had wonderful experience at Kanakapura homestay...thanks to Wandering Lama.`,
      stars: 5,
    },
    {
      name: "Avinash H R",
      review: `Very well supportive team. We booked a stay at sakleshpur in last minute this weekend. Experienced a seamless booking. Thanks to Mr Suhas from wandering lama team. They have more stay options for all kind of budgets. Recommended for all.`,
      stars: 5,
    },
    {
      name: "Manoj Kumar",
      review: `Intelligent set of people who arrange the holiday as per your need. It is easy to find places to stay in the internet but one has to find out if it’s a good holiday only after finishing the stay. Wandering lama helped me by understanding what is my need - who am I going with, how many people , age and dietary requirements. They provided the ideal homestay option which was excellent for my extended family get together.`,
      stars: 5,
    },
    {
      name: "Dr.Ankith Vaidya",
      review: `Fantastic experience arranging tour with them. Mr Aniket coordinated well and offered the best places at bargain prices. Overall the experience with wandering lama was top class. Kudos to Mr. Aniket and team.`,
      stars: 5,
    },
  ];

  const ReviewItem = ({ index, item, stars, width }) => {
    return (
      <div
        key={index}
        style={{
          padding: "50px 30px",
          borderRadius: 20,
          border:
            width > WIDTH_LIMIT
              ? activeIndex === index
                ? "1px solid #ECECEC"
                : 0
              : "1px solid #ECECEC",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          opacity: width > WIDTH_LIMIT ? (activeIndex === index ? 1 : 0.5) : 1,
          background:
            width > WIDTH_LIMIT
              ? activeIndex === index
                ? "#fff"
                : "#F6F6F6"
              : "#fff",
          height:
            width > WIDTH_LIMIT ? (activeIndex === index ? 300 : 250) : 300,
        }}
      >
        <p
          style={{
            color: "#1E1E1E",
            fontSize:
              width > WIDTH_LIMIT ? (activeIndex === index ? 18 : 16) : 18,
            margin: 0,
            fontWeight: "500",
          }}
        >
          {item.name}
        </p>

        <p
          style={{
            color: "#525252",
            textAlign: "center",
            margin: "40px 0px",
            fontSize:
              width > WIDTH_LIMIT ? (activeIndex === index ? 15 : 13) : 15,
          }}
        >
          {item?.review?.slice(0, 180)}
          {item?.review?.length > 180 ? "..." : ""}
        </p>

        <div style={{ display: "flex" }}>
          {[...Array(5)].map((star) => (
            <FullStar
              style={{
                marginLeft: 5,
                width:
                  width > WIDTH_LIMIT ? (activeIndex === index ? 30 : 20) : 30,
              }}
              fill="#FCD923"
            />
          ))}
        </div>
      </div>
    );
  };

  return (
    <div
      style={{
        width: "100%",
        marginBottom: 80,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        position: "relative",
      }}
    >
      <div
        style={{
          width: "95%",
          position: "absolute",
          marginTop: 30,
          height: "100%",
          // top: 0,
          background: "transparent",
          zIndex: 100,
        }}
      ></div>
      <p
        style={{
          textAlign: "center",
          fontSize: width > WIDTH_LIMIT ? 22 : 20,
          fontWeight: "500",
        }}
      >
        What our Travelers Says 😍
      </p>

      <div style={{ width: "90%", marginTop: 30 }}>
        <Swiper
          modules={[Autoplay]}
          preventInteractionOnTransition={false}
          spaceBetween={20}
          slidesPerView={width > WIDTH_LIMIT ? 3 : 1}
          onSlideChange={(data) => {
            setActiveIndex(data.realIndex + 1);
          }}
          autoplay={{
            delay: 5000,
          }}
        >
          {reviewData?.map((item, index) => {
            return (
              <SwiperSlide key={index}>
                <ReviewItem
                  index={index}
                  item={item}
                  stars={item.stars}
                  width={width}
                />
              </SwiperSlide>
            );
          })}
        </Swiper>
      </div>
    </div>
  );
};

export default WhatTravellersSay;
