import { connect } from "react-redux";
import ResizeListener from "../../../utility/ResizeListener";
import { SIDEBAR_COLOR } from "../../resources/colorsManager";
import "../customLoader/loader.css";
const CustomLoader = ({ size = 40, isLoading, isCancel, styles }) => {
  const { width, height } = ResizeListener();

  return (
    <>
      {isLoading && (
        <div
          style={{
            width: width,
            height: height,
            backgroundColor: "rgba(90,90,90,0.3)",
            position: "absolute",
            zIndex: 100,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            ...styles,
          }}
        >
          <div
            className="fullSpinner"
            style={{
              borderTopColor: SIDEBAR_COLOR,
              height: 50,
              width: 50,
            }}
          />
        </div>
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    isLoading: state.fullScreenLoadingReducer.isLoading,
    isCancel: state.fullScreenLoadingReducer.isCancel,
  };
};
export default connect(mapStateToProps)(CustomLoader);
