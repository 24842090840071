import React from 'react';
import { Box, Modal, Typography } from '@mui/material';
import Backdrop from '@mui/material/Backdrop';
import ResizeListener from '../../utility/ResizeListener';
import { WIDTH_LIMIT } from '../../data/constants/Constants';
import { PaymentSuccessIcon } from '../resources/assetsManager';

const ContactUsSuccess = (open, handleClose) => {
  const { width } = ResizeListener();

  return (
    <Modal
      open={open}
      onClose={handleClose}
      closeAfterTransition
      slotProps={{
        backdrop: {
          Backdrop,
          timeout: 500
        }
      }}
    >
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: width > WIDTH_LIMIT ? '30%' : '80%',
          background: '#fff',
          boxShadow: 24,
          borderRadius: '8px',
        }}
      >
        <Box
          sx={{
            padding: '30px 45px',
            background: '#428D04',
            display: 'flex',
            alignItems: 'center',
            borderTopLeftRadius: 8,
            borderTopRightRadius: 8,
          }}
        >
          <img src={PaymentSuccessIcon} alt='Successfully submitted'/>

          <Typography sx={{ fontWeight: 'bold', color: '#fff', marginLeft: '8px' }}>
            Thank you
          </Typography>
        </Box>

        <Box
          sx={{
            padding: '30px 45px',
            background: '#fff',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            borderBottomLeftRadius: 8,
            borderBottomRightRadius: 8,
          }}
        >
          <Typography sx={{ fontWeight: 'bold', fontSize: 15, textAlign: 'center' }}>
            Your request has been registered successfully. Our team will get in touch with you soon.
          </Typography>
        </Box>

        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            padding: '0px 30px 30px 30px',
          }}
        >
        </Box>
      </Box>
    </Modal>
  );
};

export default ContactUsSuccess;